import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Store, Users, Gift, Coins, TrendingUp, MapPin, Bell, ShoppingBag, Sparkles, Trophy, Target, Gamepad2, HelpCircle, Plus, Minus } from 'lucide-react';

export default function Landing() {
  const [openFaq, setOpenFaq] = useState<number | null>(null);

  const features = [
    {
      icon: Trophy,
      title: "Sunquest",
      description: "Partez à la chasse aux SUNCOINS dans votre ville et gagnez des récompenses !",
      color: "bg-yellow-500"
    },
    {
      icon: Target,
      title: "Quêtes",
      description: "Relevez des défis chez vos commerçants préférés pour gagner des récompenses.",
      color: "bg-green-500"
    },
    {
      icon: Gift,
      title: "Coupons",
      description: "Accédez à des réductions exclusives et utilisez vos SUNCOINS pour économiser.",
      color: "bg-blue-500"
    },
    {
      icon: Bell,
      title: "Notifications",
      description: "Recevez des alertes personnalisées sur les offres près de chez vous.",
      color: "bg-purple-500"
    },
    {
      icon: Users,
      title: "Parrainage",
      description: "Invitez vos amis et gagnez des SUNCOINS à chaque parrainage réussi.",
      color: "bg-pink-500"
    },
    {
      icon: TrendingUp,
      title: "Analytics",
      description: "Pour les commerçants : suivez vos performances en temps réel.",
      color: "bg-indigo-500"
    }
  ];

  const faqs = [
    {
      question: "Comment fonctionne le système de SUNCOINS ?",
      answer: "Les SUNCOINS sont notre monnaie de fidélité. Vous en gagnez en effectuant des achats, en complétant des quêtes et en parrainant d'autres utilisateurs. Vous pouvez ensuite les utiliser pour obtenir des réductions chez tous nos commerçants partenaires, jusqu'à 20% du montant de vos achats."
    },
    {
      question: "Qu'est-ce que Sunquest et comment y participer ?",
      answer: "Sunquest est notre mode de jeu géolocalisé où vous chassez des SUNCOINS dans votre ville ! Explorez votre environnement, trouvez des SUNCOINS normaux (5 SUNCOINS) et des SUNCOINS légendaires (500 SUNCOINS). Revenez régulièrement car de nouveaux SUNCOINS apparaissent toutes les heures !"
    },
    {
      question: "Comment devenir commerçant partenaire ?",
      answer: "Pour devenir partenaire, inscrivez-vous sur la plateforme en tant que commerçant. L'abonnement est de 29.99€/mois avec un pack de démarrage de 5000 SUNCOINS (50€). Vous pourrez créer des quêtes, envoyer des notifications et fidéliser vos clients comme jamais !"
    },
    {
      question: "Le programme de parrainage, comment ça marche ?",
      answer: "En tant que client, vous gagnez 100 SUNCOINS pour chaque ami parrainé qui s'inscrit et valide son compte. En tant que commerçant, vous gagnez 1000 SUNCOINS pour chaque nouveau commerçant qui rejoint la plateforme grâce à vous. Votre filleul reçoit également un bonus de bienvenue."
    },
    {
      question: "Quels sont les avantages pour les commerçants ?",
      answer: "Les commerçants bénéficient d'une solution de fidélisation clé en main, d'une visibilité accrue, d'outils marketing innovants (quêtes, notifications) et d'analyses détaillées de leur activité. Le système de gamification permet d'engager les clients de manière ludique et efficace."
    },
    {
      question: "Comment sont protégées mes données personnelles ?",
      answer: "Nous accordons la plus grande importance à la protection de vos données. Toutes les informations sont cryptées et stockées de manière sécurisée. Nous ne partageons jamais vos données avec des tiers sans votre consentement explicite et respectons strictement le RGPD."
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Fixed Sunquest Shortcut */}
      <Link
        to="/sunquest"
        className="fixed top-4 left-4 z-50 bg-primary-600 text-white p-3 rounded-full shadow-lg hover:bg-primary-700 transition-colors duration-300 group"
        title="Jouer à Sunquest"
      >
        <Gamepad2 className="h-6 w-6" />
        <span className="absolute left-full ml-2 px-2 py-1 bg-gray-900 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
          Jouer à Sunquest
        </span>
      </Link>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-primary-50 to-accent-50 opacity-50" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 relative">
          <div className="text-center">
            <Sparkles className="h-16 w-16 mx-auto mb-6 text-primary-600" />
            <h1 className="text-4xl md:text-6xl font-bold mb-6 text-gray-900">
              La première plateforme de fidélisation gamifiée qui transforme vos achats en aventure
            </h1>
            <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto text-gray-600">
              Découvrez une nouvelle façon de faire du shopping, où chaque visite devient une quête et chaque achat une récompense !
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/register"
                className="bg-primary-600 text-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-primary-700 transition-colors duration-300 shadow-lg hover:shadow-xl"
              >
                Rejoindre l'aventure
              </Link>
              <Link
                to="/merchants"
                className="bg-white text-primary-600 border-2 border-primary-600 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-primary-50 transition-colors duration-300"
              >
                Découvrir les commerces
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">
              Une expérience shopping unique
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Découvrez toutes nos fonctionnalités innovantes
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
              >
                <div className={`w-12 h-12 ${feature.color} rounded-lg flex items-center justify-center mb-4`}>
                  <feature.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">
              Questions fréquentes
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Tout ce que vous devez savoir pour commencer
            </p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white rounded-lg shadow">
                <button
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                  className="w-full px-6 py-4 flex items-center justify-between focus:outline-none"
                >
                  <span className="text-lg font-medium text-gray-900">
                    {faq.question}
                  </span>
                  {openFaq === index ? (
                    <Minus className="h-5 w-5 text-gray-500" />
                  ) : (
                    <Plus className="h-5 w-5 text-gray-500" />
                  )}
                </button>
                {openFaq === index && (
                  <div className="px-6 pb-4">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-primary-600 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Prêt à transformer votre expérience shopping ?
          </h2>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/register"
              className="bg-white text-primary-600 px-8 py-4 rounded-lg font-semibold text-lg hover:bg-gray-100 transition-colors duration-300"
            >
              Créer un compte
            </Link>
            <Link
              to="/merchants"
              className="bg-transparent text-white border-2 border-white px-8 py-4 rounded-lg font-semibold text-lg hover:bg-white/10 transition-colors duration-300"
            >
              Explorer les commerces
            </Link>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <div className="flex items-center space-x-2 mb-4">
                <Store className="h-8 w-8" />
                <span className="text-2xl font-bold">Sunkdo</span>
              </div>
              <p className="text-gray-400">
                La première plateforme de fidélisation gamifiée qui transforme vos achats en aventure.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Liens rapides</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/merchants" className="text-gray-400 hover:text-white">
                    Commerces partenaires
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="text-gray-400 hover:text-white">
                    Créer un compte
                  </Link>
                </li>
                <li>
                  <Link to="/login" className="text-gray-400 hover:text-white">
                    Se connecter
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Aide</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Centre d'aide
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Conditions d'utilisation
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-400 hover:text-white">
                    Politique de confidentialité
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} Sunkdo. Tous droits réservés.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}