import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Trophy, X, Loader, Search, Plus, Minus } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';

const sendSuncoinsSchema = z.object({
  userId: z.string().min(1, 'Utilisateur requis'),
  amount: z.number()
    .min(1, 'Le montant minimum est de 1 SUNCOIN')
    .max(100000, 'Le montant maximum est de 100000 SUNCOINS'),
  operation: z.enum(['add', 'subtract'])
});

type SendSuncoinsForm = z.infer<typeof sendSuncoinsSchema>;

interface SendSuncoinsModalProps {
  onSubmit: (userId: string, amount: number) => Promise<void>;
  onClose: () => void;
  loading?: boolean;
}

export default function SendSuncoinsModal({ onSubmit, onClose, loading }: SendSuncoinsModalProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Array<{ id: string; name: string; email: string }>>([]);
  const [searching, setSearching] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{ id: string; name: string } | null>(null);
  const [operation, setOperation] = useState<'add' | 'subtract'>('add');

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<SendSuncoinsForm>({
    resolver: zodResolver(sendSuncoinsSchema),
    defaultValues: {
      operation: 'add'
    }
  });

  const searchUsers = async (term: string) => {
    if (term.length < 3) return;

    try {
      setSearching(true);
      const usersRef = collection(db, COLLECTIONS.USERS);
      const q = query(
        usersRef,
        where('name', '>=', term),
        where('name', '<=', term + '\uf8ff')
      );
      
      const snapshot = await getDocs(q);
      const results = snapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email
      }));
      
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
    } finally {
      setSearching(false);
    }
  };

  const handleUserSelect = (user: { id: string; name: string }) => {
    setSelectedUser(user);
    setValue('userId', user.id);
    setSearchResults([]);
  };

  const handleOperationChange = (newOperation: 'add' | 'subtract') => {
    setOperation(newOperation);
    setValue('operation', newOperation);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <Trophy className="h-6 w-6 text-yellow-500" />
            <h3 className="text-xl font-semibold text-gray-900">Gérer les SUNCOINS</h3>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit(data => onSubmit(data.userId, operation === 'subtract' ? -data.amount : data.amount))} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Rechercher un utilisateur</label>
            <div className="mt-1 relative">
              {!selectedUser ? (
                <>
                  <div className="relative">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        searchUsers(e.target.value);
                      }}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      placeholder="Nom de l'utilisateur..."
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      {searching ? (
                        <Loader className="h-4 w-4 animate-spin text-gray-400" />
                      ) : (
                        <Search className="h-4 w-4 text-gray-400" />
                      )}
                    </div>
                  </div>
                  {searchResults.length > 0 && (
                    <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md border border-gray-200 max-h-60 overflow-auto">
                      {searchResults.map((user) => (
                        <button
                          key={user.id}
                          type="button"
                          onClick={() => handleUserSelect(user)}
                          className="w-full text-left px-4 py-2 hover:bg-gray-100"
                        >
                          <div className="font-medium">{user.name}</div>
                          <div className="text-sm text-gray-500">{user.email}</div>
                        </button>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-between bg-gray-50 p-3 rounded-md">
                  <div>
                    <div className="font-medium">{selectedUser.name}</div>
                    <input type="hidden" {...register('userId')} value={selectedUser.id} />
                  </div>
                  <button
                    type="button"
                    onClick={() => setSelectedUser(null)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
            {errors.userId && (
              <p className="mt-1 text-sm text-red-600">{errors.userId.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Opération</label>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => handleOperationChange('add')}
                className={`flex-1 flex items-center justify-center space-x-2 px-4 py-2 rounded-md ${
                  operation === 'add'
                    ? 'bg-green-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                <Plus className="h-5 w-5" />
                <span>Ajouter</span>
              </button>
              <button
                type="button"
                onClick={() => handleOperationChange('subtract')}
                className={`flex-1 flex items-center justify-center space-x-2 px-4 py-2 rounded-md ${
                  operation === 'subtract'
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                <Minus className="h-5 w-5" />
                <span>Retirer</span>
              </button>
            </div>
            <input type="hidden" {...register('operation')} value={operation} />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Montant (SUNCOINS)</label>
            <input
              type="number"
              {...register('amount', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              min="1"
              max="100000"
            />
            {errors.amount && (
              <p className="mt-1 text-sm text-red-600">{errors.amount.message}</p>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading || !selectedUser}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50"
            >
              {loading ? (
                <div className="flex items-center space-x-2">
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Envoi...</span>
                </div>
              ) : (
                operation === 'add' ? 'Ajouter' : 'Retirer'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}