import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Store, Loader, Gift, AlertCircle } from 'lucide-react';
import { MERCHANT_CATEGORIES } from '../lib/constants';
import MerchantSubscriptionButton from '../components/MerchantSubscriptionButton';

const registerSchema = z.object({
  name: z.string().min(2, 'Le nom doit contenir au moins 2 caractères'),
  email: z.string().email('Email invalide'),
  password: z.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères'),
  confirmPassword: z.string(),
  isMerchant: z.boolean(),
  referralCode: z.string().optional(),
  merchantInfo: z.object({
    siren: z.string().optional(),
    companyName: z.string().optional(),
    category: z.string().optional(),
  }).optional(),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Les mots de passe ne correspondent pas",
  path: ["confirmPassword"],
});

type RegisterForm = z.infer<typeof registerSchema>;

export default function Register() {
  const { register: registerUser } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('ref');
  const [loading, setLoading] = useState(false);
  const [isMerchant, setIsMerchant] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm<RegisterForm>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      isMerchant: false,
      referralCode: referralCode || '',
      merchantInfo: {
        siren: '',
        companyName: '',
        category: '',
      }
    }
  });

  const handleSubmitForm = async (data: RegisterForm) => {
    try {
      setLoading(true);
      setError(null);

      await registerUser(data.email, data.password, {
        name: data.name,
        role: data.isMerchant ? 'merchant' : 'customer',
        referralCode: data.referralCode,
        ...(data.isMerchant && {
          merchantInfo: {
            siren: data.merchantInfo?.siren,
            companyName: data.merchantInfo?.companyName,
            category: data.merchantInfo?.category,
          }
        })
      });
      
      navigate('/');
    } catch (error: any) {
      console.error('Erreur inscription:', error);
      setError(error.message || 'Une erreur est survenue lors de l\'inscription');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Créer un compte</h1>
        
        {error && (
          <div className="mb-6 p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <span>{error}</span>
          </div>
        )}

        {referralCode && (
          <div className="mb-6 p-4 bg-primary-50 rounded-lg">
            <div className="flex items-start space-x-3">
              <Gift className="h-5 w-5 text-primary-600 mt-1" />
              <div>
                <p className="text-primary-700 font-medium">
                  Vous avez été parrainé !
                </p>
                <p className="text-primary-600 text-sm mt-1">
                  Créez votre compte pour recevoir votre bonus de bienvenue de {isMerchant ? '250' : '25'} SUNCOINS !
                </p>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit(handleSubmitForm)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nom complet</label>
            <input
              type="text"
              {...register('name')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              {...register('email')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
            <input
              type="password"
              {...register('password')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
            {errors.password && (
              <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Confirmer le mot de passe</label>
            <input
              type="password"
              {...register('confirmPassword')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
            {errors.confirmPassword && (
              <p className="mt-1 text-sm text-red-600">{errors.confirmPassword.message}</p>
            )}
          </div>

          {/* Champ pour le code parrain */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Code parrain (optionnel)</label>
            <input
              type="text"
              {...register('referralCode')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              placeholder="Entrez le code parrain"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              {...register('isMerchant')}
              onChange={(e) => setIsMerchant(e.target.checked)}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Je suis un commerçant
            </label>
          </div>

          {watch('isMerchant') && (
            <div className="space-y-6 border-t pt-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">SIREN</label>
                <input
                  type="text"
                  {...register('merchantInfo.siren')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Nom de l'entreprise</label>
                <input
                  type="text"
                  {...register('merchantInfo.companyName')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Catégorie</label>
                <select
                  {...register('merchantInfo.category')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                >
                  <option value="">Sélectionnez une catégorie</option>
                  {MERCHANT_CATEGORIES.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="bg-primary-50 p-4 rounded-lg">
                <h3 className="font-medium text-gray-900 mb-2">Abonnement marchand</h3>
                <p className="text-sm text-gray-600 mb-4">
                  Pour devenir marchand, un abonnement mensuel de 19,90€ est requis. 
                  Vous bénéficiez de 14 jours d'essai gratuit !
                </p>
                <MerchantSubscriptionButton 
                  onError={(error) => setError(error.message)}
                  className="w-full"
                />
              </div>
            </div>
          )}

          <button
            type="submit"
            disabled={loading || watch('isMerchant')}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
          >
            {loading ? (
              <div className="flex items-center space-x-2">
                <Loader className="h-4 w-4 animate-spin" />
                <span>Inscription en cours...</span>
              </div>
            ) : (
              'Créer mon compte'
            )}
          </button>
        </form>

        <p className="mt-6 text-center text-sm text-gray-600">
          Déjà un compte ?{' '}
          <Link to="/login" className="font-medium text-primary-600 hover:text-primary-700">
            Connectez-vous
          </Link>
        </p>
      </div>
    </div>
  );
}