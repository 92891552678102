export const MAX_REFERRALS_PER_DAY = 20;
export const MIN_TIME_BETWEEN_REFERRALS = 5 * 60 * 1000; // 5 minutes

export const REWARDS = {
  customer: {
    referrer: 100, // Parrain reçoit 100 SUNCOINS
    referee: 50   // Filleul reçoit 50 SUNCOINS
  },
  merchant: {
    referrer: 1000, // Parrain reçoit 1000 SUNCOINS
    referee: 500    // Filleul reçoit 500 SUNCOINS
  }
} as const;

export const REFERRAL_MILESTONES = [
  { count: 5, bonus: 250 },    // +250 SUNCOINS à 5 parrainages
  { count: 10, bonus: 750 },   // +750 SUNCOINS à 10 parrainages
  { count: 25, bonus: 2500 },  // +2500 SUNCOINS à 25 parrainages
  { count: 50, bonus: 7500 },  // +7500 SUNCOINS à 50 parrainages
  { count: 100, bonus: 25000 } // +25000 SUNCOINS à 100 parrainages
] as const;