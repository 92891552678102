import { doc, getDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../firebase';

const ADMIN_EMAILS = [
  'admin@sunkdo.com',
  'sebgoudon@gmail.com',
  'denismisran@gmail.com',
  'direction.sunkdo@gmail.com'
];

export const isUserAdmin = async (userId: string): Promise<boolean> => {
  try {
    const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, userId));
    if (!userDoc.exists()) return false;
    
    const userData = userDoc.data();
    return userData.role === 'admin' || ADMIN_EMAILS.includes(userData.email);
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const validateAdminAction = async (userId: string): Promise<void> => {
  const isAdmin = await isUserAdmin(userId);
  if (!isAdmin) {
    throw new Error('Permission denied. Admin access required.');
  }
};