import React, { useEffect, useRef, useState } from 'react';
import { MapPin } from 'lucide-react';
import { initMapsApi } from '../lib/maps';

interface AddressAutocompleteProps {
  value: string;
  onChange: (address: string, location?: { lat: number; lng: number }) => void;
  className?: string;
  error?: string;
}

export function AddressAutocomplete({ value, onChange, className, error }: AddressAutocompleteProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAutocomplete = async () => {
      try {
        const maps = await initMapsApi();
        
        if (inputRef.current) {
          const autocompleteInstance = new maps.places.Autocomplete(inputRef.current, {
            componentRestrictions: { country: 'fr' },
            fields: ['formatted_address', 'geometry']
          });

          autocompleteInstance.addListener('place_changed', () => {
            const place = autocompleteInstance.getPlace();
            if (place.formatted_address && place.geometry?.location) {
              onChange(
                place.formatted_address,
                {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng()
                }
              );
            }
          });

          setAutocomplete(autocompleteInstance);
        }
      } catch (err) {
        console.error('Erreur initialisation autocomplete:', err);
      } finally {
        setLoading(false);
      }
    };

    initAutocomplete();
  }, [onChange]);

  return (
    <div className="relative">
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MapPin className={`h-5 w-5 ${error ? 'text-red-400' : 'text-gray-400'}`} />
        </div>
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={loading}
          className={`pl-10 block w-full rounded-md ${
            error
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
              : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
          } ${className}`}
          placeholder={loading ? 'Chargement...' : 'Entrez une adresse'}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}