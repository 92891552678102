import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { isUserAdmin } from '../lib/utils/admin';

interface AdminContextType {
  isAdmin: boolean;
  loading: boolean;
  validateAdminAccess: () => Promise<void>;
}

const AdminContext = createContext<AdminContextType | null>(null);

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider');
  }
  return context;
};

export function AdminProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        setIsAdmin(false);
        setLoading(false);
        return;
      }

      try {
        const adminStatus = await isUserAdmin(currentUser.uid);
        setIsAdmin(adminStatus);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  const validateAdminAccess = async () => {
    if (!isAdmin) {
      throw new Error('Permission denied. Admin access required.');
    }
  };

  return (
    <AdminContext.Provider value={{ isAdmin, loading, validateAdminAccess }}>
      {children}
    </AdminContext.Provider>
  );
}