import React from 'react';
import { LogIn } from 'lucide-react';
import { Link } from 'react-router-dom';

export function AuthOverlay() {
  return (
    <div className="absolute inset-0 bg-white bg-opacity-90 flex items-center justify-center z-40">
      <div className="text-center p-8 max-w-md">
        <LogIn className="h-12 w-12 text-primary-600 mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          Connectez-vous pour accéder à cette fonctionnalité
        </h2>
        <p className="text-gray-600 mb-8">
          Créez un compte ou connectez-vous pour profiter de toutes les fonctionnalités de Sunkdo
        </p>
        <div className="space-y-4">
          <Link
            to="/login"
            className="block w-full bg-primary-600 text-white py-2 px-4 rounded-md hover:bg-primary-700 transition-colors duration-300"
          >
            Se connecter
          </Link>
          <Link
            to="/register"
            className="block w-full bg-white text-primary-600 py-2 px-4 rounded-md border border-primary-600 hover:bg-primary-50 transition-colors duration-300"
          >
            Créer un compte
          </Link>
        </div>
      </div>
    </div>
  );
}