import { z } from 'zod';

export const couponFormSchema = z.object({
  title: z.string().min(3, 'Le titre doit contenir au moins 3 caractères'),
  description: z.string().min(10, 'La description doit contenir au moins 10 caractères'),
  category: z.string().min(1, 'La catégorie est requise'),
  discountType: z.enum(['percentage', 'fixed']),
  discountValue: z.number()
    .min(0.01, 'La valeur doit être supérieure à 0')
    .refine(
      (val) => val <= (val === 'percentage' ? 100 : 1000),
      (val) => ({
        message: val === 'percentage' ? 
          'La réduction ne peut pas dépasser 100%' : 
          'La réduction ne peut pas dépasser 1000€'
      })
    ),
  maxUses: z.number().min(1, 'Le nombre d\'utilisations doit être supérieur à 0'),
  expiresAt: z.string().refine(
    (date) => new Date(date) > new Date(),
    'La date d\'expiration doit être dans le futur'
  ),
  terms: z.string().optional()
});

export type CouponFormData = z.infer<typeof couponFormSchema>;