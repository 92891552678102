export const MERCHANT_CATEGORIES = [
  { id: 'restaurant', label: 'Restaurant' },
  { id: 'bakery', label: 'Boulangerie / Pâtisserie' },
  { id: 'grocery', label: 'Épicerie / Alimentation' },
  { id: 'fashion', label: 'Mode & Accessoires' },
  { id: 'beauty', label: 'Beauté & Bien-être' },
  { id: 'health', label: 'Santé & Pharmacie' },
  { id: 'sports', label: 'Sports & Loisirs' },
  { id: 'culture', label: 'Culture & Divertissement' },
  { id: 'home', label: 'Maison & Décoration' },
  { id: 'tech', label: 'High-Tech & Informatique' },
  { id: 'auto', label: 'Auto & Moto' },
  { id: 'pets', label: 'Animalerie' },
  { id: 'toys', label: 'Jouets & Jeux' },
  { id: 'flowers', label: 'Fleurs & Jardinage' },
  { id: 'other', label: 'Autre' }
] as const;

export const GAME_CONFIG = {
  // Collection settings
  COLLECTION_RADIUS: 70,
  SPAWN_RADIUS: {
    MIN: 50,
    MAX: 200
  },
  REWARDS: {
    REGULAR: 1,
    LEGENDARY: 3,
    RARE: 5
  },
  DAILY_LIMITS: {
    REGULAR: 10,
    LEGENDARY: 3,
    RARE: 1
  },
  RARE_INTERVAL: 2 // Days between rare coins
};

export const DEALS_CONFIG = {
  MAX_IMAGE_SIZE: 5 * 1024 * 1024, // 5MB
  ALLOWED_IMAGE_TYPES: ['image/jpeg', 'image/png', 'image/webp'],
  MAX_DESCRIPTION_LENGTH: 500,
  PRICE_LIMITS: {
    MIN: 0.01,
    MAX: 999999.99
  },
  LOCATION_RADIUS: 50000, // 50km for nearby deals
  FEED_LIMIT: 50 // Number of deals to load initially
} as const;