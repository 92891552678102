import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Bell, X, Loader, Users, Store } from 'lucide-react';
import { MERCHANT_CATEGORIES } from '../lib/constants';
import { collection, query, where, getDocs, Query, DocumentData } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';

const notificationSchema = z.object({
  title: z.string().min(3, 'Le titre doit contenir au moins 3 caractères'),
  message: z.string().min(10, 'Le message doit contenir au moins 10 caractères'),
  target: z.enum(['all', 'users', 'merchants', 'category']),
  merchantCategory: z.string().optional()
});

type NotificationForm = z.infer<typeof notificationSchema>;

interface AdminNotificationFormProps {
  onSubmit: (data: NotificationForm) => Promise<void>;
  onClose: () => void;
  loading?: boolean;
}

export default function AdminNotificationForm({ onSubmit, onClose, loading }: AdminNotificationFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [recipientCount, setRecipientCount] = useState<number | null>(null);

  const { register, handleSubmit, watch, formState: { errors } } = useForm<NotificationForm>({
    resolver: zodResolver(notificationSchema),
    defaultValues: {
      target: 'all'
    }
  });

  const target = watch('target');
  const merchantCategory = watch('merchantCategory');

  // Calculer le nombre de destinataires
  const calculateRecipients = async (target: string, category?: string) => {
    try {
      const usersRef = collection(db, COLLECTIONS.USERS);
      let q: Query<DocumentData>;

      switch (target) {
        case 'all':
          q = query(usersRef);
          break;
        case 'users':
          q = query(usersRef, where('role', '==', 'customer'));
          break;
        case 'merchants':
          q = query(usersRef, where('role', '==', 'merchant'));
          break;
        case 'category':
          if (!category) {
            setRecipientCount(0);
            return;
          }
          q = query(
            usersRef,
            where('role', '==', 'merchant'),
            where('merchantInfo.category', '==', category)
          );
          break;
        default:
          setRecipientCount(0);
          return;
      }

      const snapshot = await getDocs(q);
      // Filtrer les utilisateurs qui ont un token de notification
      const validRecipients = snapshot.docs.filter(doc => doc.data().notificationToken);
      setRecipientCount(validRecipients.length);
    } catch (err) {
      console.error('Erreur calcul destinataires:', err);
      setError('Erreur lors du calcul des destinataires');
      setRecipientCount(0);
    }
  };

  // Mettre à jour le nombre de destinataires quand la cible change
  React.useEffect(() => {
    calculateRecipients(target, merchantCategory);
  }, [target, merchantCategory]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-xl">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <Bell className="h-6 w-6 text-primary-600" />
            <h3 className="text-xl font-semibold text-gray-900">Envoyer une notification</h3>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Destinataires</label>
            <select
              {...register('target')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            >
              <option value="all">Tous les utilisateurs</option>
              <option value="users">Clients uniquement</option>
              <option value="merchants">Tous les marchands</option>
              <option value="category">Par catégorie de marchand</option>
            </select>
          </div>

          {target === 'category' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Catégorie de marchand</label>
              <select
                {...register('merchantCategory')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              >
                <option value="">Sélectionnez une catégorie</option>
                {MERCHANT_CATEGORIES.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          {recipientCount !== null && (
            <div className="bg-primary-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2">
                {target === 'users' ? (
                  <Users className="h-5 w-5 text-primary-600" />
                ) : target === 'merchants' || target === 'category' ? (
                  <Store className="h-5 w-5 text-primary-600" />
                ) : (
                  <Bell className="h-5 w-5 text-primary-600" />
                )}
                <span className="text-primary-700">
                  {recipientCount} destinataire{recipientCount > 1 ? 's' : ''} sélectionné{recipientCount > 1 ? 's' : ''}
                </span>
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Titre</label>
            <input
              type="text"
              {...register('title')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              placeholder="Ex: Nouvelle fonctionnalité disponible !"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Message</label>
            <textarea
              {...register('message')}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              placeholder="Contenu de la notification..."
            />
            {errors.message && (
              <p className="mt-1 text-sm text-red-600">{errors.message.message}</p>
            )}
          </div>

          {error && (
            <div className="p-3 bg-red-50 text-red-600 rounded-md">
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading || recipientCount === 0}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50"
            >
              {loading ? (
                <div className="flex items-center space-x-2">
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Envoi...</span>
                </div>
              ) : (
                'Envoyer'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}