// Réponses par route
const responses = {
  '/': {
    welcome: [
      "Hey ! 👋 Je suis Sunny, votre compagnon de jeu et assistant personnel. Prêt à transformer votre shopping en aventure ? Je suis là pour vous guider ! 🎮",
      "Salut l'aventurier ! 🌟 Moi c'est Sunny, et je vais vous faire découvrir un nouveau monde où shopping rime avec gaming. Par où voulez-vous commencer ?"
    ],
    question: [
      "Je peux vous aider à découvrir toutes les fonctionnalités de Sunkdo. Que souhaitez-vous savoir ?",
      "N'hésitez pas à me poser des questions sur le fonctionnement de la plateforme !"
    ]
  },
  '/merchants': {
    welcome: [
      "Bienvenue dans la zone des commerçants ! 🏪 Je connais tous les bons plans et les meilleures quêtes du coin. Besoin d'un guide shopping personnalisé ?",
      "Ah, vous cherchez des bonnes affaires ? 🎯 Vous êtes au bon endroit ! Je peux vous recommander les meilleurs commerçants selon vos préférences."
    ],
    question: [
      "Je connais tous les commerçants par cœur ! Dites-moi ce que vous recherchez.",
      "Je peux vous aider à trouver les meilleures offres chez nos commerçants !"
    ]
  },
  '/wallet': {
    welcome: [
      "Bienvenue dans votre coffre-fort digital ! 💎 Vos SUNCOINS sont précieusement gardés ici. Envie de savoir comment en gagner plus ou les utiliser intelligemment ?",
      "Voici votre trésor personnel ! ✨ Je peux vous montrer comment maximiser vos gains en SUNCOINS et les utiliser au mieux chez nos commerçants."
    ],
    question: [
      "Les SUNCOINS sont notre monnaie de fidélité. Vous pouvez les utiliser chez tous nos commerçants !",
      "Je peux vous aider à comprendre comment maximiser vos gains en SUNCOINS."
    ]
  },
  '/quests': {
    welcome: [
      "Ah, un amateur de défis ! 🎯 Les quêtes sont ma spécialité. Je peux vous aider à trouver celles qui vous rapporteront le plus de SUNCOINS !",
      "Bienvenue dans le QG des quêtes ! 🗺️ Prêt à relever des défis et gagner des récompenses ? Je serai votre guide personnel !"
    ],
    question: [
      "Les quêtes sont un excellent moyen de gagner des SUNCOINS. Que voulez-vous savoir ?",
      "Je connais toutes les astuces pour compléter les quêtes efficacement !"
    ]
  },
  '/sunquest': {
    welcome: [
      "Wow, vous voilà dans mon mode préféré ! 🎮 Sunquest, c'est LA chasse aux trésors version 2.0. Prêt à partir à l'aventure ?",
      "Bienvenue dans Sunquest, chasseur de trésors ! 🏆 Les rues regorgent de SUNCOINS qui n'attendent que vous. Je vous dévoile tous mes secrets ?"
    ],
    question: [
      "Je peux vous donner des astuces pour trouver les meilleurs trophées !",
      "Sunquest cache de nombreux secrets. Que voulez-vous savoir ?"
    ]
  },
  '/referral': {
    welcome: [
      "Excellent choix ! 🤝 Le parrainage est le moyen le plus malin de gagner des SUNCOINS. Prêt à devenir un super parrain ?",
      "Ah, vous avez découvert notre programme VIP ! 🌟 Parrainez vos amis et devenez un véritable magnat des SUNCOINS !"
    ],
    question: [
      "Le parrainage est super rentable ! Que voulez-vous savoir ?",
      "Je peux vous expliquer comment maximiser vos gains avec le parrainage !"
    ]
  },
  '/profile': {
    welcome: [
      "Voici votre QG personnel ! 🎯 C'est ici que vous gérez votre profil de chasseur de SUNCOINS. Besoin d'aide pour optimiser vos paramètres ?",
      "Bienvenue dans votre espace perso ! ⚡ Je peux vous aider à configurer votre profil pour une expérience de jeu optimale."
    ],
    question: [
      "Je peux vous aider à personnaliser votre profil. Que souhaitez-vous modifier ?",
      "Votre profil est important pour l'expérience de jeu. Que puis-je vous expliquer ?"
    ]
  }
};

// Message crypto exclusif
const CRYPTO_TEASER = "🚀 INFO EXCLUSIVE CRYPTO ! 🚀\n\nPour les aficionados des cryptos, partagez l'info et parrainez vos amis ! Sunkdo prépare le lancement de la crypto SUNCOIN très prochainement. Plus vous accumulerez de SUNCOINS avec nos jeux maintenant, plus vous serez heureux quand la crypto sortira ! 🌟\n\nPréparez-vous pour la révolution SUNCOIN ! 💎";

// Réponses principales pour les questions fréquentes
const mainResponses = {
  suncoins: [
    "Les SUNCOINS sont notre monnaie de fidélité. 1 SUNCOIN = 0.01€ de réduction. Vous pouvez les utiliser jusqu'à 20% du montant de vos achats chez tous nos commerçants partenaires ! 💰",
    "Avec les SUNCOINS, économisez jusqu'à 20% sur vos achats ! Gagnez-en en complétant des quêtes, en participant à Sunquest, ou en parrainant vos amis. 1 SUNCOIN = 0.01€ de réduction ! ✨"
  ],
  paiement: [
    "Le paiement avec les SUNCOINS est super simple ! Vous pouvez utiliser jusqu'à 20% du montant de votre achat en SUNCOINS. Par exemple, pour un achat de 100€, vous pouvez utiliser jusqu'à 2000 SUNCOINS (20€) ! 💳",
    "Pour payer avec vos SUNCOINS, il suffit de présenter votre QR code au commerçant. Vous pouvez utiliser jusqu'à 20% du montant en SUNCOINS. C'est rapide et avantageux ! 🎯"
  ]
};

// Réponses commerciales pour les commerçants
const commercialResponses = {
  devenir_commercant: [
    "Pour devenir commerçant partenaire, c'est simple : abonnement de 29.99€/mois avec un pack de démarrage de 5000 SUNCOINS (50€). Vous aurez accès à :\n\n• Création de quêtes personnalisées\n• Système de coupons\n• Notifications push géolocalisées\n• Analytics en temps réel\n• Support dédié 🚀",
    "Rejoignez notre réseau de commerçants pour 29.99€/mois ! Le pack de démarrage inclut 5000 SUNCOINS (50€) pour commencer à récompenser vos clients. Vous pourrez créer des quêtes, envoyer des notifications ciblées et suivre vos performances en temps réel ! ✨"
  ],
  avantages_commercant: [
    "En tant que commerçant partenaire, vous bénéficiez de nombreux avantages :\n\n• Quêtes personnalisées pour attirer les clients\n• Coupons de réduction personnalisables\n• Notifications push géolocalisées\n• Statistiques détaillées\n• Système de fidélisation gamifié\n• Support prioritaire 📊",
    "Nos outils de fidélisation sont conçus pour booster votre business :\n\n• Attirez de nouveaux clients avec les quêtes\n• Fidélisez avec les SUNCOINS\n• Communiquez via les notifications push\n• Analysez vos performances\n• Créez des offres personnalisées 🎯"
  ]
};

// Réponses sur le gameplay pour les utilisateurs
const gameplayResponses = {
  sunquest: [
    "Sunquest transforme votre ville en terrain de chasse aux trophées :\n\n• Trophées normaux : 5 SUNCOINS\n• Trophées légendaires : 500 SUNCOINS\n• Rayon de collecte : 1km\n• Nouveaux trophées toutes les heures\n\nPartez à l'aventure ! 🏆",
    "Sunquest, c'est la chasse aux trophées version Sunkdo :\n\n• Explorez votre ville\n• Trouvez des trophées normaux (5 SUNCOINS)\n• Chassez les légendaires (500 SUNCOINS)\n• Revenez régulièrement pour de nouveaux trophées 🎮"
  ],
  quetes: [
    "Les quêtes sont un excellent moyen de gagner des SUNCOINS :\n\n• Visitez des commerces\n• Effectuez des achats spécifiques\n• Explorez de nouveaux endroits\n• Gagnez des récompenses\n• Débloquez des bonus exclusifs 🎯",
    "Participez aux quêtes pour maximiser vos gains :\n\n• Chaque commerçant propose ses défis\n• Validez les quêtes en scannant les QR codes\n• Cumulez les SUNCOINS\n• Découvrez de nouveaux commerces 🌟"
  ],
  parrainage: [
    "Le système de parrainage est super avantageux :\n\n• 100 SUNCOINS par client parrainé\n• 1000 SUNCOINS par commerçant parrainé\n• Bonus de bienvenue pour les filleuls\n• Cumul illimité\n• Code parrainage personnel 🤝",
    "Parrainez et gagnez :\n\n• Clients : 100 SUNCOINS\n• Commerçants : 1000 SUNCOINS\n• Vos filleuls reçoivent un bonus\n• Partagez votre code\n• Programme sans limite 🎁"
  ]
};

export const getResponseForRoute = (
  route: string,
  type: 'welcome' | 'question',
  question?: string
): string => {
  const routeResponses = responses[route] || responses['/'];
  const responseArray = routeResponses[type];
  
  // 10% de chance d'afficher le message crypto
  if (Math.random() < 0.1) {
    return CRYPTO_TEASER;
  }
  
  if (question) {
    // Vérifier d'abord les réponses principales
    for (const [key, responses] of Object.entries(mainResponses)) {
      if (question.toLowerCase().includes(key)) {
        return responses[Math.floor(Math.random() * responses.length)];
      }
    }

    // Vérifier les réponses spécifiques au gameplay
    if (question.toLowerCase().includes('sunquest')) {
      return gameplayResponses.sunquest[Math.floor(Math.random() * gameplayResponses.sunquest.length)];
    }
    if (question.toLowerCase().includes('quête')) {
      return gameplayResponses.quetes[Math.floor(Math.random() * gameplayResponses.quetes.length)];
    }
    if (question.toLowerCase().includes('parrain')) {
      return gameplayResponses.parrainage[Math.floor(Math.random() * gameplayResponses.parrainage.length)];
    }

    // Vérifier les questions sur les commerçants
    if (question.toLowerCase().includes('devenir commercant') || question.toLowerCase().includes('devenir marchand')) {
      return commercialResponses.devenir_commercant[Math.floor(Math.random() * commercialResponses.devenir_commercant.length)];
    }
    if (question.toLowerCase().includes('avantage') && (question.toLowerCase().includes('commercant') || question.toLowerCase().includes('marchand'))) {
      return commercialResponses.avantages_commercant[Math.floor(Math.random() * commercialResponses.avantages_commercant.length)];
    }

    // 5% de chance d'avoir une expression humoristique
    if (Math.random() < 0.05) {
      const funnyExpression = funnyExpressions[Math.floor(Math.random() * funnyExpressions.length)];
      return `${responseArray[Math.floor(Math.random() * responseArray.length)]}\n\n${funnyExpression}`;
    }
  }

  return responseArray[Math.floor(Math.random() * responseArray.length)];
};