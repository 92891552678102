import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, COLLECTIONS } from '../firebase';
import type { PremiumStats } from './types';

export const usePremiumStatus = () => {
  const { currentUser } = useAuth();
  const [isPremium, setIsPremium] = useState(false);
  const [premiumStats, setPremiumStats] = useState<PremiumStats | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      setIsPremium(false);
      setPremiumStats(null);
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, COLLECTIONS.USERS, currentUser.uid),
      (doc) => {
        const data = doc.data();
        setIsPremium(data?.subscription?.status === 'active');
        setPremiumStats(data?.premiumStats || null);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching premium status:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  return { isPremium, premiumStats, loading };
};