import { BehaviorSubject } from 'rxjs';

const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 30000, // Increased timeout to 30 seconds
  maximumAge: 10000, // Cache position for 10 seconds
  maximumRetries: 3,
  retryDelay: 2000
};

export const getCurrentPosition = (retryCount = 0): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('La géolocalisation n\'est pas supportée par votre navigateur'));
      return;
    }

    const handleError = (error: GeolocationPositionError) => {
      if (retryCount < GEOLOCATION_OPTIONS.maximumRetries) {
        setTimeout(() => {
          getCurrentPosition(retryCount + 1)
            .then(resolve)
            .catch(reject);
        }, GEOLOCATION_OPTIONS.retryDelay);
      } else {
        let message = 'Erreur de géolocalisation';
        switch (error.code) {
          case error.PERMISSION_DENIED:
            message = 'Vous devez autoriser la géolocalisation pour utiliser cette fonctionnalité';
            break;
          case error.POSITION_UNAVAILABLE:
            message = 'Position indisponible. Vérifiez que la géolocalisation est activée.';
            break;
          case error.TIMEOUT:
            message = 'La demande de géolocalisation a expiré. Veuillez réessayer.';
            break;
        }
        reject(new Error(message));
      }
    };

    navigator.geolocation.getCurrentPosition(
      resolve,
      handleError,
      {
        enableHighAccuracy: GEOLOCATION_OPTIONS.enableHighAccuracy,
        timeout: GEOLOCATION_OPTIONS.timeout,
        maximumAge: GEOLOCATION_OPTIONS.maximumAge
      }
    );
  });
};

export const watchPosition = (
  onSuccess: (position: GeolocationPosition) => void,
  onError: (error: GeolocationPositionError) => void
): number | null => {
  if (!navigator.geolocation) {
    onError({
      code: 0,
      message: 'La géolocalisation n\'est pas supportée par votre navigateur',
      PERMISSION_DENIED: 1,
      POSITION_UNAVAILABLE: 2,
      TIMEOUT: 3
    });
    return null;
  }

  let retryCount = 0;
  const handleError = (error: GeolocationPositionError) => {
    if (retryCount < GEOLOCATION_OPTIONS.maximumRetries) {
      retryCount++;
      setTimeout(() => {
        watchPosition(onSuccess, onError);
      }, GEOLOCATION_OPTIONS.retryDelay);
    } else {
      onError(error);
    }
  };

  return navigator.geolocation.watchPosition(
    (position) => {
      retryCount = 0; // Reset retry count on success
      onSuccess(position);
    },
    handleError,
    {
      enableHighAccuracy: GEOLOCATION_OPTIONS.enableHighAccuracy,
      timeout: GEOLOCATION_OPTIONS.timeout,
      maximumAge: GEOLOCATION_OPTIONS.maximumAge
    }
  );
};

export const clearWatch = (id: number | null): void => {
  if (id !== null) {
    navigator.geolocation.clearWatch(id);
  }
};

export const formatDistance = (meters: number): string => {
  if (meters >= 1000) {
    return `${(meters / 1000).toFixed(1)} km`;
  }
  return `${Math.round(meters)} m`;
};

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371e3; // Earth's radius in meters
  const φ1 = lat1 * Math.PI/180;
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c;
};

export const isLocationInRadius = (
  center: { lat: number; lng: number },
  point: { lat: number; lng: number },
  radiusInMeters: number
): boolean => {
  const distance = calculateDistance(
    center.lat,
    center.lng,
    point.lat,
    point.lng
  );
  return distance <= radiusInMeters;
};