import React, { useState } from 'react';
import { X, CheckCircle, XCircle, Store, MapPin, Phone, Globe, Loader } from 'lucide-react';
import { doc, updateDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { MERCHANT_CATEGORIES } from '../lib/constants';

interface Merchant {
  id: string;
  name: string;
  email: string;
  merchantInfo: {
    siren: string;
    companyName: string;
    businessAddress: string;
    category: string;
    website?: string;
  };
  phone?: string;
}

interface MerchantValidationModalProps {
  merchants: Merchant[];
  onClose: () => void;
}

export default function MerchantValidationModal({ merchants, onClose }: MerchantValidationModalProps) {
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [error, setError] = useState<string | null>(null);

  const handleValidation = async (merchantId: string, approved: boolean) => {
    try {
      setLoading(prev => ({ ...prev, [merchantId]: true }));
      setError(null);

      const merchantRef = doc(db, COLLECTIONS.USERS, merchantId);
      await updateDoc(merchantRef, {
        verified: approved,
        verifiedAt: new Date(),
        active: approved
      });

      // Retirer le marchand de la liste
      const element = document.getElementById(`merchant-${merchantId}`);
      if (element) {
        element.classList.add('animate-fadeOut');
        setTimeout(() => {
          element.remove();
          // Si plus de marchands, fermer la modal
          if (document.querySelectorAll('[id^="merchant-"]').length === 0) {
            onClose();
          }
        }, 300);
      }
    } catch (err) {
      console.error('Erreur validation marchand:', err);
      setError('Une erreur est survenue lors de la validation');
    } finally {
      setLoading(prev => ({ ...prev, [merchantId]: false }));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <Store className="h-6 w-6 text-primary-600" />
            <h3 className="text-xl font-semibold text-gray-900">Validation des marchands</h3>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md">
            {error}
          </div>
        )}

        <div className="space-y-4">
          {merchants.length === 0 ? (
            <p className="text-center text-gray-600 py-4">
              Aucun marchand en attente de validation
            </p>
          ) : (
            merchants.map((merchant) => (
              <div
                key={merchant.id}
                id={`merchant-${merchant.id}`}
                className="bg-gray-50 rounded-lg p-4 transition-all duration-300"
              >
                <div className="flex justify-between">
                  <div className="space-y-2">
                    <h4 className="font-semibold text-gray-900">
                      {merchant.merchantInfo.companyName}
                    </h4>
                    <div className="space-y-1 text-sm text-gray-600">
                      <p className="flex items-center space-x-2">
                        <Store className="h-4 w-4" />
                        <span>SIREN: {merchant.merchantInfo.siren}</span>
                      </p>
                      <p className="flex items-center space-x-2">
                        <MapPin className="h-4 w-4" />
                        <span>{merchant.merchantInfo.businessAddress}</span>
                      </p>
                      {merchant.phone && (
                        <p className="flex items-center space-x-2">
                          <Phone className="h-4 w-4" />
                          <span>{merchant.phone}</span>
                        </p>
                      )}
                      {merchant.merchantInfo.website && (
                        <p className="flex items-center space-x-2">
                          <Globe className="h-4 w-4" />
                          <a
                            href={merchant.merchantInfo.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary-600 hover:text-primary-700"
                          >
                            Site web
                          </a>
                        </p>
                      )}
                      <p className="flex items-center space-x-2">
                        <Store className="h-4 w-4" />
                        <span>
                          {MERCHANT_CATEGORIES.find(
                            cat => cat.id === merchant.merchantInfo.category
                          )?.label || 'Catégorie inconnue'}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleValidation(merchant.id, false)}
                      disabled={loading[merchant.id]}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
                    >
                      <XCircle className="h-6 w-6" />
                    </button>
                    <button
                      onClick={() => handleValidation(merchant.id, true)}
                      disabled={loading[merchant.id]}
                      className="p-2 text-green-600 hover:bg-green-50 rounded-full transition-colors"
                    >
                      {loading[merchant.id] ? (
                        <Loader className="h-6 w-6 animate-spin" />
                      ) : (
                        <CheckCircle className="h-6 w-6" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}