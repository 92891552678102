export const tips = [
  "N'oubliez pas de scanner les QR codes des commerçants pour valider vos quêtes !",
  "Vous pouvez utiliser jusqu'à 20% de SUNCOINS sur vos achats.",
  "Complétez des quêtes quotidiennes pour gagner des SUNCOINS bonus !",
  "Parrainez vos amis et gagnez 100 SUNCOINS pour chaque nouveau membre.",
  "Les trophées légendaires rapportent 500 SUNCOINS, gardez l'œil ouvert !",
  "Consultez régulièrement la section 'Coupons' pour ne pas manquer les meilleures offres.",
  "Plus vous visitez un commerçant, plus vous gagnez de SUNCOINS !",
  "Activez les notifications pour ne manquer aucune nouvelle quête.",
  "Le mode TAKE IT se renouvelle toutes les heures, revenez souvent !",
  "Certains commerçants proposent des quêtes spéciales pendant les événements.",
  "Combinez plusieurs quêtes dans la même zone pour optimiser vos gains.",
  "Les quêtes de type 'TREASURE' offrent les meilleures récompenses !",
  "Suivez vos commerçants préférés pour être informé de leurs nouvelles offres.",
  "Complétez votre profil pour débloquer des quêtes exclusives.",
  "Participez aux événements saisonniers pour gagner des récompenses uniques."
];