import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

interface CollectAnimationProps {
  position: { x: number; y: number };
  value: number;
  isLegendary?: boolean;
}

export default function CollectAnimation({ position, value, isLegendary }: CollectAnimationProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const particleCount = isLegendary ? 12 : 8;
    
    // Create sparkle particles
    const particles = Array.from({ length: particleCount }, () => {
      const particle = document.createElement('div');
      particle.className = `absolute w-2 h-2 rounded-full ${
        isLegendary ? 'bg-pink-400' : 'bg-yellow-400'
      }`;
      container.appendChild(particle);
      
      const angle = Math.random() * Math.PI * 2;
      const distance = 30 + Math.random() * 30;

      gsap.fromTo(particle, 
        { 
          x: 0, 
          y: 0, 
          scale: 1, 
          opacity: 1,
          rotation: 0
        },
        { 
          x: Math.cos(angle) * distance,
          y: Math.sin(angle) * distance,
          scale: 0,
          opacity: 0,
          rotation: 360,
          duration: 0.8 + Math.random() * 0.4,
          ease: "power2.out"
        }
      );

      return particle;
    });

    // Create shine effect
    const shine = document.createElement('div');
    shine.className = `absolute inset-0 rounded-full ${
      isLegendary ? 'bg-pink-400' : 'bg-yellow-400'
    } opacity-50`;
    container.appendChild(shine);

    gsap.fromTo(shine,
      { scale: 0.5, opacity: 0.8 },
      { scale: 2, opacity: 0, duration: 0.5, ease: "power2.out" }
    );

    // Create and animate value text with glow effect
    const valueEl = document.createElement('div');
    valueEl.className = `font-bold text-2xl ${
      isLegendary ? 'text-pink-500' : 'text-yellow-500'
    } drop-shadow-glow`;
    valueEl.textContent = `+${value}`;
    valueEl.style.textShadow = isLegendary ?
      '0 0 10px rgba(236, 72, 153, 0.7)' :
      '0 0 10px rgba(234, 179, 8, 0.7)';
    container.appendChild(valueEl);

    gsap.fromTo(valueEl,
      { y: 0, scale: 1, opacity: 1 },
      { 
        y: -40,
        scale: 1.2,
        opacity: 0,
        duration: 1,
        ease: "power2.out"
      }
    );

    // Cleanup
    return () => {
      particles.forEach(particle => particle.remove());
      shine.remove();
      valueEl.remove();
      gsap.killTweensOf([...particles, shine, valueEl]);
    };
  }, [position, value, isLegendary]);

  return (
    <div
      ref={containerRef}
      className="fixed pointer-events-none flex items-center justify-center"
      style={{
        left: position.x - 20,
        top: position.y - 20,
        width: 40,
        height: 40,
        zIndex: 1000
      }}
    />
  );
}