import React from 'react';
import { Trophy, Gift, Users, Coins, Crown, Store } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import ReferralStats from '../components/ReferralStats';
import ReferralQRCode from '../components/ReferralQRCode';

function Referral() {
  const { currentUser, userData } = useAuth();

  if (!currentUser || !userData) {
    return (
      <div className="min-h-[80vh] flex items-center justify-center p-4">
        <div className="text-center">
          <Gift className="h-12 w-12 text-primary-600 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Connectez-vous pour accéder au programme de parrainage
          </h2>
          <p className="text-gray-600">
            Parrainez vos amis et gagnez des SUNCOINS !
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto space-y-8">
      <div className="bg-gradient-to-r from-primary-50 to-accent-50 rounded-xl p-6 shadow-md">
        <div className="flex items-center gap-4">
          <div className="hidden md:block">
            <div className="w-16 h-16 bg-white rounded-full shadow-lg flex items-center justify-center">
              <Crown className="h-8 w-8 text-primary-600" />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900 mb-2">
              Programme de parrainage
            </h1>
            <p className="text-gray-700">
              Invitez vos amis à rejoindre Sunkdo et gagnez des récompenses !
            </p>
          </div>
        </div>
      </div>

      <ReferralStats />

      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Gift className="h-6 w-6 text-primary-600" />
          <h2 className="text-xl font-bold text-gray-900">Récompenses</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-primary-50 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-3 flex items-center gap-2">
              <Users className="h-5 w-5 text-primary-600" />
              Pour les clients
            </h3>
            <ul className="space-y-2 text-gray-700">
              <li className="flex items-center gap-2">
                <Coins className="h-4 w-4 text-primary-600" />
                <span>100 SUNCOINS par client parrainé</span>
              </li>
              <li className="flex items-center gap-2">
                <Gift className="h-4 w-4 text-primary-600" />
                <span>50 SUNCOINS pour le filleul</span>
              </li>
            </ul>
          </div>

          <div className="bg-accent-50 rounded-lg p-4">
            <h3 className="font-semibold text-gray-900 mb-3 flex items-center gap-2">
              <Store className="h-5 w-5 text-accent-600" />
              Pour les commerçants
            </h3>
            <ul className="space-y-2 text-gray-700">
              <li className="flex items-center gap-2">
                <Coins className="h-4 w-4 text-accent-600" />
                <span>1000 SUNCOINS par marchand parrainé</span>
              </li>
              <li className="flex items-center gap-2">
                <Gift className="h-4 w-4 text-accent-600" />
                <span>500 SUNCOINS pour le filleul</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-yellow-50 p-4 rounded-lg mb-8">
          <h3 className="font-semibold text-gray-900 mb-3 flex items-center gap-2">
            <Trophy className="h-5 w-5 text-yellow-600" />
            Paliers de récompenses
          </h3>
          <ul className="space-y-2 text-gray-700">
            <li className="flex items-center justify-between">
              <span>5 parrainages</span>
              <span className="font-semibold text-primary-600">+250 SUNCOINS</span>
            </li>
            <li className="flex items-center justify-between">
              <span>10 parrainages</span>
              <span className="font-semibold text-primary-600">+750 SUNCOINS</span>
            </li>
            <li className="flex items-center justify-between">
              <span>25 parrainages</span>
              <span className="font-semibold text-primary-600">+2500 SUNCOINS</span>
            </li>
            <li className="flex items-center justify-between">
              <span>50 parrainages</span>
              <span className="font-semibold text-primary-600">+7500 SUNCOINS</span>
            </li>
            <li className="flex items-center justify-between">
              <span>100 parrainages</span>
              <span className="font-semibold text-primary-600">+25000 SUNCOINS</span>
            </li>
          </ul>
        </div>

        <ReferralQRCode />
      </div>
    </div>
  );
}

export default Referral;