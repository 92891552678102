import React, { useState, useEffect } from 'react';
import { MapPin, Percent, Globe, Search, ChevronDown, Store } from 'lucide-react';
import { collection, getDocs } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { initMapsApi } from '../lib/maps';
import { MERCHANT_CATEGORIES } from '../lib/constants';

interface Merchant {
  id: string;
  name: string;
  address: string;
  discount: string;
  category: string;
  image: string;
  coordinates: { lat: number; lng: number };
  website?: string;
  distance?: number;
}

function PhysicalMerchants() {
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [filteredMerchants, setFilteredMerchants] = useState<Merchant[]>([]);
  const [loading, setLoading] = useState(true);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [showCategories, setShowCategories] = useState(false);
  const [mapsLoaded, setMapsLoaded] = useState(false);

  useEffect(() => {
    const initMaps = async () => {
      try {
        await initMapsApi();
        setMapsLoaded(true);
      } catch (error) {
        console.error('Erreur lors de l\'initialisation de Google Maps:', error);
      }
    };

    initMaps();
  }, []);

  useEffect(() => {
    if (mapsLoaded && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => {
          console.error('Erreur de géolocalisation:', error);
        }
      );
    }
  }, [mapsLoaded]);

  useEffect(() => {
    const fetchMerchants = async () => {
      if (!mapsLoaded) return;

      try {
        const merchantsRef = collection(db, COLLECTIONS.USERS);
        const snapshot = await getDocs(merchantsRef);
        const fetchedMerchants = snapshot.docs
          .filter(doc => doc.data().role === 'merchant')
          .map(doc => {
            const data = doc.data();
            const merchant = {
              id: doc.id,
              name: data.merchantInfo?.companyName || 'Commerce',
              address: data.merchantInfo?.businessAddress || '',
              discount: '10%',
              category: data.merchantInfo?.category || 'Commerce',
              image: "https://images.unsplash.com/photo-1509440159596-0249088772ff?auto=format&fit=crop&q=80&w=1000",
              coordinates: data.merchantInfo?.location || { lat: 48.8584, lng: 2.2945 },
              website: data.merchantInfo?.website
            };

            if (userLocation && data.merchantInfo?.location) {
              const distance = google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(userLocation),
                new google.maps.LatLng(data.merchantInfo.location)
              );
              return { ...merchant, distance };
            }

            return merchant;
          });

        if (userLocation) {
          fetchedMerchants.sort((a, b) => (a.distance || Infinity) - (b.distance || Infinity));
        }

        setMerchants(fetchedMerchants);
        setFilteredMerchants(fetchedMerchants);
      } catch (error) {
        console.error('Erreur lors de la récupération des marchands:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMerchants();
  }, [userLocation, mapsLoaded]);

  useEffect(() => {
    let filtered = [...merchants];

    if (searchTerm) {
      filtered = filtered.filter(merchant => 
        merchant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        merchant.address.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(merchant => 
        merchant.category === selectedCategory
      );
    }

    filtered.sort((a, b) => (a.distance || Infinity) - (b.distance || Infinity));
    setFilteredMerchants(filtered);
  }, [searchTerm, selectedCategory, merchants]);

  const formatDistance = (meters?: number): string => {
    if (!meters) return '';
    return meters >= 1000 
      ? `${(meters / 1000).toFixed(1)} km`
      : `${Math.round(meters)} m`;
  };

  const openGoogleMaps = (coordinates: { lat: number; lng: number }) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`,
      '_blank'
    );
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Rechercher un commerce..."
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
          </div>
        </div>

        <div className="relative mt-4">
          <button
            onClick={() => setShowCategories(!showCategories)}
            className="w-full md:w-64 flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md bg-white hover:bg-gray-50 transition-colors duration-200"
          >
            <span className="text-gray-700">
              {selectedCategory ? 
                MERCHANT_CATEGORIES.find(c => c.id === selectedCategory)?.label : 
                'Toutes les catégories'}
            </span>
            <ChevronDown className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${
              showCategories ? 'transform rotate-180' : ''
            }`} />
          </button>

          {showCategories && (
            <div className="absolute z-10 mt-1 w-full md:w-64 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
              <div className="py-1">
                <button
                  onClick={() => {
                    setSelectedCategory('');
                    setShowCategories(false);
                  }}
                  className="w-full text-left px-4 py-2 text-gray-700 hover:bg-primary-50 hover:text-primary-700"
                >
                  Toutes les catégories
                </button>
                {MERCHANT_CATEGORIES.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => {
                      setSelectedCategory(category.id);
                      setShowCategories(false);
                    }}
                    className={`w-full text-left px-4 py-2 hover:bg-primary-50 hover:text-primary-700 ${
                      selectedCategory === category.id 
                        ? 'bg-primary-50 text-primary-700' 
                        : 'text-gray-700'
                    }`}
                  >
                    {category.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <p className="text-sm text-gray-600 mt-4">
          {filteredMerchants.length} commerce{filteredMerchants.length > 1 ? 's' : ''} trouvé{filteredMerchants.length > 1 ? 's' : ''}
        </p>
      </div>

      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600 mx-auto"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredMerchants.map((merchant) => (
            <div
              key={merchant.id}
              className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow"
            >
              <div className="h-48 w-full overflow-hidden">
                <img
                  src={merchant.image}
                  alt={merchant.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6 space-y-4">
                <h3 className="text-xl font-semibold text-gray-900">{merchant.name}</h3>
                <div className="flex items-center space-x-2 text-gray-600">
                  <MapPin className="h-5 w-5" />
                  <span>{merchant.address}</span>
                </div>
                {merchant.distance && (
                  <div className="flex items-center space-x-2 text-primary-600">
                    <MapPin className="h-5 w-5" />
                    <span>{formatDistance(merchant.distance)}</span>
                  </div>
                )}
                <div className="flex items-center space-x-2 text-primary-600">
                  <Percent className="h-5 w-5" />
                  <span className="font-semibold">{merchant.discount} de remise</span>
                </div>
                {merchant.website && (
                  <a
                    href={merchant.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-primary-600 hover:text-primary-700"
                  >
                    <Globe className="h-5 w-5" />
                    <span>Voir le catalogue</span>
                  </a>
                )}
                <div className="flex space-x-4">
                  <button
                    onClick={() => openGoogleMaps(merchant.coordinates)}
                    className="flex-1 bg-primary-600 text-white py-2 px-4 rounded-md hover:bg-primary-700 transition-colors duration-300"
                  >
                    Y aller
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PhysicalMerchants;