import React from 'react';

export default function SunnyAvatar() {
  return (
    <div className="relative w-8 h-8">
      <svg
        viewBox="0 0 100 100"
        className="w-full h-full"
        style={{ filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))' }}
      >
        {/* Background Circle */}
        <circle cx="50" cy="50" r="45" fill="#40E0D0" />
        
        {/* Robot Head */}
        <rect
          x="25"
          y="30"
          width="50"
          height="40"
          rx="20"
          fill="white"
        />
        
        {/* Antenna */}
        <rect x="47" y="15" width="6" height="15" fill="white" />
        <circle cx="50" cy="12" r="4" fill="white" />
        
        {/* Eyes Strip */}
        <rect
          x="30"
          y="40"
          width="40"
          height="15"
          rx="7.5"
          fill="#1A1A1A"
        />
        
        {/* Eyes */}
        <circle cx="40" cy="47.5" r="5" fill="#40E0D0" />
        <circle cx="60" cy="47.5" r="5" fill="#40E0D0" />
        
        {/* Smile */}
        <path
          d="M 40 60 Q 50 65 60 60"
          fill="none"
          stroke="#1A1A1A"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}