import { STRIPE_CONFIG } from './config';
import { PaymentError } from './errors';
import { SUBSCRIPTION_PLANS } from './constants';

export type SubscriptionPlan = 'PREMIUM' | 'MERCHANT';

export const createSubscription = async (
  userId: string, 
  plan: SubscriptionPlan
): Promise<string> => {
  try {
    const response = await fetch('/.netlify/functions/create-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        priceId: SUBSCRIPTION_PLANS[plan].id,
        planType: plan
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new PaymentError(
        error.message || 'Subscription creation failed',
        error.code || 'subscription_creation_failed'
      );
    }

    const { sessionId } = await response.json();
    return sessionId;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export const cancelSubscription = async (subscriptionId: string): Promise<void> => {
  try {
    const response = await fetch('/.netlify/functions/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ subscriptionId }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new PaymentError(
        error.message || 'Subscription cancellation failed',
        error.code || 'subscription_cancellation_failed'
      );
    }
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
};