import { getMessaging, getToken } from 'firebase/messaging';
import { doc, setDoc } from 'firebase/firestore';
import { db, COLLECTIONS, messaging, VAPID_KEY } from './firebase';

export const initNotifications = async (userId: string) => {
  try {
    // Vérifier si les notifications sont supportées
    if (!('Notification' in window)) {
      throw new Error('Les notifications ne sont pas supportées par ce navigateur');
    }

    // Vérifier si le service worker est supporté
    if (!('serviceWorker' in navigator)) {
      throw new Error('Service Workers ne sont pas supportés par ce navigateur');
    }

    // Demander la permission
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      throw new Error('Permission refusée pour les notifications');
    }

    // Vérifier si Firebase Messaging est disponible
    if (!messaging) {
      throw new Error('Firebase Messaging n\'est pas disponible dans cet environnement');
    }

    try {
      // Enregistrer le service worker d'abord
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
        scope: '/',
        type: 'module'
      });
      
      // Attendre que le service worker soit prêt
      await navigator.serviceWorker.ready;
      
      // Obtenir le token FCM
      const currentToken = await getToken(messaging, {
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration: registration
      });

      if (!currentToken) {
        throw new Error('Impossible d\'obtenir le token de notification');
      }

      // Sauvegarder le token dans Firestore
      await setDoc(doc(db, COLLECTIONS.USERS, userId), {
        notificationToken: currentToken,
        notificationsEnabled: true
      }, { merge: true });

      console.log('Notifications initialisées avec succès');
      return true;
    } catch (error: any) {
      console.error('Erreur FCM token:', error);
      throw new Error('Erreur lors de l\'obtention du token FCM: ' + error.message);
    }
  } catch (error: any) {
    console.error('Erreur initialisation notifications:', error);
    throw new Error(error.message || 'Erreur lors de l\'activation des notifications');
  }
};