import React, { useMemo } from 'react';

interface WelcomeMessageProps {
  userName?: string;
}

export function WelcomeMessage({ userName }: WelcomeMessageProps) {
  const loggedInMessages = [
    {
      title: `Hey ${userName} ! 🎉 Trop content de te revoir !`,
      subtitle: "Prêt(e) pour de nouvelles découvertes chez nos super commerçants ?"
    },
    {
      title: `${userName} ! 🌟 La star du shopping est de retour !`,
      subtitle: "On t'a préparé plein de bonnes affaires aujourd'hui !"
    },
    {
      title: `Woohoo ! ${userName} est dans la place ! 🚀`,
      subtitle: "Les commerçants n'attendaient que toi !"
    },
    {
      title: `Re-coucou ${userName} ! 🎯 Prêt(e) pour une nouvelle quête ?`,
      subtitle: "De nouvelles aventures t'attendent chez nos commerçants !"
    },
    {
      title: `${userName} ! 🎪 Le festival du shopping peut commencer !`,
      subtitle: "Nos commerçants ont hâte de te revoir !"
    },
    {
      title: `Hey ${userName} ! 🌈 Content de te retrouver !`,
      subtitle: "Plein de nouveautés t'attendent aujourd'hui !"
    }
  ];

  const guestMessages = [
    {
      title: "Bienvenue chez nos commerçants partenaires ! 🎉",
      subtitle: "Découvre une sélection unique de commerces locaux et commence à gagner des SUNCOINS !"
    },
    {
      title: "Hello l'aventurier(e) ! 🌟",
      subtitle: "Prêt(e) à découvrir le shopping version fun ?"
    },
    {
      title: "Bienvenue dans la communauté ! 🚀",
      subtitle: "Rejoins l'aventure et transforme ton shopping en jeu !"
    },
    {
      title: "Hey ! Content de te voir ! 🎯",
      subtitle: "Découvre nos commerçants et commence à gagner des récompenses !"
    },
    {
      title: "Salut toi ! 🎪",
      subtitle: "Prêt(e) à révolutionner ta façon de faire du shopping ?"
    }
  ];

  const message = useMemo(() => {
    const messages = userName ? loggedInMessages : guestMessages;
    return messages[Math.floor(Math.random() * messages.length)];
  }, [userName]);

  return (
    <div className="bg-gradient-to-r from-primary-100 to-accent-100 rounded-xl p-6 shadow-md">
      <h2 className="text-2xl font-bold text-primary-800">
        {message.title}
      </h2>
      <p className="mt-2 text-primary-600">
        {message.subtitle}
      </p>
    </div>
  );
}