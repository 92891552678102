import React, { useState, useEffect, useRef } from 'react';
import { MapPin, Trophy, Clock, Coins, Navigation2, Loader, QrCode, AlertCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { collection, query, where, getDocs, Timestamp, limit } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { initMapsApi } from '../lib/maps';
import { getCurrentPosition, watchPosition, clearWatch } from '../lib/utils/geo';
import QuestScanner from '../components/QuestScanner';
import { validateQuest } from '../lib/quests';

interface Quest {
  id: string;
  title: string;
  description: string;
  type: 'WALK' | 'VISIT' | 'PURCHASE';
  reward: number;
  merchantId: string;
  merchantName: string;
  location: google.maps.LatLngLiteral;
  deadline: string;
  createdAt: Timestamp;
  active: boolean;
  distance?: number;
}

export default function Quests() {
  const { currentUser, userData } = useAuth();
  const [quests, setQuests] = useState<Quest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const [selectedQuest, setSelectedQuest] = useState<Quest | null>(null);
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer | null>(null);
  const [showScanner, setShowScanner] = useState(false);
  const [scanSuccess, setScanSuccess] = useState<string | null>(null);
  const [locationError, setLocationError] = useState<string | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const isMobile = window.innerWidth <= 768;
  const questMarkersRef = useRef<google.maps.Marker[]>([]);
  const userMarkerRef = useRef<google.maps.Marker | null>(null);
  const watchIdRef = useRef<number | null>(null);

  // Cleanup function
  useEffect(() => {
    return () => {
      // Clear markers
      questMarkersRef.current.forEach(marker => marker.setMap(null));
      questMarkersRef.current = [];
      
      // Clear user marker
      if (userMarkerRef.current) {
        userMarkerRef.current.setMap(null);
        userMarkerRef.current = null;
      }
      
      // Clear location watch
      if (watchIdRef.current) {
        clearWatch(watchIdRef.current);
        watchIdRef.current = null;
      }
    };
  }, []);

  // Initialize map
  useEffect(() => {
    const initMap = async () => {
      if (!mapRef.current) return;

      try {
        setLoading(true);
        setError(null);
        setLocationError(null);

        const maps = await initMapsApi();
        
        const mapInstance = new maps.Map(mapRef.current, {
          zoom: 15,
          disableDefaultUI: false,
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            }
          ]
        });

        setMap(mapInstance);

        const directionsRendererInstance = new maps.DirectionsRenderer({
          map: mapInstance,
          suppressMarkers: true,
          preserveViewport: true
        });
        setDirectionsRenderer(directionsRendererInstance);

        // Get initial position
        try {
          const position = await getCurrentPosition();
          const userPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(userPos);
          mapInstance.setCenter(userPos);

          // Create user marker
          userMarkerRef.current = new maps.Marker({
            position: userPos,
            map: mapInstance,
            icon: {
              url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
              scaledSize: new maps.Size(32, 32)
            },
            title: 'Votre position'
          });

          // Start watching position
          watchIdRef.current = watchPosition(
            (position) => {
              const newPos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              setUserLocation(newPos);
              if (userMarkerRef.current) {
                userMarkerRef.current.setPosition(newPos);
              }
            },
            (error) => {
              console.error('Watch position error:', error);
              setLocationError('Erreur lors du suivi de votre position');
            }
          );
        } catch (error: any) {
          console.error('Geolocation error:', error);
          setLocationError(error.message || 'Impossible d\'obtenir votre position');
        }
      } catch (error: any) {
        console.error('Map initialization error:', error);
        setError(error.message || 'Erreur lors du chargement de la carte');
      } finally {
        setLoading(false);
      }
    };

    initMap();
  }, []);

  // Fetch quests when map and location are ready
  useEffect(() => {
    const fetchQuests = async () => {
      if (!map || !userLocation) return;

      try {
        setLoading(true);
        setError(null);

        // Clear existing markers
        questMarkersRef.current.forEach(marker => marker.setMap(null));
        questMarkersRef.current = [];

        const questsRef = collection(db, COLLECTIONS.QUESTS);
        const q = query(
          questsRef,
          where('active', '==', true),
          limit(20)
        );
        const snapshot = await getDocs(q);
        
        if (snapshot.empty) {
          setQuests([]);
          return;
        }

        const now = new Date();
        const fetchedQuests = snapshot.docs
          .map(doc => {
            const data = doc.data();
            
            if (!data.location || !data.location.lat || !data.location.lng) {
              console.warn(`Quest ${doc.id} has invalid location`);
              return null;
            }

            const distance = google.maps.geometry.spherical.computeDistanceBetween(
              new google.maps.LatLng(userLocation),
              new google.maps.LatLng(data.location)
            );

            return {
              id: doc.id,
              ...data,
              distance
            } as Quest;
          })
          .filter((quest): quest is Quest => 
            quest !== null && 
            new Date(quest.deadline) > now
          )
          .sort((a, b) => (a.distance || 0) - (b.distance || 0));

        setQuests(fetchedQuests);

        // Create markers for quests
        fetchedQuests.forEach(quest => {
          const marker = new google.maps.Marker({
            position: quest.location,
            map: map,
            title: quest.title,
            icon: {
              url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
              scaledSize: new google.maps.Size(32, 32)
            }
          });

          marker.addListener('click', () => {
            setSelectedQuest(quest);
            calculateRoute(quest);
          });

          questMarkersRef.current.push(marker);
        });

        // Fit bounds to include all markers
        if (fetchedQuests.length > 0) {
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(userLocation);
          fetchedQuests.forEach(quest => bounds.extend(quest.location));
          map.fitBounds(bounds);
        }
      } catch (err) {
        console.error('Error fetching quests:', err);
        setError('Erreur lors du chargement des quêtes');
      } finally {
        setLoading(false);
      }
    };

    fetchQuests();
  }, [map, userLocation]);

  const calculateRoute = async (quest: Quest) => {
    if (!userLocation || !directionsRenderer || !map) return;

    const directionsService = new google.maps.DirectionsService();

    try {
      const result = await directionsService.route({
        origin: userLocation,
        destination: quest.location,
        travelMode: google.maps.TravelMode.WALKING
      });

      directionsRenderer.setDirections(result);
      
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(userLocation);
      bounds.extend(quest.location);
      map.fitBounds(bounds);
    } catch (error) {
      console.error('Route calculation error:', error);
      setError('Impossible de calculer l\'itinéraire');
    }
  };

  const handleQuestValidation = async (questId: string) => {
    if (!currentUser || !userData) return;

    try {
      await validateQuest(questId, currentUser.uid, userData.fidecoins || 0, 100);
      setScanSuccess('Félicitations ! Vous avez gagné 100 SUNCOINS !');
      
      // Refresh quests after validation
      const questIndex = quests.findIndex(q => q.id === questId);
      if (questIndex !== -1) {
        const newQuests = [...quests];
        newQuests.splice(questIndex, 1);
        setQuests(newQuests);
        
        // Remove marker
        if (questMarkersRef.current[questIndex]) {
          questMarkersRef.current[questIndex].setMap(null);
          questMarkersRef.current.splice(questIndex, 1);
        }
      }
    } catch (error: any) {
      console.error('Quest validation error:', error);
      setError(error.message || 'Erreur lors de la validation de la quête');
    }
  };

  const formatDistance = (meters: number): string => {
    return meters >= 1000 
      ? `${(meters / 1000).toFixed(1)} km`
      : `${Math.round(meters)} m`;
  };

  const openInGoogleMaps = (quest: Quest) => {
    if (isMobile && userLocation) {
      const url = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.lng}&destination=${quest.location.lat},${quest.location.lng}&travelmode=walking`;
      window.open(url, '_blank');
    } else {
      setSelectedQuest(quest);
      calculateRoute(quest);
    }
  };

  return (
    <div className="h-[calc(100vh-4rem)] flex flex-col space-y-4">
      {/* Map Section */}
      <div className="h-[50vh] bg-white rounded-lg overflow-hidden shadow-lg relative">
        <div ref={mapRef} className="w-full h-full" />
        
        {locationError && (
          <div className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-red-50 text-red-600 px-4 py-2 rounded-lg shadow flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{locationError}</span>
          </div>
        )}
        
        {loading && (
          <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
            <Loader className="h-8 w-8 animate-spin text-primary-600" />
          </div>
        )}
      </div>

      {/* Quests List Section */}
      <div className="flex-1 bg-white rounded-lg shadow-lg p-4 overflow-y-auto">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Quêtes disponibles</h2>
        
        {scanSuccess && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-lg flex items-center space-x-2">
            <Trophy className="h-5 w-5" />
            <span>{scanSuccess}</span>
          </div>
        )}
        
        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        {currentUser && (
          <button
            onClick={() => setShowScanner(true)}
            className="w-full flex items-center justify-center space-x-2 bg-primary-600 text-white px-4 py-3 rounded-lg hover:bg-primary-700 transition-colors mb-4"
          >
            <QrCode className="h-5 w-5" />
            <span>Scanner une quête</span>
          </button>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-32">
            <Loader className="h-8 w-8 animate-spin text-primary-600" />
          </div>
        ) : quests.length === 0 ? (
          <p className="text-gray-600 text-center">Aucune quête disponible pour le moment.</p>
        ) : (
          <div className="space-y-4">
            {quests.map((quest) => (
              <div
                key={quest.id}
                className={`p-4 rounded-lg border transition-colors cursor-pointer ${
                  selectedQuest?.id === quest.id
                    ? 'border-primary-500 bg-primary-50'
                    : 'border-gray-200 hover:border-primary-300'
                }`}
                onClick={() => openInGoogleMaps(quest)}
              >
                <div className="flex items-start justify-between">
                  <div className="flex-1">
                    <h3 className="font-semibold text-gray-900">{quest.title}</h3>
                    <p className="text-sm text-gray-600 mt-1">{quest.description}</p>
                    
                    <div className="flex items-center space-x-4 mt-2 text-sm">
                      <div className="flex items-center text-gray-600">
                        <MapPin className="h-4 w-4 mr-1" />
                        <span>{quest.merchantName}</span>
                      </div>
                      <div className="flex items-center text-primary-600">
                        <Coins className="h-4 w-4 mr-1" />
                        <span>{quest.reward} SUNCOINS</span>
                      </div>
                    </div>

                    <div className="flex items-center mt-2 text-sm text-gray-500">
                      <Clock className="h-4 w-4 mr-1" />
                      <span>
                        Expire le {new Date(quest.deadline).toLocaleDateString()}
                      </span>
                    </div>

                    {quest.distance && (
                      <div className="mt-2 text-sm font-medium text-primary-600">
                        Distance : {formatDistance(quest.distance)}
                      </div>
                    )}
                  </div>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openInGoogleMaps(quest);
                    }}
                    className="ml-4 p-2 text-primary-600 hover:bg-primary-50 rounded-full"
                  >
                    <Navigation2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showScanner && currentUser && (
        <QuestScanner
          userId={currentUser.uid}
          onScan={(questId) => {
            handleQuestValidation(questId);
            setShowScanner(false);
          }}
          onClose={() => setShowScanner(false)}
        />
      )}
    </div>
  );
}