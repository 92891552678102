import React from 'react';
import { doc, runTransaction, collection } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { AlertTriangle, Check, X, Coins } from 'lucide-react';

interface PaymentNotificationProps {
  requestId: string;
  amount: number;
  suncoinsRequested: number;
  onClose: () => void;
}

export default function PaymentNotification({ 
  requestId, 
  amount, 
  suncoinsRequested,
  onClose 
}: PaymentNotificationProps) {
  const { currentUser, userData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleResponse = async (approved: boolean) => {
    if (!currentUser || !userData) return;

    try {
      setLoading(true);
      setError(null);

      await runTransaction(db, async (transaction) => {
        // Get payment request
        const requestRef = doc(db, COLLECTIONS.PAYMENT_REQUESTS, requestId);
        const requestDoc = await transaction.get(requestRef);
        
        if (!requestDoc.exists()) {
          throw new Error('Demande de paiement introuvable');
        }

        const requestData = requestDoc.data();
        const merchantId = requestData.merchantId;

        // Get up-to-date user and merchant data
        const userRef = doc(db, COLLECTIONS.USERS, currentUser.uid);
        const merchantRef = doc(db, COLLECTIONS.USERS, merchantId);
        
        const [userDoc, merchantDoc] = await Promise.all([
          transaction.get(userRef),
          transaction.get(merchantRef)
        ]);

        if (!merchantDoc.exists()) {
          throw new Error('Compte marchand introuvable');
        }

        const currentUserSuncoins = userDoc.data().fidecoins || 0;
        const currentMerchantSuncoins = merchantDoc.data().fidecoins || 0;

        if (approved) {
          // Verify user still has enough SUNCOINS
          if (currentUserSuncoins < suncoinsRequested) {
            throw new Error('Solde SUNCOINS insuffisant');
          }

          // Update balances
          transaction.update(userRef, {
            fidecoins: currentUserSuncoins - suncoinsRequested,
            suncoinsSpent: (userDoc.data().suncoinsSpent || 0) + suncoinsRequested,
            totalSpent: (userDoc.data().totalSpent || 0) + amount
          });

          transaction.update(merchantRef, {
            fidecoins: currentMerchantSuncoins + suncoinsRequested,
            totalRevenue: (merchantDoc.data().totalRevenue || 0) + amount
          });

          // Create payment record
          const paymentRef = doc(collection(db, COLLECTIONS.PAYMENTS));
          transaction.set(paymentRef, {
            merchantId,
            merchantName: merchantDoc.data().merchantInfo?.companyName,
            userId: currentUser.uid,
            amount,
            suncoinsUsed: suncoinsRequested,
            timestamp: new Date(),
            status: 'completed'
          });
        }

        // Update request status
        transaction.update(requestRef, {
          status: approved ? 'approved' : 'rejected',
          processedAt: new Date()
        });
      });

      onClose();
    } catch (err: any) {
      console.error('Error processing payment:', err);
      setError(err.message || 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-xl font-semibold text-gray-900">Demande de paiement</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg space-y-3">
            <div className="flex items-center justify-between">
              <span className="text-gray-600">Montant total:</span>
              <span className="font-bold text-gray-900">{amount.toFixed(2)}€</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">SUNCOINS demandés:</span>
              <div className="flex items-center space-x-2 text-primary-600">
                <Coins className="h-5 w-5" />
                <span className="font-bold">{suncoinsRequested}</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">Votre solde:</span>
              <div className="flex items-center space-x-2 text-primary-600">
                <Coins className="h-5 w-5" />
                <span className="font-bold">{userData?.fidecoins || 0}</span>
              </div>
            </div>
          </div>

          {error && (
            <div className="p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
              <AlertTriangle className="h-5 w-5" />
              <span>{error}</span>
            </div>
          )}

          <div className="flex space-x-4">
            <button
              onClick={() => handleResponse(false)}
              disabled={loading}
              className="flex-1 flex items-center justify-center space-x-2 px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              <X className="h-5 w-5" />
              <span>Refuser</span>
            </button>
            <button
              onClick={() => handleResponse(true)}
              disabled={loading || (userData?.fidecoins || 0) < suncoinsRequested}
              className="flex-1 flex items-center justify-center space-x-2 px-4 py-2 border border-transparent rounded-md text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50"
            >
              <Check className="h-5 w-5" />
              <span>Approuver</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}