import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: 'AIzaSyCCTxQbEc_Bc1mEgzEgruaqAGExUY9aN_0',
  version: 'weekly',
  libraries: ['places', 'geometry'],
  language: 'fr',
  region: 'FR'
});

let mapsApiInitialized = false;
let mapsInstance: typeof google.maps | null = null;

export const initMapsApi = async () => {
  if (mapsApiInitialized && mapsInstance) {
    return mapsInstance;
  }

  try {
    await loader.load();
    mapsApiInitialized = true;
    mapsInstance = window.google.maps;

    // Force le chargement complet de l'API
    await new Promise<void>((resolve) => {
      if (mapsInstance?.Map) {
        resolve();
      } else {
        const checkInterval = setInterval(() => {
          if (mapsInstance?.Map) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100);
      }
    });

    return mapsInstance;
  } catch (error) {
    console.error('Error loading Google Maps:', error);
    throw error;
  }
};