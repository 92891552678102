import { doc, getDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../firebase';
import { REFERRAL_MILESTONES } from './constants';
import type { ReferralStats } from './types';

export const getReferralStats = async (userId: string): Promise<ReferralStats> => {
  try {
    const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, userId));
    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    const referralCount = userData.referralCount || 0;
    const totalEarnings = userData.totalReferralEarnings || 0;
    
    // Find next milestone
    const nextMilestone = REFERRAL_MILESTONES.find(m => m.count > referralCount);
    
    return {
      referralCount,
      totalEarnings,
      nextMilestone: nextMilestone ? {
        count: nextMilestone.count,
        bonus: nextMilestone.bonus,
        remaining: nextMilestone.count - referralCount
      } : null,
      referrals: userData.referrals || []
    };
  } catch (error) {
    console.error('Error getting referral stats:', error);
    throw error;
  }
};