import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Store, Gift, ShoppingBag } from 'lucide-react';
import { WelcomeMessage } from '../components/WelcomeMessage';
import StepChallenge from '../components/StepChallenge';
import { useAuth } from '../contexts/AuthContext';

function Merchants() {
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <WelcomeMessage userName={userData?.name} />

      {currentUser && (
        <div className="mb-8">
          <StepChallenge />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <button
          onClick={() => navigate('/physical-merchants')}
          className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all duration-300 flex flex-col items-center justify-center space-y-4"
        >
          <Store className="h-12 w-12 text-primary-600" />
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900">Marchands Physiques</h3>
            <p className="mt-2 text-gray-600">Découvrez nos commerçants partenaires près de chez vous</p>
          </div>
        </button>

        <button
          onClick={() => navigate('/gift-cards')}
          className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all duration-300 flex flex-col items-center justify-center space-y-4"
        >
          <Gift className="h-12 w-12 text-primary-600" />
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900">Cartes Cadeaux</h3>
            <p className="mt-2 text-gray-600">Découvrez notre sélection de cartes cadeaux</p>
          </div>
        </button>
      </div>

      <button
        onClick={() => navigate('/radins3000')}
        className="w-full bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:from-yellow-500 hover:via-yellow-600 hover:to-yellow-700 text-white rounded-xl shadow-lg p-8 transition-all duration-300 group"
      >
        <div className="flex flex-col items-center space-y-4">
          <ShoppingBag className="h-16 w-16 transform group-hover:scale-110 transition-transform duration-300" />
          <div className="text-center">
            <h3 className="text-2xl font-bold mb-2">Bienvenue sur RADINS 3000 🤑</h3>
            <p className="text-lg opacity-90">
              Devenez un super-héros des bons plans ! Scannez, partagez et trouvez les meilleurs prix près de chez vous. 
              C'est comme un réseau social des économies, mais en plus fun ! 🦸‍♂️✨
            </p>
            <div className="mt-4 flex flex-col items-center space-y-2">
              <p className="text-sm bg-white/20 rounded-full px-4 py-2 inline-block">
                Rejoignez la communauté des chasseurs de promos ! 🎯
              </p>
              <p className="text-xs italic opacity-75">
                Complètement décalé mais on aime ça ! 😜
              </p>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}

export default Merchants;