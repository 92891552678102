import React, { useState, useEffect } from 'react';
import { Gift, Heart, Percent, Coins, Star, BookmarkIcon } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';

interface GiftCard {
  id: string;
  name: string;
  description: string;
  category: string;
  image: string;
  values: number[];
  discountPercent: number;
}

const GIFT_CARDS: GiftCard[] = [
  {
    id: 'amazon',
    name: 'Amazon',
    description: 'Utilisable sur des millions de produits',
    category: 'E-commerce',
    image: 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?auto=format&fit=crop&q=80&w=1000',
    values: [15, 25, 50, 100],
    discountPercent: 5
  },
  {
    id: 'fnac',
    name: 'Fnac',
    description: 'Culture, tech et loisirs',
    category: 'Culture',
    image: 'https://images.unsplash.com/photo-1544716278-ca5e3f4abd8c?auto=format&fit=crop&q=80&w=1000',
    values: [20, 30, 50, 100],
    discountPercent: 8
  },
  {
    id: 'decathlon',
    name: 'Decathlon',
    description: 'Tout pour le sport',
    category: 'Sport',
    image: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?auto=format&fit=crop&q=80&w=1000',
    values: [20, 50, 75, 100],
    discountPercent: 7
  },
  {
    id: 'sephora',
    name: 'Sephora',
    description: 'Beauté et cosmétiques',
    category: 'Beauté',
    image: 'https://images.unsplash.com/photo-1596462502278-27bfdc403348?auto=format&fit=crop&q=80&w=1000',
    values: [25, 50, 75, 100],
    discountPercent: 10
  },
  {
    id: 'darty',
    name: 'Darty',
    description: 'Électroménager et multimédia',
    category: 'High-Tech',
    image: 'https://images.unsplash.com/photo-1550009158-9ebf69173e03?auto=format&fit=crop&q=80&w=1000',
    values: [50, 100, 200, 500],
    discountPercent: 6
  },
  {
    id: 'zara',
    name: 'Zara',
    description: 'Mode et accessoires',
    category: 'Mode',
    image: 'https://images.unsplash.com/photo-1445205170230-053b83016050?auto=format&fit=crop&q=80&w=1000',
    values: [25, 50, 75, 100],
    discountPercent: 8
  }
];

function GiftCards() {
  const { currentUser } = useAuth();
  const [favorites, setFavorites] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFavorites = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, currentUser.uid));
        if (userDoc.exists()) {
          setFavorites(userDoc.data().favoriteGiftCards || []);
        }
      } catch (error) {
        console.error('Error loading favorites:', error);
      } finally {
        setLoading(false);
      }
    };

    loadFavorites();
  }, [currentUser]);

  const toggleFavorite = async (cardId: string) => {
    if (!currentUser) return;

    try {
      const userRef = doc(db, COLLECTIONS.USERS, currentUser.uid);
      if (favorites.includes(cardId)) {
        await updateDoc(userRef, {
          favoriteGiftCards: arrayRemove(cardId)
        });
        setFavorites(prev => prev.filter(id => id !== cardId));
      } else {
        await updateDoc(userRef, {
          favoriteGiftCards: arrayUnion(cardId)
        });
        setFavorites(prev => [...prev, cardId]);
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const filteredCards = GIFT_CARDS.filter(card => {
    if (showOnlyFavorites) {
      return favorites.includes(card.id);
    }
    if (selectedCategory) {
      return card.category === selectedCategory;
    }
    return true;
  });

  const categories = Array.from(new Set(GIFT_CARDS.map(card => card.category)));

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Cartes Cadeaux</h1>

        <div className="bg-gradient-to-r from-primary-50 to-accent-50 rounded-lg p-6 mb-8">
          <div className="flex items-start space-x-6">
            <div className="flex-1">
              <h2 className="text-lg font-semibold text-gray-900 mb-2">
                🎯 Votre avis compte énormément !
              </h2>
              <p className="text-gray-700 mb-4">
                Pour accéder aux cartes cadeaux, commencez par donner votre avis avec un petit 
                <span className="inline-flex items-center mx-1 text-green-600">
                  <Heart className="h-4 w-4 mr-1" /> like
                </span>
                !
              </p>
              <p className="text-gray-600 text-sm">
                Votre feedback nous aide à sélectionner les meilleures offres et à créer une expérience shopping 
                qui correspond vraiment à vos envies ! 🎉
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="flex items-center space-x-4">
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            >
              <option value="">Toutes les catégories</option>
              {categories.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>

            <button
              onClick={() => setShowOnlyFavorites(!showOnlyFavorites)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
                showOnlyFavorites
                  ? 'bg-red-500 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <BookmarkIcon className="h-5 w-5" />
              <span>Mes favoris{showOnlyFavorites ? ' uniquement' : ''}</span>
            </button>
          </div>
        </div>

        {showOnlyFavorites && favorites.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-xl shadow-lg">
            <BookmarkIcon className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">Aucune carte favorite</h3>
            <p className="text-gray-600">
              Ajoutez des cartes à vos favoris en cliquant sur le cœur
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredCards.map((card) => (
              <div
                key={card.id}
                className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
              >
                <div className="relative h-48">
                  <img
                    src={card.image}
                    alt={card.name}
                    className="w-full h-full object-cover"
                  />
                  <button
                    onClick={() => toggleFavorite(card.id)}
                    className={`absolute top-4 right-4 p-2 rounded-full ${
                      favorites.includes(card.id)
                        ? 'bg-red-500 text-white'
                        : 'bg-white text-gray-600'
                    } hover:scale-110 transition-transform duration-200`}
                  >
                    <Heart className={`h-5 w-5 ${
                      favorites.includes(card.id) ? 'fill-current' : ''
                    }`} />
                  </button>
                  {favorites.includes(card.id) && (
                    <div className="absolute top-4 left-4 bg-white px-3 py-1 rounded-full flex items-center space-x-1">
                      <Star className="h-4 w-4 text-yellow-500" />
                      <span className="text-sm font-medium">Favori</span>
                    </div>
                  )}
                </div>

                <div className="p-6 space-y-4">
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">{card.name}</h3>
                    <p className="text-gray-600 mt-1">{card.description}</p>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    {card.values.map((value) => (
                      <div
                        key={value}
                        className="bg-gray-50 p-3 rounded-lg text-center hover:bg-gray-100 transition-colors cursor-pointer"
                      >
                        <span className="font-semibold text-gray-900">{value}€</span>
                      </div>
                    ))}
                  </div>

                  <div className="flex items-center justify-between pt-4 border-t">
                    <div className="flex items-center space-x-2 text-green-600">
                      <Percent className="h-5 w-5" />
                      <span className="font-semibold">{card.discountPercent}% de remise</span>
                    </div>
                    <div className="flex items-center space-x-2 text-primary-600">
                      <Coins className="h-5 w-5" />
                      <span className="font-semibold">+{card.discountPercent * 100} SUNCOINS</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default GiftCards;