import React, { useEffect, useRef, useState } from 'react';
import { MapPin, AlertCircle, Info } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { GAME_CONFIG } from '../lib/gameConfig';
import { CoinManager } from '../lib/coinManager';
import GameHUD from '../components/GameHUD';
import CollectAnimation from '../components/CollectAnimation';
import { initMapsApi } from '../lib/maps';

const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

export default function SunQuest() {
  const { currentUser } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [coinManager, setCoinManager] = useState<CoinManager | null>(null);
  const [isLocating, setIsLocating] = useState(true);
  const [collectAnimations, setCollectAnimations] = useState<Array<{
    id: string;
    position: { x: number; y: number };
    value: number;
    isLegendary: boolean;
  }>>([]);
  const [dailyStats, setDailyStats] = useState(() => {
    const saved = localStorage.getItem('sunquest_stats');
    const defaultStats = {
      regular: 0,
      legendary: 0,
      rare: 0,
      lastRareDate: null
    };
    
    if (!saved) return defaultStats;
    
    try {
      const parsed = JSON.parse(saved);
      // Reset stats if it's a new day
      const lastDate = new Date(parsed.lastUpdate || 0);
      const today = new Date();
      if (lastDate.getDate() !== today.getDate()) {
        return defaultStats;
      }
      return parsed;
    } catch {
      return defaultStats;
    }
  });

  const mapRef = useRef<HTMLDivElement>(null);
  const watchId = useRef<number | null>(null);

  useEffect(() => {
    if (!currentUser) return;

    const initMap = async () => {
      if (!mapRef.current) return;

      try {
        const maps = await initMapsApi();
        const mapInstance = new maps.Map(mapRef.current, {
          zoom: 18,
          disableDefaultUI: true,
          styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }]
        });
        setMap(mapInstance);

        // Initialize CoinManager
        const manager = new CoinManager(mapInstance, handleCoinCollect);
        setCoinManager(manager);

        // Start watching position
        watchId.current = navigator.geolocation.watchPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            
            mapInstance.panTo(pos);
            manager.updatePlayerPosition(pos);
            setIsLocating(false);
          },
          (error) => {
            console.error('Geolocation error:', error);
            setError('Impossible d\'obtenir votre position');
            setIsLocating(false);
          },
          GEOLOCATION_OPTIONS
        );
      } catch (error) {
        console.error('Error initializing map:', error);
        setError('Erreur lors du chargement de la carte');
        setIsLocating(false);
      }
    };

    initMap();

    return () => {
      if (watchId.current) {
        navigator.geolocation.clearWatch(watchId.current);
      }
      if (coinManager) {
        coinManager.cleanup();
      }
    };
  }, [currentUser]);

  const handleCoinCollect = async (type: 'regular' | 'legendary' | 'rare', value: number, position: { x: number; y: number }) => {
    if (!currentUser) return;

    const currentCount = dailyStats[type];
    const limit = GAME_CONFIG.DAILY_LIMITS[type];

    if (currentCount >= limit) {
      setError(`Limite quotidienne de ${type} atteinte (${limit})`);
      return;
    }

    try {
      // Update Firestore
      await updateDoc(doc(db, COLLECTIONS.USERS, currentUser.uid), {
        fidecoins: increment(value)
      });

      // Update local stats
      const newStats = {
        ...dailyStats,
        [type]: currentCount + 1,
        lastUpdate: new Date().toISOString()
      };
      setDailyStats(newStats);
      localStorage.setItem('sunquest_stats', JSON.stringify(newStats));

      // Show animation
      const animationId = crypto.randomUUID();
      setCollectAnimations(prev => [...prev, {
        id: animationId,
        position,
        value,
        isLegendary: type !== 'regular'
      }]);

      setTimeout(() => {
        setCollectAnimations(prev => 
          prev.filter(anim => anim.id !== animationId)
        );
      }, 1000);
    } catch (error) {
      console.error('Error collecting coin:', error);
      setError('Erreur lors de la collecte');
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col">
      <GameHUD
        loading={isLocating}
        error={error}
        dailyStats={dailyStats}
      />

      <div className="flex-1 relative">
        <div ref={mapRef} className="absolute inset-0" />
        
        {isLocating && (
          <div className="absolute inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center">
            <div className="text-center space-y-4">
              <div className="animate-bounce">
                <MapPin className="h-12 w-12 text-primary-600 mx-auto" />
              </div>
              <p className="text-gray-600">Localisation en cours...</p>
            </div>
          </div>
        )}

        {/* Info message - Fixed position with semi-transparent background */}
        <div className="fixed bottom-20 md:bottom-8 left-1/2 transform -translate-x-1/2 w-[90%] max-w-md z-10">
          <div className="bg-white/90 backdrop-blur-sm p-4 rounded-lg shadow-lg border border-primary-100">
            <div className="flex items-start space-x-3">
              <Info className="h-5 w-5 text-primary-600 mt-0.5 flex-shrink-0" />
              <p className="text-sm text-primary-700">
                Rapprochez-vous à moins de {GAME_CONFIG.COLLECTION_RADIUS}m d'un SUNCOIN et cliquez dessus pour le collecter !
              </p>
            </div>
          </div>
        </div>
      </div>

      {collectAnimations.map(animation => (
        <CollectAnimation
          key={animation.id}
          position={animation.position}
          value={animation.value}
          isLegendary={animation.isLegendary}
        />
      ))}
    </div>
  );
}