import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import PaymentNotification from './PaymentNotification';

interface PaymentRequest {
  id: string;
  amount: number;
  suncoinsRequested: number;
  status: 'pending' | 'approved' | 'rejected';
}

export default function PaymentListener() {
  const { currentUser } = useAuth();
  const [pendingRequest, setPendingRequest] = useState<PaymentRequest | null>(null);

  useEffect(() => {
    if (!currentUser) return;

    // Listen for payment requests for this user
    const q = query(
      collection(db, COLLECTIONS.PAYMENT_REQUESTS),
      where('customerId', '==', currentUser.uid),
      where('status', '==', 'pending')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          setPendingRequest({
            id: change.doc.id,
            ...change.doc.data()
          } as PaymentRequest);
        }
        if (change.type === 'modified' && change.doc.data().status !== 'pending') {
          setPendingRequest(null);
        }
      });
    });

    return () => unsubscribe();
  }, [currentUser]);

  if (!pendingRequest) return null;

  return (
    <PaymentNotification
      requestId={pendingRequest.id}
      amount={pendingRequest.amount}
      suncoinsRequested={pendingRequest.suncoinsRequested}
      onClose={() => setPendingRequest(null)}
    />
  );
}