import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { UserCircle, Mail, Phone, Store, Loader, LogOut, Globe, Sparkles, Image as ImageIcon } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import AuthRedirect from '../components/AuthRedirect';
import { AddressAutocomplete } from '../components/AddressAutocomplete';
import { MERCHANT_CATEGORIES } from '../lib/constants';
import PremiumSubscriptionBanner from '../components/premium/PremiumSubscriptionBanner';
import SubscriptionDetails from '../components/premium/SubscriptionDetails';
import PremiumHistory from '../components/premium/PremiumHistory';
import PremiumRewards from '../components/premium/PremiumRewards';
import PremiumAchievements from '../components/premium/PremiumAchievements';

const profileSchema = z.object({
  name: z.string().min(2, 'Le nom doit contenir au moins 2 caractères'),
  email: z.string().email('Email invalide'),
  phone: z.string().optional(),
  address: z.string().optional(),
  siren: z.string().optional(),
  website: z.string().url('URL invalide').optional().or(z.literal('')),
  merchantInfo: z.object({
    category: z.string().optional(),
    description: z.string().max(500, 'La description ne doit pas dépasser 500 caractères').optional(),
  }).optional(),
});

type ProfileForm = z.infer<typeof profileSchema>;

function Profile() {
  const { currentUser, userData, updateUserProfile, logout } = useAuth();
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [location, setLocation] = useState<{ lat: number; lng: number } | undefined>(
    userData?.merchantInfo?.location
  );

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<ProfileForm>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      name: userData?.name || '',
      email: userData?.email || '',
      phone: userData?.phone || '',
      address: userData?.address || userData?.merchantInfo?.businessAddress || '',
      siren: userData?.merchantInfo?.siren || '',
      website: userData?.merchantInfo?.website || '',
      merchantInfo: {
        category: userData?.merchantInfo?.category || '',
        description: userData?.merchantInfo?.description || '',
      },
    }
  });

  if (!currentUser || !userData) {
    return <AuthRedirect />;
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const onSubmit = async (data: ProfileForm) => {
    try {
      setSaving(true);
      setError(null);

      const updateData: any = {
        ...data,
        address: data.address || null,
        phone: data.phone || null,
      };

      if (data.siren) {
        updateData.role = 'merchant';
        updateData.merchantInfo = {
          ...userData?.merchantInfo,
          siren: data.siren,
          businessAddress: data.address,
          location,
          website: data.website || null,
          companyName: userData?.merchantInfo?.companyName || data.name,
          category: data.merchantInfo?.category,
          description: data.merchantInfo?.description?.trim(),
        };
      }

      await updateUserProfile(updateData);
      setError('Profil mis à jour avec succès');
    } catch (err) {
      console.error('Erreur mise à jour profil:', err);
      setError('Erreur lors de la mise à jour du profil');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto space-y-8 pb-24 md:pb-0">
      <PremiumSubscriptionBanner />
      <SubscriptionDetails />
      <PremiumHistory />
      <PremiumRewards />
      <PremiumAchievements />

      <div className="bg-white rounded-xl shadow-lg p-8">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-8">
            <div className="relative">
              <div className="h-32 w-32 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
                {userData?.photoURL ? (
                  <img
                    src={userData.photoURL}
                    alt={userData.name}
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <UserCircle className="h-24 w-24 text-gray-400" />
                )}
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Mon Profil</h1>
              <p className="text-gray-600">Gérez vos informations personnelles</p>
            </div>
          </div>
        </div>

        {error && (
          <div className={`mb-6 p-3 rounded-md ${
            error.includes('succès') 
              ? 'bg-green-50 text-green-700'
              : 'bg-red-50 text-red-600'
          }`}>
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Nom complet</label>
              <input
                type="text"
                {...register('name')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                {...register('email')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Téléphone</label>
              <input
                type="tel"
                {...register('phone')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
              {errors.phone && (
                <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                SIREN (pour devenir marchand)
              </label>
              <input
                type="text"
                {...register('siren')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                placeholder="Entrez votre numéro SIREN pour devenir marchand"
              />
              {errors.siren && (
                <p className="mt-1 text-sm text-red-600">{errors.siren.message}</p>
              )}
            </div>

            {watch('siren') && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Catégorie</label>
                  <select
                    {...register('merchantInfo.category')}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  >
                    <option value="">Sélectionnez une catégorie</option>
                    {MERCHANT_CATEGORIES.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.label}
                      </option>
                    ))}
                  </select>
                  {errors.merchantInfo?.category && (
                    <p className="mt-1 text-sm text-red-600">{errors.merchantInfo.category.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Site web</label>
                  <input
                    type="url"
                    {...register('website')}
                    placeholder="https://www.example.com"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  />
                  {errors.website && (
                    <p className="mt-1 text-sm text-red-600">{errors.website.message}</p>
                  )}
                </div>

                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Description</label>
                  <textarea
                    {...register('merchantInfo.description')}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    placeholder="Décrivez votre activité..."
                  />
                  {errors.merchantInfo?.description && (
                    <p className="mt-1 text-sm text-red-600">{errors.merchantInfo.description.message}</p>
                  )}
                </div>
              </>
            )}

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Adresse</label>
              <AddressAutocomplete
                value={watch('address') || ''}
                onChange={(address, loc) => {
                  setValue('address', address);
                  setLocation(loc);
                }}
                error={errors.address?.message}
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={handleLogout}
              className="px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50"
            >
              <div className="flex items-center space-x-2">
                <LogOut className="h-4 w-4" />
                <span>Déconnexion</span>
              </div>
            </button>
            <button
              type="submit"
              disabled={saving}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50"
            >
              {saving ? (
                <div className="flex items-center space-x-2">
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Enregistrement...</span>
                </div>
              ) : (
                'Sauvegarder'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;