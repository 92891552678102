import React, { useState } from 'react';
import { Crown, Calendar, AlertCircle, Loader, X } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { cancelSubscription } from '../../lib/stripe/subscriptions';
import { useAuth } from '../../contexts/AuthContext';

export default function SubscriptionDetails() {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  if (!userData?.subscription) return null;

  const { status, currentPeriodEnd, cancelAtPeriodEnd } = userData.subscription;

  const handleCancelSubscription = async () => {
    if (!userData?.subscription?.id) return;

    try {
      setLoading(true);
      setError(null);
      await cancelSubscription(userData.subscription.id);
      setShowCancelConfirm(false);
    } catch (error: any) {
      console.error('Error canceling subscription:', error);
      setError(error.message || 'Error canceling subscription');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center space-x-3 mb-6">
        <Crown className="h-6 w-6 text-primary-600" />
        <h2 className="text-xl font-semibold text-gray-900">Abonnement Premium</h2>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className={`w-3 h-3 rounded-full ${
              status === 'active' ? 'bg-green-500' : 'bg-red-500'
            }`} />
            <span className="font-medium text-gray-900">
              {status === 'active' ? 'Actif' : 'Inactif'}
            </span>
          </div>
          <div className="flex items-center space-x-2 text-gray-600">
            <Calendar className="h-5 w-5" />
            <span>
              {currentPeriodEnd ? (
                <>
                  Prochain paiement le{' '}
                  {format(new Date(currentPeriodEnd), 'dd MMMM yyyy', { locale: fr })}
                </>
              ) : (
                'Date de fin non disponible'
              )}
            </span>
          </div>
        </div>

        {cancelAtPeriodEnd && (
          <div className="bg-yellow-50 p-4 rounded-lg">
            <p className="text-yellow-800">
              Votre abonnement sera annulé à la fin de la période en cours. 
              Vous pouvez continuer à profiter des avantages Premium jusqu'au{' '}
              {format(new Date(currentPeriodEnd), 'dd MMMM yyyy', { locale: fr })}.
            </p>
          </div>
        )}

        {error && (
          <div className="p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        {!cancelAtPeriodEnd && (
          <div className="flex justify-end">
            <button
              onClick={() => setShowCancelConfirm(true)}
              className="text-red-600 hover:text-red-700"
            >
              Annuler l'abonnement
            </button>
          </div>
        )}
      </div>

      {showCancelConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl shadow-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Confirmer l'annulation
              </h3>
              <button
                onClick={() => setShowCancelConfirm(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <p className="text-gray-600 mb-6">
              Êtes-vous sûr de vouloir annuler votre abonnement Premium ? 
              Vous perdrez l'accès à tous les avantages Premium à la fin de 
              votre période de facturation actuelle.
            </p>

            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCancelConfirm(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Retour
              </button>
              <button
                onClick={handleCancelSubscription}
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 disabled:opacity-50"
              >
                {loading ? (
                  <div className="flex items-center space-x-2">
                    <Loader className="h-4 w-4 animate-spin" />
                    <span>Annulation...</span>
                  </div>
                ) : (
                  'Confirmer l\'annulation'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}