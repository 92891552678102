import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { AdminProvider } from './contexts/AdminContext';
import Navigation from './components/Navigation';
import { EmailVerificationBanner } from './components/EmailVerificationBanner';
import { NotificationPrompt } from './components/NotificationPrompt';
import { FrozenAccountBanner } from './components/FrozenAccountBanner';
import PaymentListener from './components/PaymentListener';
import SunnyChat from './components/Sunny/SunnyChat';

// Pages
import Landing from './pages/Landing';
import Merchants from './pages/Merchants';
import PhysicalMerchants from './pages/PhysicalMerchants';
import GiftCards from './pages/GiftCards';
import Coupons from './pages/Coupons';
import Quests from './pages/Quests';
import SunQuest from './pages/SunQuest';
import Referral from './pages/Referral';
import Wallet from './pages/Wallet';
import Profile from './pages/Profile';
import MerchantDashboard from './pages/MerchantDashboard';
import Management from './pages/Management';
import Login from './pages/Login';
import Register from './pages/Register';

function App() {
  return (
    <AuthProvider>
      <AdminProvider>
        <BrowserRouter>
          <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-100">
            <Navigation />
            <EmailVerificationBanner />
            <FrozenAccountBanner />
            <NotificationPrompt />
            <PaymentListener />
            
            <main className="container mx-auto px-4 py-8 mt-16">
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/merchants" element={<Merchants />} />
                <Route path="/physical-merchants" element={<PhysicalMerchants />} />
                <Route path="/gift-cards" element={<GiftCards />} />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/quests" element={<Quests />} />
                <Route path="/sunquest" element={<SunQuest />} />
                <Route path="/referral" element={<Referral />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/merchant-dashboard" element={<MerchantDashboard />} />
                <Route path="/management" element={<Management />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
              </Routes>
            </main>
            
            <SunnyChat />
          </div>
        </BrowserRouter>
      </AdminProvider>
    </AuthProvider>
  );
}

export default App;