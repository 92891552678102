export class PaymentError extends Error {
  constructor(
    message: string,
    public code: string,
    public declineCode?: string
  ) {
    super(message);
    this.name = 'PaymentError';
  }
}

export const getPaymentErrorMessage = (error: any): string => {
  if (error instanceof PaymentError) {
    switch (error.code) {
      case 'card_declined':
        return 'La carte a été refusée. Veuillez réessayer avec une autre carte.';
      case 'expired_card':
        return 'La carte est expirée.';
      case 'incorrect_cvc':
        return 'Le code de sécurité est incorrect.';
      case 'processing_error':
        return 'Une erreur est survenue lors du traitement du paiement. Veuillez réessayer.';
      case 'insufficient_funds':
        return 'Fonds insuffisants sur la carte.';
      default:
        return error.message;
    }
  }
  return 'Une erreur est survenue lors du paiement.';
};