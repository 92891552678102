import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export function FrozenAccountBanner() {
  const { userData } = useAuth();

  if (!userData?.frozen) return null;

  return (
    <div className="bg-red-50 border-l-4 border-red-400 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <AlertTriangle className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-red-700">
            Votre compte est actuellement gelé. Vous ne pouvez pas effectuer de transactions, 
            participer aux quêtes ou utiliser vos SUNCOINS. Veuillez contacter le support 
            pour plus d'informations.
          </p>
          <p className="mt-2 text-sm text-red-700">
            <a 
              href="mailto:support@sunkdo.com" 
              className="font-medium underline hover:text-red-600"
            >
              Contacter le support
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}