import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Plus, BarChart2, ThumbsUp, ThumbsDown, Users, TrendingUp, Target, QrCode } from 'lucide-react';
import CouponForm from '../components/CouponForm';
import CouponScanner from '../components/CouponScanner';
import { validateCoupon } from '../lib/coupons';

interface CouponStats {
  id: string;
  title: string;
  description: string;
  usageCount: number;
  likeCount: number;
  dislikeCount: number;
  likedBy: UserInteraction[];
  dislikedBy: UserInteraction[];
  viewCount: number;
  engagementRate: number;
  conversionRate: number;
  category: string;
  discount: {
    type: 'percentage' | 'fixed';
    value: number;
  };
}

interface UserInteraction {
  userId: string;
  name: string;
  timestamp: Date;
}

export default function MerchantCoupons() {
  const { currentUser, userData } = useAuth();
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [coupons, setCoupons] = useState<CouponStats[]>([]);
  const [scanSuccess, setScanSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (!currentUser) return;

    const fetchCoupons = async () => {
      try {
        setLoading(true);
        setError(null);

        const couponsRef = collection(db, COLLECTIONS.COUPONS);
        const q = query(
          couponsRef,
          where('merchantId', '==', currentUser.uid)
        );
        
        const snapshot = await getDocs(q);
        const fetchedCoupons = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          
          const likedByDetails = await Promise.all((data.likedBy || []).map(async (userId: string) => {
            const userRef = doc(db, COLLECTIONS.USERS, userId);
            const userDoc = await getDoc(userRef);
            return {
              userId,
              name: userDoc.exists() ? userDoc.data()?.name : 'Utilisateur anonyme',
              timestamp: data.likeTimestamps?.[userId] ? new Date(data.likeTimestamps[userId].toDate()) : new Date()
            };
          }));

          const dislikedByDetails = await Promise.all((data.dislikedBy || []).map(async (userId: string) => {
            const userRef = doc(db, COLLECTIONS.USERS, userId);
            const userDoc = await getDoc(userRef);
            return {
              userId,
              name: userDoc.exists() ? userDoc.data()?.name : 'Utilisateur anonyme',
              timestamp: data.dislikeTimestamps?.[userId] ? new Date(data.dislikeTimestamps[userId].toDate()) : new Date()
            };
          }));

          const viewCount = data.viewCount || 0;
          const likeCount = data.likeCount || 0;
          const dislikeCount = data.dislikeCount || 0;
          const totalInteractions = likeCount + dislikeCount;

          return {
            id: docSnapshot.id,
            title: data.title,
            description: data.description,
            usageCount: data.usageCount || 0,
            likeCount,
            dislikeCount,
            likedBy: likedByDetails,
            dislikedBy: dislikedByDetails,
            viewCount,
            engagementRate: viewCount > 0 ? (totalInteractions / viewCount) * 100 : 0,
            conversionRate: viewCount > 0 ? (data.usageCount || 0) / viewCount * 100 : 0,
            category: data.category,
            discount: data.discount
          };
        }));

        setCoupons(fetchedCoupons);
      } catch (error) {
        console.error('Error fetching coupons:', error);
        setError('Une erreur est survenue lors du chargement des coupons');
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [currentUser]);

  const handleScanSuccess = async (result: { code: string; userId: string }) => {
    if (!currentUser) return;

    try {
      setLoading(true);
      const validationResult = await validateCoupon(currentUser.uid, result.code, result.userId);

      if (validationResult.success && validationResult.discount) {
        setScanSuccess(`${validationResult.message} ${
          validationResult.discount.type === 'percentage'
            ? `${validationResult.discount.value}% de réduction`
            : `${validationResult.discount.value}€ de réduction`
        }`);
        setShowScanner(false);
      } else {
        setError(validationResult.message);
      }
    } catch (error: any) {
      console.error('Error validating coupon:', error);
      setError(error.message || 'Erreur lors de la validation du coupon');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Mes coupons</h1>
          <div className="flex gap-4">
            <button
              onClick={() => setShowScanner(true)}
              className="flex items-center space-x-2 bg-accent-400 text-white px-4 py-2 rounded-lg hover:bg-accent-500"
            >
              <QrCode className="h-5 w-5" />
              <span>Scanner un coupon</span>
            </button>
            <button
              onClick={() => setShowCouponForm(true)}
              className="flex items-center space-x-2 bg-primary-600 text-white px-4 py-2 rounded-lg hover:bg-primary-700"
            >
              <Plus className="h-5 w-5" />
              <span>Créer un coupon</span>
            </button>
          </div>
        </div>

        {scanSuccess && (
          <div className="mb-6 p-4 bg-green-50 text-green-700 rounded-lg">
            {scanSuccess}
          </div>
        )}

        {error && (
          <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600 mx-auto"></div>
          </div>
        ) : coupons.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-600">Vous n'avez pas encore créé de coupons</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {coupons.map((coupon) => (
              <div
                key={coupon.id}
                className="bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow"
              >
                <h3 className="font-semibold text-gray-900 mb-4">{coupon.title}</h3>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <Users className="h-5 w-5 text-blue-500" />
                      <span className="text-gray-600">Vues</span>
                    </div>
                    <span className="font-medium">{coupon.viewCount}</span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <ThumbsUp className="h-5 w-5 text-green-500" />
                      <span className="text-gray-600">Likes</span>
                    </div>
                    <span className="font-medium text-green-600">{coupon.likeCount}</span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <ThumbsDown className="h-5 w-5 text-red-500" />
                      <span className="text-gray-600">Dislikes</span>
                    </div>
                    <span className="font-medium text-red-600">{coupon.dislikeCount}</span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <TrendingUp className="h-5 w-5 text-purple-500" />
                      <span className="text-gray-600">Engagement</span>
                    </div>
                    <span className="font-medium text-purple-600">
                      {coupon.engagementRate.toFixed(1)}%
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <Target className="h-5 w-5 text-primary-500" />
                      <span className="text-gray-600">Conversion</span>
                    </div>
                    <span className="font-medium text-primary-600">
                      {coupon.conversionRate.toFixed(1)}%
                    </span>
                  </div>
                </div>

                <button
                  className="mt-4 w-full flex items-center justify-center space-x-2 text-primary-600 hover:bg-primary-50 py-2 rounded-md"
                >
                  <BarChart2 className="h-4 w-4" />
                  <span>Voir les détails</span>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {showCouponForm && (
        <CouponForm
          onSubmit={async (data) => {
            try {
              setLoading(true);
              await addDoc(collection(db, COLLECTIONS.COUPONS), {
                ...data,
                merchantId: currentUser.uid,
                merchantName: userData?.merchantInfo?.companyName,
                active: true,
                createdAt: Timestamp.now(),
                code: crypto.randomUUID(),
                likeCount: 0,
                dislikeCount: 0,
                viewCount: 0,
                usageCount: 0,
                usedBy: []
              });
              setShowCouponForm(false);
            } catch (err) {
              console.error('Error creating coupon:', err);
              setError('Error creating coupon');
            } finally {
              setLoading(false);
            }
          }}
          onClose={() => setShowCouponForm(false)}
          loading={loading}
        />
      )}

      {showScanner && currentUser && (
        <CouponScanner
          merchantId={currentUser.uid}
          onSuccess={handleScanSuccess}
          onClose={() => setShowScanner(false)}
        />
      )}
    </div>
  );
}