import { collection, query, where, getDocs, doc, getDoc, updateDoc, increment, Timestamp } from 'firebase/firestore';
import { db, COLLECTIONS } from './firebase';

interface CouponValidationResult {
  success: boolean;
  message: string;
  discount?: {
    type: 'percentage' | 'fixed';
    value: number;
  };
}

interface CouponData {
  id: string;
  active: boolean;
  code: string;
  merchantId: string;
  merchantName: string;
  title: string;
  description: string;
  expiresAt: string | Timestamp;
  discountType: 'percentage' | 'fixed';
  discountValue: number;
  usedBy: string[];
  usageCount: number;
  maxUses?: number;
  category: string;
  terms?: string;
  likeCount: number;
  dislikeCount: number;
  viewCount: number;
  likedBy: string[];
  createdAt: Timestamp;
}

export const validateCoupon = async (
  merchantId: string,
  code: string,
  userId: string
): Promise<CouponValidationResult> => {
  try {
    // Basic input validation
    if (!merchantId || !code || !userId) {
      return {
        success: false,
        message: 'Données de validation manquantes'
      };
    }

    // Find the coupon first
    const couponsRef = collection(db, COLLECTIONS.COUPONS);
    const q = query(
      couponsRef,
      where('code', '==', code),
      where('merchantId', '==', merchantId)
    );
    
    const couponSnapshot = await getDocs(q);
    
    if (couponSnapshot.empty) {
      return {
        success: false,
        message: 'Coupon non trouvé'
      };
    }

    const couponDoc = couponSnapshot.docs[0];
    const couponData = couponDoc.data() as CouponData;

    // Verify coupon structure
    if (!couponData.discountType || !couponData.discountValue || 
        !['percentage', 'fixed'].includes(couponData.discountType) || 
        typeof couponData.discountValue !== 'number') {
      console.error('Invalid coupon structure:', couponData);
      return {
        success: false,
        message: 'Format du coupon invalide'
      };
    }

    // Verify coupon is active
    if (!couponData.active) {
      return {
        success: false,
        message: 'Ce coupon n\'est plus actif'
      };
    }

    // Verify expiration
    const expiryDate = couponData.expiresAt instanceof Timestamp 
      ? couponData.expiresAt.toDate() 
      : new Date(couponData.expiresAt);

    if (expiryDate < new Date()) {
      return {
        success: false,
        message: 'Ce coupon a expiré'
      };
    }

    // Verify user exists
    const userRef = doc(db, COLLECTIONS.USERS, userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return {
        success: false,
        message: 'Utilisateur non trouvé'
      };
    }

    // Check if user has already used this coupon
    if (couponData.usedBy?.includes(userId)) {
      return {
        success: false,
        message: 'Vous avez déjà utilisé ce coupon'
      };
    }

    // Check maximum usage limit if set
    if (couponData.maxUses && couponData.usageCount >= couponData.maxUses) {
      return {
        success: false,
        message: 'Ce coupon a atteint sa limite d\'utilisation'
      };
    }

    // Update coupon usage
    await updateDoc(doc(db, COLLECTIONS.COUPONS, couponDoc.id), {
      usageCount: increment(1),
      usedBy: [...(couponData.usedBy || []), userId],
      lastUsedAt: Timestamp.now()
    });

    return {
      success: true,
      message: 'Coupon validé avec succès !',
      discount: {
        type: couponData.discountType,
        value: couponData.discountValue
      }
    };
  } catch (error: any) {
    console.error('Error validating coupon:', error);
    return {
      success: false,
      message: error.message || 'Une erreur est survenue lors de la validation'
    };
  }
};