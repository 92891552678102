import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Store, Gift, Coins, Bell, Loader, AlertCircle, QrCode, Settings } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { CouponFormData } from '../lib/coupons/validation';
import CouponForm from '../components/CouponForm';
import NotificationForm from '../components/NotificationForm';
import QuestForm from '../components/QuestForm';
import PaymentScanner from '../components/PaymentScanner';
import PaymentConfirmation from '../components/PaymentConfirmation';
import DiscountSettings from '../components/DiscountSettings';
import MerchantCoupons from './MerchantCoupons';

export default function MerchantDashboard() {
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();
  const [showQuestForm, setShowQuestForm] = useState(false);
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [showNotificationForm, setShowNotificationForm] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<{ userId: string; suncoins: number } | null>(null);
  const [showDiscountSettings, setShowDiscountSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [scanSuccess, setScanSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (!currentUser || !userData) {
      navigate('/login');
      return;
    }

    if (userData.role !== 'merchant') {
      navigate('/');
      return;
    }
  }, [currentUser, userData, navigate]);

  const handleCreateCoupon = async (formData: CouponFormData) => {
    if (!currentUser || !userData?.merchantInfo?.companyName) {
      setError('Informations du marchand manquantes');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const couponData = {
        merchantId: currentUser.uid,
        merchantName: userData.merchantInfo.companyName,
        title: formData.title,
        description: formData.description,
        category: formData.category,
        discount: {
          type: formData.discountType,
          value: formData.discountValue
        },
        maxUses: formData.maxUses,
        expiresAt: Timestamp.fromDate(new Date(formData.expiresAt)),
        terms: formData.terms,
        code: crypto.randomUUID(),
        active: true,
        createdAt: Timestamp.now(),
        usageCount: 0,
        usedBy: [],
        likeCount: 0,
        dislikeCount: 0,
        viewCount: 0,
        likedBy: [],
        dislikedBy: []
      };

      await addDoc(collection(db, COLLECTIONS.COUPONS), couponData);
      setShowCouponForm(false);
    } catch (err) {
      console.error('Error creating coupon:', err);
      setError('Erreur lors de la création du coupon');
    } finally {
      setLoading(false);
    }
  };

  const handleScanSuccess = (data: { userId: string; suncoins: number }) => {
    setSelectedCustomer(data);
    setShowScanner(false);
    setShowPaymentConfirmation(true);
  };

  if (!currentUser || !userData) {
    return null;
  }

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Store className="h-8 w-8 text-primary-600" />
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {userData?.merchantInfo?.companyName || 'Mon Commerce'}
                </h2>
                <p className="text-sm text-gray-600">{userData?.merchantInfo?.businessAddress}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center space-x-4">
            <Coins className="h-8 w-8 text-primary-600" />
            <div>
              <p className="text-sm text-gray-600">SUNCOINS disponibles</p>
              <p className="text-2xl font-bold text-primary-600">{userData?.fidecoins || 0}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center space-x-4">
            <Store className="h-8 w-8 text-gray-400" />
            <div>
              <p className="text-sm text-gray-600">Module PrestaShop</p>
              <p className="text-gray-400 italic">Arrive bientôt</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-900">Actions rapides</h3>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <button
            onClick={() => setShowQuestForm(true)}
            className="flex items-center justify-center space-x-2 bg-primary-600 text-white py-3 px-4 rounded-md hover:bg-primary-700 transition-colors duration-300"
          >
            <Gift className="h-5 w-5" />
            <span>Créer une quête</span>
          </button>

          <button
            onClick={() => setShowCouponForm(true)}
            className="flex items-center justify-center space-x-2 bg-yellow-600 text-white py-3 px-4 rounded-md hover:bg-yellow-700 transition-colors duration-300"
          >
            <Gift className="h-5 w-5" />
            <span>Créer un coupon</span>
          </button>

          <button
            onClick={() => setShowScanner(true)}
            className="flex items-center justify-center space-x-2 bg-green-600 text-white py-3 px-4 rounded-md hover:bg-green-700 transition-colors duration-300"
          >
            <QrCode className="h-5 w-5" />
            <span>Scanner un paiement</span>
          </button>

          <button
            onClick={() => setShowNotificationForm(true)}
            className="flex items-center justify-center space-x-2 bg-accent-400 text-white py-3 px-4 rounded-md hover:bg-accent-500 transition-colors duration-300"
          >
            <Bell className="h-5 w-5" />
            <span>Envoyer une notification</span>
          </button>
        </div>
      </div>

      {/* Paramètres de remise */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <DiscountSettings />
      </div>

      {/* Liste des coupons */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <MerchantCoupons />
      </div>

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <span>{error}</span>
        </div>
      )}

      {scanSuccess && (
        <div className="bg-green-50 text-green-600 p-4 rounded-lg flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <span>{scanSuccess}</span>
        </div>
      )}

      {showQuestForm && (
        <QuestForm
          onSubmit={async (data) => {
            try {
              setLoading(true);
              await addDoc(collection(db, COLLECTIONS.QUESTS), {
                ...data,
                merchantId: currentUser.uid,
                merchantName: userData?.merchantInfo?.companyName,
                location: userData?.merchantInfo?.location,
                active: true,
                createdAt: new Date(),
                validationCount: 0,
                validatedBy: []
              });
              setShowQuestForm(false);
            } catch (err) {
              console.error('Error creating quest:', err);
              setError('Error creating quest');
            } finally {
              setLoading(false);
            }
          }}
          onClose={() => setShowQuestForm(false)}
          loading={loading}
        />
      )}

      {showCouponForm && (
        <CouponForm
          onSubmit={handleCreateCoupon}
          onClose={() => setShowCouponForm(false)}
          loading={loading}
        />
      )}

      {showScanner && (
        <PaymentScanner
          merchantId={currentUser.uid}
          onScan={handleScanSuccess}
          onClose={() => setShowScanner(false)}
          maxFidecoinsPercent={userData?.merchantInfo?.discountPercent || 20}
        />
      )}

      {showPaymentConfirmation && selectedCustomer && (
        <PaymentConfirmation
          customerData={selectedCustomer}
          maxFidecoinsPercent={userData?.merchantInfo?.discountPercent || 20}
          onConfirm={async (amount, suncoinsUsed) => {
            try {
              setLoading(true);
              // Add payment record
              await addDoc(collection(db, COLLECTIONS.PAYMENTS), {
                merchantId: currentUser.uid,
                merchantName: userData.merchantInfo?.companyName,
                userId: selectedCustomer.userId,
                amount,
                suncoinsUsed,
                timestamp: Timestamp.now()
              });
              setScanSuccess('Paiement effectué avec succès !');
              setShowPaymentConfirmation(false);
            } catch (err) {
              console.error('Error processing payment:', err);
              setError('Erreur lors du traitement du paiement');
            } finally {
              setLoading(false);
            }
          }}
          onCancel={() => setShowPaymentConfirmation(false)}
        />
      )}

      {showNotificationForm && (
        <NotificationForm
          onSubmit={async (data) => {
            try {
              setLoading(true);
              await addDoc(collection(db, COLLECTIONS.NOTIFICATIONS), {
                ...data,
                merchantId: currentUser.uid,
                sentAt: new Date()
              });
              setShowNotificationForm(false);
            } catch (err) {
              console.error('Error sending notification:', err);
              setError('Error sending notification');
            } finally {
              setLoading(false);
            }
          }}
          onClose={() => setShowNotificationForm(false)}
          loading={loading}
        />
      )}
    </div>
  );
}