import React, { useState } from 'react';
import { Coins, CreditCard, AlertCircle, Loader } from 'lucide-react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { validateSuncoinsAmount } from '../lib/payments/validation';

interface PaymentConfirmationProps {
  customerData: {
    userId: string;
    suncoins: number;
  };
  maxFidecoinsPercent: number;
  onConfirm: (amount: number, suncoinsUsed: number) => Promise<void>;
  onCancel: () => void;
}

export default function PaymentConfirmation({
  customerData,
  maxFidecoinsPercent,
  onConfirm,
  onCancel
}: PaymentConfirmationProps) {
  const [amount, setAmount] = useState<string>('');
  const [suncoinsToUse, setSuncoinsToUse] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const parsedAmount = parseFloat(amount);
  const parsedSuncoins = parseInt(suncoinsToUse, 10) || 0;
  const maxSuncoinsPayment = parsedAmount ? Math.floor(parsedAmount * maxFidecoinsPercent / 100) : 0;

  const handleConfirm = async () => {
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      setError('Veuillez entrer un montant valide');
      return;
    }

    const validation = validateSuncoinsAmount(
      parsedAmount,
      parsedSuncoins,
      maxFidecoinsPercent,
      customerData.suncoins
    );

    if (!validation.valid) {
      setError(validation.error);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Create payment request
      await addDoc(collection(db, COLLECTIONS.PAYMENT_REQUESTS), {
        customerId: customerData.userId,
        amount: parsedAmount,
        suncoinsRequested: parsedSuncoins,
        status: 'pending',
        createdAt: Timestamp.now()
      });

      await onConfirm(parsedAmount, parsedSuncoins);
    } catch (err) {
      console.error('Error processing payment:', err);
      setError('Une erreur est survenue lors du traitement du paiement');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md">
        <h3 className="text-xl font-semibold text-gray-900 mb-6">Confirmer le paiement</h3>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Montant total (€)
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <CreditCard className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                placeholder="0.00"
                step="0.01"
                min="0"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              SUNCOINS à utiliser
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Coins className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="number"
                value={suncoinsToUse}
                onChange={(e) => setSuncoinsToUse(e.target.value)}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                placeholder="0"
                min="0"
                max={Math.min(maxSuncoinsPayment || 0, customerData.suncoins)}
              />
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg space-y-3">
            <div className="flex items-center justify-between">
              <span className="text-gray-600">SUNCOINS disponibles:</span>
              <span className="font-medium text-primary-600">
                {customerData.suncoins}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">Maximum utilisable:</span>
              <span className="font-medium text-primary-600">{maxSuncoinsPayment} SUNCOINS</span>
            </div>
          </div>

          {error && (
            <div className="p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
              <AlertCircle className="h-5 w-5" />
              <span>{error}</span>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              onClick={onCancel}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              onClick={handleConfirm}
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50 flex items-center space-x-2"
            >
              {loading ? (
                <>
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Traitement...</span>
                </>
              ) : (
                'Confirmer'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}