export const SUBSCRIPTION_PLANS = {
  PREMIUM: {
    id: 'price_premium_monthly',
    name: 'Premium',
    amount: 299, // 2.99€ in cents
    interval: 'month',
    currency: 'eur',
    features: [
      'Quêtes exclusives Premium',
      'Double SUNCOINS sur les quêtes',
      'Badge Premium',
      'Support prioritaire',
      'Notifications instantanées'
    ]
  },
  MERCHANT: {
    id: 'price_merchant_monthly',
    name: 'Marchand',
    amount: 1990, // 19.90€ in cents
    interval: 'month',
    currency: 'eur',
    features: [
      'Création de quêtes illimitées',
      'Dashboard marchand',
      'Analytics avancés',
      'Support dédié',
      'API PrestaShop'
    ]
  }
} as const;

export const PREMIUM_BENEFITS = {
  QUEST_MULTIPLIER: 2,
  DAILY_BONUS: 50,
  REFERRAL_BONUS: 25
} as const;