import { doc, getDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import { db, COLLECTIONS } from './firebase';

export const validateQuest = async (
  questId: string, 
  userId: string,
  currentFidecoins: number,
  reward: number
): Promise<void> => {
  try {
    // Get quest data first to validate
    const questRef = doc(db, COLLECTIONS.QUESTS, questId);
    const questDoc = await getDoc(questRef);
    
    if (!questDoc.exists()) {
      throw new Error('Quête introuvable');
    }

    const questData = questDoc.data();

    // Validate quest
    if (!questData.active) {
      throw new Error('Cette quête n\'est plus active');
    }

    if (questData.validatedBy?.includes(userId)) {
      throw new Error('Vous avez déjà validé cette quête');
    }

    if (new Date(questData.deadline) < new Date()) {
      throw new Error('Cette quête a expiré');
    }

    // Update quest with new validation
    await updateDoc(questRef, {
      validatedBy: arrayUnion(userId),
      validationCount: increment(1)
    });

    // Update user's fidecoins
    const userRef = doc(db, COLLECTIONS.USERS, userId);
    await updateDoc(userRef, {
      fidecoins: increment(reward)
    });
  } catch (error: any) {
    console.error('Error validating quest:', error);
    throw new Error(error.message || 'Erreur lors de la validation de la quête');
  }
};