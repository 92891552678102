import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Coins, Gift, Sparkles, Info } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { AuthOverlay } from '../components/AuthOverlay';

// Taux de conversion : 1 SUNCOIN = 0.01€
const SUNCOIN_TO_EUR_RATE = 0.01;

function Wallet() {
  const { currentUser, userData } = useAuth();

  const walletInfo = userData ? {
    userId: userData.id,
    name: userData.name,
    totalSavings: (userData.suncoinsSpent || 0) * SUNCOIN_TO_EUR_RATE,
    suncoins: userData.fidecoins || 0,
    suncoinsSpent: userData.suncoinsSpent || 0
  } : null;

  const qrData = walletInfo ? JSON.stringify({
    userId: walletInfo.userId,
    suncoins: walletInfo.suncoins,
    timestamp: new Date().toISOString()
  }) : '';

  return (
    <div className="max-w-2xl mx-auto px-4 space-y-6 pb-24 md:pb-8">
      <div className="bg-gradient-to-r from-primary-50 via-accent-50 to-primary-50 rounded-xl p-4 md:p-6 shadow-md">
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="hidden md:block">
            <div className="w-16 h-16 bg-white rounded-full shadow-lg flex items-center justify-center">
              <Sparkles className="h-8 w-8 text-primary-600" />
            </div>
          </div>
          <div className="flex-1 text-center md:text-left">
            <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-2">
              🎮 Votre Wallet SUNCOINS
            </h2>
            <p className="text-sm md:text-base text-gray-700">
              Bienvenue dans votre wallet virtuel ! C'est ici que vous gérez vos SUNCOINS, 
              la monnaie magique qui transforme votre shopping en aventure ! 🌟
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-6">
          <div className="bg-gradient-to-br from-primary-50 to-accent-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-900 mb-2">Comment ça marche ? 🤔</h3>
            <ul className="space-y-2 text-sm md:text-base text-gray-700">
              <li className="flex items-start gap-2">
                <span>🚶</span>
                <span>Gagnez des SUNCOINS en marchant</span>
              </li>
              <li className="flex items-start gap-2">
                <span>🎯</span>
                <span>Complétez des quêtes pour des bonus</span>
              </li>
              <li className="flex items-start gap-2">
                <span>💰</span>
                <span>Utilisez vos SUNCOINS pour des réductions</span>
              </li>
              <li className="flex items-start gap-2">
                <span>🤝</span>
                <span>Parrainez vos amis pour plus de gains</span>
              </li>
            </ul>
          </div>

          <div className="bg-gradient-to-br from-green-50 to-emerald-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-900 mb-2">Le saviez-vous ? 💡</h3>
            <ul className="space-y-2 text-sm md:text-base text-gray-700">
              <li className="flex items-start gap-2">
                <span>💎</span>
                <span>1 SUNCOIN = 0.01€ de réduction</span>
              </li>
              <li className="flex items-start gap-2">
                <span>🎁</span>
                <span>Jusqu'à 20% de réduction par achat</span>
              </li>
              <li className="flex items-start gap-2">
                <span>🌟</span>
                <span>Débloquez des récompenses exclusives</span>
              </li>
              <li className="flex items-start gap-2">
                <span>🏆</span>
                <span>Grimpez dans le classement</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-0">
          <div className="text-center md:text-left">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-900">{walletInfo?.name || 'Mon Wallet'}</h2>
            {walletInfo?.userId && <p className="text-sm text-gray-600">ID: {walletInfo.userId}</p>}
          </div>
          <div className="text-center md:text-right">
            <p className="text-sm text-gray-600">Total des remises</p>
            <p className="text-2xl md:text-3xl font-bold text-primary-600">{walletInfo?.totalSavings.toFixed(2)}€</p>
            <p className="text-xs text-gray-500">
              ({walletInfo?.suncoinsSpent || 0} SUNCOINS utilisés)
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-4 bg-primary-50 p-4 rounded-lg">
          <Coins className="h-6 w-6 text-primary-600" />
          <div className="text-center md:text-left">
            <p className="text-sm text-gray-600">Vos SUNCOINS</p>
            <p className="text-xl font-semibold text-primary-600">{walletInfo?.suncoins || 0}</p>
            <p className="text-xs text-gray-500">
              Valeur estimée : {((walletInfo?.suncoins || 0) * SUNCOIN_TO_EUR_RATE).toFixed(2)}€
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center space-y-4 p-4 md:p-6 bg-gray-50 rounded-lg">
          <p className="text-sm md:text-base text-gray-600 text-center">
            Présentez ce QR code lors de vos achats
          </p>
          <div className="bg-white p-4 rounded-lg shadow-inner w-full max-w-[250px] md:max-w-[300px] mx-auto">
            <QRCodeSVG
              value={qrData}
              size={200}
              level="H"
              includeMargin={true}
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      {!currentUser && <AuthOverlay />}
    </div>
  );
}

export default Wallet;