import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { Loader, Camera, AlertCircle, X } from 'lucide-react';

interface PaymentScannerProps {
  merchantId: string;
  onScan: (data: { userId: string; suncoins: number }) => void;
  onClose: () => void;
  maxFidecoinsPercent: number;
}

export default function PaymentScanner({ onScan, onClose, maxFidecoinsPercent }: PaymentScannerProps) {
  const [error, setError] = useState<string | null>(null);
  const [scanning, setScanning] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const html5QrCode = useRef<Html5Qrcode | null>(null);

  useEffect(() => {
    let mounted = true;

    const initializeScanner = async () => {
      try {
        const devices = await Html5Qrcode.getCameras();
        if (devices && devices.length > 0) {
          html5QrCode.current = new Html5Qrcode("qr-reader");
          if (mounted) {
            startScanning();
          }
        } else {
          setCameraError(true);
          setError('Aucune caméra détectée');
        }
      } catch (err) {
        console.error('Erreur d\'initialisation de la caméra:', err);
        setCameraError(true);
        setError('Impossible d\'accéder à la caméra');
      }
    };

    initializeScanner();

    return () => {
      mounted = false;
      stopScanning();
    };
  }, []);

  const startScanning = async () => {
    if (!html5QrCode.current) return;

    try {
      setScanning(true);
      setError(null);

      await html5QrCode.current.start(
        { facingMode: "environment" },
        {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          aspectRatio: 1.0,
        },
        async (decodedText) => {
          try {
            const data = JSON.parse(decodedText);
            
            // Validate QR code data
            if (!data.userId || typeof data.suncoins !== 'number') {
              throw new Error('QR code invalide. Veuillez scanner un QR code Sunkdo valide.');
            }

            // Validate timestamp is not too old (24h max)
            const scanTimestamp = new Date(data.timestamp);
            const now = new Date();
            const timeDiff = now.getTime() - scanTimestamp.getTime();
            const hoursDiff = timeDiff / (1000 * 60 * 60);

            if (hoursDiff > 24) {
              throw new Error('Ce QR code a expiré. Veuillez demander au client de générer un nouveau code.');
            }

            onScan(data);
          } catch (err: any) {
            setError(err.message || 'QR code invalide');
          }
        },
        (errorMessage) => {
          // Ignore non-critical scanning errors
          if (!errorMessage.includes('No MultiFormat Readers')) {
            console.debug('Scanner status:', errorMessage);
          }
        }
      );
    } catch (err) {
      console.error('Erreur du scanner:', err);
      setError('Erreur lors de l\'initialisation du scanner');
      setScanning(false);
    }
  };

  const stopScanning = async () => {
    if (html5QrCode.current?.isScanning) {
      try {
        await html5QrCode.current.stop();
        await html5QrCode.current.clear();
      } catch (err) {
        console.error('Erreur lors de l\'arrêt du scanner:', err);
      }
    }
    setScanning(false);
  };

  const handleClose = async () => {
    await stopScanning();
    onClose();
  };

  const handleRetry = async () => {
    setError(null);
    setCameraError(false);
    startScanning();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-xl">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-900">Scanner un paiement</h3>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="mb-4">
          {cameraError ? (
            <div className="text-center p-8 bg-gray-50 rounded-lg">
              <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
              <p className="text-gray-600 mb-4">
                {error || 'Impossible d\'accéder à la caméra'}
              </p>
              <button
                onClick={handleRetry}
                className="bg-primary-600 text-white px-4 py-2 rounded-md hover:bg-primary-700"
              >
                Réessayer
              </button>
            </div>
          ) : (
            <>
              <div className="relative">
                <div id="qr-reader" className="w-full rounded-lg overflow-hidden" />
                <div className="absolute inset-0 pointer-events-none">
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="w-64 h-64 border-2 border-primary-500 rounded-lg">
                      <div className="absolute top-0 left-0 w-4 h-4 border-t-2 border-l-2 border-primary-500" />
                      <div className="absolute top-0 right-0 w-4 h-4 border-t-2 border-r-2 border-primary-500" />
                      <div className="absolute bottom-0 left-0 w-4 h-4 border-b-2 border-l-2 border-primary-500" />
                      <div className="absolute bottom-0 right-0 w-4 h-4 border-b-2 border-r-2 border-primary-500" />
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-center text-sm text-gray-600 mt-2">
                Placez le QR code du client dans le cadre
              </p>
            </>
          )}
        </div>

        {error && !cameraError && (
          <div className="p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        <div className="text-center text-sm text-gray-600">
          <p className="mb-2">
            Maximum {maxFidecoinsPercent}% du montant peut être payé en SUNCOINS
          </p>
          <p>
            {scanning ? (
              <span className="flex items-center justify-center gap-2">
                <Loader className="h-4 w-4 animate-spin" />
                <span>Scanner en cours...</span>
              </span>
            ) : (
              <span className="flex items-center justify-center gap-2">
                <Camera className="h-4 w-4" />
                <span>Préparation du scanner...</span>
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}