import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { AlertTriangle } from 'lucide-react';

export function EmailVerificationBanner() {
  const { currentUser, sendVerificationEmail } = useAuth();
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  if (!currentUser || currentUser.emailVerified) return null;

  const handleResend = async () => {
    try {
      setSending(true);
      await sendVerificationEmail();
      setSent(true);
    } catch (error) {
      console.error('Error sending verification email:', error);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <AlertTriangle className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Veuillez vérifier votre adresse email. 
            {!sent && (
              <button
                onClick={handleResend}
                disabled={sending}
                className="ml-2 font-medium underline hover:text-yellow-600 disabled:opacity-50"
              >
                {sending ? 'Envoi en cours...' : 'Renvoyer l\'email de vérification'}
              </button>
            )}
            {sent && (
              <span className="ml-2 text-green-600">
                Email de vérification envoyé !
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}