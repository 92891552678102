import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { Percent, Save, AlertCircle, Loader, Info } from 'lucide-react';

const MAX_DISCOUNT_PERCENT = 20;

export default function DiscountSettings() {
  const { currentUser, userData, updateUserProfile } = useAuth();
  const [discountPercent, setDiscountPercent] = useState(
    userData?.merchantInfo?.discountPercent || MAX_DISCOUNT_PERCENT
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSave = async () => {
    if (!currentUser || !userData) return;

    try {
      setLoading(true);
      setError(null);
      setSuccess(false);

      // Vérifier que la remise ne dépasse pas le maximum
      if (discountPercent > MAX_DISCOUNT_PERCENT) {
        setError(`La remise ne peut pas dépasser ${MAX_DISCOUNT_PERCENT}%`);
        return;
      }

      // Mettre à jour le pourcentage de remise
      await updateDoc(doc(db, COLLECTIONS.USERS, currentUser.uid), {
        'merchantInfo.discountPercent': discountPercent
      });

      // Mettre à jour le contexte utilisateur
      await updateUserProfile({
        merchantInfo: {
          ...userData.merchantInfo,
          discountPercent
        }
      });

      setSuccess(true);
    } catch (err) {
      console.error('Erreur lors de la mise à jour de la remise:', err);
      setError('Une erreur est survenue lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-3 mb-6">
        <Percent className="h-6 w-6 text-primary-600" />
        <h3 className="text-xl font-semibold text-gray-900">Paramètres de remise</h3>
      </div>

      <div className="space-y-6">
        <div className="bg-blue-50 p-4 rounded-lg flex items-start space-x-3">
          <Info className="h-5 w-5 text-blue-600 mt-0.5 flex-shrink-0" />
          <div className="text-sm text-blue-700">
            <p className="font-medium">Limite de remise à {MAX_DISCOUNT_PERCENT}%</p>
            <p className="mt-1">
              Pour garantir la pérennité du système et maintenir l'équilibre entre récompenses et 
              rentabilité, la remise maximale en SUNCOINS est limitée à {MAX_DISCOUNT_PERCENT}% du 
              montant total de la commande.
            </p>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Pourcentage maximum payable en SUNCOINS
          </label>
          <div className="flex items-center space-x-4">
            <input
              type="number"
              min="0"
              max={MAX_DISCOUNT_PERCENT}
              value={discountPercent}
              onChange={(e) => setDiscountPercent(Number(e.target.value))}
              className="block w-32 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
            <span className="text-gray-600">%</span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            Les clients pourront utiliser leurs SUNCOINS pour payer jusqu'à ce pourcentage de leur commande
          </p>
        </div>

        {error && (
          <div className="p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="p-3 bg-green-50 text-green-600 rounded-md">
            Paramètres de remise mis à jour avec succès !
          </div>
        )}

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={loading}
            className="flex items-center space-x-2 bg-primary-600 text-white px-4 py-2 rounded-md hover:bg-primary-700 transition-colors duration-300 disabled:opacity-50"
          >
            {loading ? (
              <>
                <Loader className="h-5 w-5 animate-spin" />
                <span>Enregistrement...</span>
              </>
            ) : (
              <>
                <Save className="h-5 w-5" />
                <span>Enregistrer</span>
              </>
            )}
          </button>
        </div>

        <div className="mt-6 border-t pt-6">
          <h4 className="font-medium text-gray-900 mb-2">Comment ça marche ?</h4>
          <ul className="space-y-2 text-sm text-gray-600">
            <li>• Les clients accumulent des SUNCOINS avec le Step Challenge, en complétant des quêtes ou en jouant à SunQuest</li>
            <li>• 1 SUNCOIN = 0.01€ de remise</li>
            <li>• Exemple : Pour une commande de 100€ avec une remise de {MAX_DISCOUNT_PERCENT}%, le client peut utiliser jusqu'à {MAX_DISCOUNT_PERCENT * 100} SUNCOINS pour une remise de {MAX_DISCOUNT_PERCENT}€</li>
            <li>• Les SUNCOINS utilisés sont automatiquement déduits du solde du client</li>
            <li>• Vous recevez le paiement complet moins la valeur des SUNCOINS utilisés</li>
          </ul>
        </div>
      </div>
    </div>
  );
}