import { GAME_CONFIG } from './gameConfig';
import { Howl } from 'howler';

interface Coin {
  id: string;
  position: google.maps.LatLngLiteral;
  isLegendary: boolean;
  isRare?: boolean;
  marker?: google.maps.Marker;
}

const SUNCOIN_SVG = `
<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="shadow">
      <feDropShadow dx="0" dy="2" stdDeviation="1" flood-opacity="0.3"/>
    </filter>
  </defs>
  <g filter="url(#shadow)">
    <circle cx="20" cy="20" r="18" fill="#FFD700" stroke="#B8860B" stroke-width="2"/>
    <text x="20" y="25" font-size="16" font-weight="bold" text-anchor="middle" fill="#B8860B">S</text>
    <line x1="10" y1="20" x2="30" y2="20" stroke="#B8860B" stroke-width="2"/>
  </g>
  <animateTransform
    attributeName="transform"
    type="translate"
    values="0,0; 0,-5; 0,0"
    dur="1s"
    repeatCount="indefinite"
    additive="sum"
  />
  <animate 
    attributeName="opacity" 
    values="1;0.8;1" 
    dur="1s" 
    repeatCount="indefinite"
  />
</svg>`;

const LEGENDARY_SUNCOIN_SVG = `
<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="glow">
      <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
      <feMerge>
        <feMergeNode in="coloredBlur"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g filter="url(#glow)">
    <circle cx="20" cy="20" r="18" fill="#FF1493" stroke="#C71585" stroke-width="2"/>
    <text x="20" y="25" font-size="16" font-weight="bold" text-anchor="middle" fill="#FFF">S</text>
    <line x1="10" y1="20" x2="30" y2="20" stroke="#FFF" stroke-width="2"/>
  </g>
  <animateTransform
    attributeName="transform"
    type="translate"
    values="0,0; 0,-8; 0,0"
    dur="0.8s"
    repeatCount="indefinite"
    additive="sum"
  />
  <animate 
    attributeName="opacity" 
    values="1;0.8;1" 
    dur="0.8s" 
    repeatCount="indefinite"
  />
</svg>`;

const RARE_SUNCOIN_SVG = `
<svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="rareGlow">
      <feGaussianBlur stdDeviation="3" result="coloredBlur"/>
      <feMerge>
        <feMergeNode in="coloredBlur"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g filter="url(#rareGlow)">
    <circle cx="20" cy="20" r="18" fill="#4B0082" stroke="#2E0854" stroke-width="2"/>
    <text x="20" y="25" font-size="16" font-weight="bold" text-anchor="middle" fill="#FFF">S</text>
    <line x1="10" y1="20" x2="30" y2="20" stroke="#FFF" stroke-width="2"/>
  </g>
  <animateTransform
    attributeName="transform"
    type="translate"
    values="0,0; 0,-6; 0,0"
    dur="1.2s"
    repeatCount="indefinite"
    additive="sum"
  />
  <animate 
    attributeName="opacity" 
    values="1;0.7;1" 
    dur="1.2s" 
    repeatCount="indefinite"
  />
</svg>`;

export class CoinManager {
  private map: google.maps.Map;
  private coins: Map<string, Coin> = new Map();
  private onCollect: (type: 'regular' | 'legendary' | 'rare', value: number, position: { x: number; y: number }) => void;
  private coinSound: Howl;
  private legendaryCoinSound: Howl;
  private rareCoinSound: Howl;
  private playerPosition: google.maps.LatLngLiteral | null = null;

  constructor(
    map: google.maps.Map,
    onCollect: (type: 'regular' | 'legendary' | 'rare', value: number, position: { x: number; y: number }) => void
  ) {
    this.map = map;
    this.onCollect = onCollect;
    this.coinSound = new Howl({ src: ['/sounds/coin-common.mp3'] });
    this.legendaryCoinSound = new Howl({ src: ['/sounds/coin-legendary.mp3'] });
    this.rareCoinSound = new Howl({ src: ['/sounds/coin-rare.mp3'] });

    this.map.addListener('click', (e: google.maps.MapMouseEvent) => {
      if (e.latLng) {
        this.checkCoinsNearClick(e.latLng.toJSON());
      }
    });
  }

  public updatePlayerPosition(position: google.maps.LatLngLiteral) {
    this.playerPosition = position;
    
    if (this.coins.size === 0) {
      this.generateInitialCoins(position);
    }
  }

  private generateInitialCoins(center: google.maps.LatLngLiteral) {
    const count = Math.floor(Math.random() * 5) + 3; // 3-7 coins
    
    for (let i = 0; i < count; i++) {
      const angle = (i * 2 * Math.PI) / count;
      const distance = GAME_CONFIG.SPAWN_RADIUS.MIN + 
        Math.random() * (GAME_CONFIG.SPAWN_RADIUS.MAX - GAME_CONFIG.SPAWN_RADIUS.MIN);
      
      const position = {
        lat: center.lat + (Math.sin(angle) * distance) / 111111,
        lng: center.lng + (Math.cos(angle) * distance) / (111111 * Math.cos(center.lat * Math.PI / 180))
      };

      const isLegendary = Math.random() < 0.1; // 10% chance for legendary
      this.createCoin(position, isLegendary);
    }
  }

  private createCoin(position: google.maps.LatLngLiteral, isLegendary: boolean, isRare: boolean = false) {
    const svgString = isLegendary ? LEGENDARY_SUNCOIN_SVG : 
                     isRare ? RARE_SUNCOIN_SVG : 
                     SUNCOIN_SVG;

    const svgUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgString);

    const marker = new google.maps.Marker({
      position,
      map: this.map,
      icon: {
        url: svgUrl,
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(20, 20)
      },
      clickable: true,
      optimized: false
    });

    const coin: Coin = {
      id: crypto.randomUUID(),
      position,
      isLegendary,
      isRare,
      marker
    };

    this.coins.set(coin.id, coin);

    marker.addListener('click', () => {
      this.collectCoin(coin);
    });
  }

  private checkCoinsNearClick(clickPosition: google.maps.LatLngLiteral) {
    if (!this.playerPosition) return;

    this.coins.forEach(coin => {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(clickPosition),
        new google.maps.LatLng(coin.position)
      );

      if (distance <= GAME_CONFIG.COLLECTION_RADIUS) {
        this.collectCoin(coin);
      }
    });
  }

  private collectCoin(coin: Coin) {
    if (!this.playerPosition || !coin.marker) return;

    const distanceToPlayer = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(this.playerPosition),
      new google.maps.LatLng(coin.position)
    );

    if (distanceToPlayer > GAME_CONFIG.COLLECTION_RADIUS) {
      return;
    }

    let value: number;
    let type: 'regular' | 'legendary' | 'rare';

    if (coin.isLegendary) {
      value = GAME_CONFIG.REWARDS.LEGENDARY;
      type = 'legendary';
      this.legendaryCoinSound.play();
    } else if (coin.isRare) {
      value = GAME_CONFIG.REWARDS.RARE;
      type = 'rare';
      this.rareCoinSound.play();
    } else {
      value = GAME_CONFIG.REWARDS.REGULAR;
      type = 'regular';
      this.coinSound.play();
    }

    // Get screen position for animation
    const projection = this.map.getProjection();
    if (projection) {
      const position = projection.fromLatLngToPoint(coin.marker.getPosition()!);
      const bounds = this.map.getBounds();
      if (bounds) {
        const ne = projection.fromLatLngToPoint(bounds.getNorthEast());
        const sw = projection.fromLatLngToPoint(bounds.getSouthWest());
        const scale = Math.pow(2, this.map.getZoom()!);
        
        const screenPosition = {
          x: (position.x - sw.x) * scale,
          y: (position.y - ne.y) * scale
        };
        
        this.onCollect(type, value, screenPosition);
      }
    }

    // Remove collected coin
    coin.marker.setMap(null);
    this.coins.delete(coin.id);

    // Spawn new coin
    if (this.playerPosition) {
      const angle = Math.random() * Math.PI * 2;
      const distance = GAME_CONFIG.SPAWN_RADIUS.MIN + 
        Math.random() * (GAME_CONFIG.SPAWN_RADIUS.MAX - GAME_CONFIG.SPAWN_RADIUS.MIN);
      
      const newPosition = {
        lat: this.playerPosition.lat + (Math.sin(angle) * distance) / 111111,
        lng: this.playerPosition.lng + (Math.cos(angle) * distance) / (111111 * Math.cos(this.playerPosition.lat * Math.PI / 180))
      };

      const isLegendary = Math.random() < 0.1; // 10% chance for legendary
      this.createCoin(newPosition, isLegendary);
    }
  }

  public cleanup() {
    this.coins.forEach(coin => coin.marker?.setMap(null));
    this.coins.clear();
  }
}