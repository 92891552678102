export const GAME_CONFIG = {
  // Collection settings
  COLLECTION_RADIUS: 70,
  SPAWN_RADIUS: {
    MIN: 50,
    MAX: 200
  },
  REWARDS: {
    REGULAR: 1,
    LEGENDARY: 3,
    RARE: 5
  },
  DAILY_LIMITS: {
    REGULAR: 10,
    LEGENDARY: 3,
    RARE: 1
  },
  RARE_INTERVAL: 2 // Days between rare coins
};