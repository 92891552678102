import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Users, Store, Bell, CheckCircle, AlertTriangle, Loader, Trophy, Trash2, Search, Lock, Unlock, Coins } from 'lucide-react';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc, increment } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useAdmin } from '../contexts/AdminContext';
import SendSuncoinsModal from '../components/SendSuncoinsModal';
import AdminNotificationForm from '../components/AdminNotificationForm';
import MerchantValidationModal from '../components/MerchantValidationModal';

interface User {
  id: string;
  name: string;
  email: string;
  role: 'customer' | 'merchant' | 'admin';
  fidecoins: number;
  frozen?: boolean;
  merchantInfo?: {
    companyName: string;
    category: string;
  };
}

interface StatCardProps {
  icon: React.ElementType;
  label: string;
  value: number;
  color: 'blue' | 'purple' | 'yellow' | 'green' | 'pink';
}

const StatCard: React.FC<StatCardProps> = ({ icon: Icon, label, value, color }) => {
  const colorClasses = {
    blue: 'text-blue-600 bg-blue-100',
    purple: 'text-purple-600 bg-purple-100',
    yellow: 'text-yellow-600 bg-yellow-100',
    green: 'text-green-600 bg-green-100',
    pink: 'text-pink-600 bg-pink-100'
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-600">{label}</p>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
        </div>
        <div className={`p-3 rounded-full ${colorClasses[color]}`}>
          <Icon className="h-6 w-6" />
        </div>
      </div>
    </div>
  );
};

function Management() {
  const { currentUser } = useAuth();
  const { isAdmin, validateAdminAccess } = useAdmin();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState<'all' | 'customer' | 'merchant'>('all');
  const [showSendSuncoins, setShowSendSuncoins] = useState(false);
  const [showNotificationForm, setShowNotificationForm] = useState(false);
  const [showMerchantValidation, setShowMerchantValidation] = useState(false);
  const [pendingMerchants, setPendingMerchants] = useState<any[]>([]);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalMerchants: 0,
    pendingMerchants: 0,
    activeQuests: 0,
    activeCoupons: 0
  });

  useEffect(() => {
    const checkAccess = async () => {
      try {
        await validateAdminAccess();
      } catch (error) {
        navigate('/');
      }
    };

    checkAccess();
  }, [validateAdminAccess, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser || !isAdmin) return;

      try {
        setLoading(true);
        setError(null);

        const usersRef = collection(db, COLLECTIONS.USERS);
        const usersSnapshot = await getDocs(usersRef);
        const fetchedUsers = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as User[];

        const merchants = fetchedUsers.filter(user => user.role === 'merchant');
        const pendingMerchants = merchants.filter(merchant => !merchant.verified);

        setUsers(fetchedUsers);
        setPendingMerchants(pendingMerchants);
        setStats({
          totalUsers: fetchedUsers.length,
          totalMerchants: merchants.length,
          pendingMerchants: pendingMerchants.length,
          activeQuests: 0, // Will be updated with actual data
          activeCoupons: 0 // Will be updated with actual data
        });
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser, isAdmin]);

  const handleFreezeUser = async (userId: string, currentState: boolean) => {
    try {
      await validateAdminAccess();
      setLoading(true);
      
      await updateDoc(doc(db, COLLECTIONS.USERS, userId), {
        frozen: !currentState
      });
      
      setUsers(users.map(user => {
        if (user.id === userId) {
          return { ...user, frozen: !currentState };
        }
        return user;
      }));
    } catch (err) {
      console.error('Error freezing user:', err);
      setError('Erreur lors du gel/dégel du compte');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      await validateAdminAccess();
      setLoading(true);
      
      await deleteDoc(doc(db, COLLECTIONS.USERS, userId));
      setUsers(users.filter(user => user.id !== userId));
    } catch (err) {
      console.error('Error deleting user:', err);
      setError('Erreur lors de la suppression de l\'utilisateur');
    } finally {
      setLoading(false);
    }
  };

  const handleSendSuncoins = async (userId: string, amount: number) => {
    try {
      await validateAdminAccess();
      setLoading(true);
      
      await updateDoc(doc(db, COLLECTIONS.USERS, userId), {
        fidecoins: increment(amount)
      });
      
      setUsers(users.map(user => {
        if (user.id === userId) {
          return { ...user, fidecoins: (user.fidecoins || 0) + amount };
        }
        return user;
      }));
      
      setShowSendSuncoins(false);
    } catch (err) {
      console.error('Error sending SUNCOINS:', err);
      setError('Erreur lors de l\'envoi des SUNCOINS');
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user => {
    const matchesSearch = 
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.merchantInfo?.companyName?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesRole = selectedRole === 'all' || user.role === selectedRole;
    
    return matchesSearch && matchesRole;
  });

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center space-x-3">
          <Shield className="h-8 w-8 text-primary-600" />
          <h1 className="text-2xl font-bold text-gray-900">Gestion</h1>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
        <StatCard
          icon={Users}
          label="Utilisateurs"
          value={stats.totalUsers}
          color="blue"
        />
        <StatCard
          icon={Store}
          label="Marchands"
          value={stats.totalMerchants}
          color="purple"
        />
        <StatCard
          icon={AlertTriangle}
          label="En attente"
          value={stats.pendingMerchants}
          color="yellow"
        />
        <StatCard
          icon={CheckCircle}
          label="Quêtes actives"
          value={stats.activeQuests}
          color="green"
        />
        <StatCard
          icon={Trophy}
          label="Coupons actifs"
          value={stats.activeCoupons}
          color="pink"
        />
      </div>

      {/* Quick Actions */}
      <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Actions rapides</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <button
            onClick={() => setShowMerchantValidation(true)}
            className="flex items-center justify-center space-x-2 bg-primary-600 text-white py-3 px-4 rounded-md hover:bg-primary-700"
          >
            <CheckCircle className="h-5 w-5" />
            <span>Valider les marchands</span>
          </button>

          <button
            onClick={() => setShowNotificationForm(true)}
            className="flex items-center justify-center space-x-2 bg-yellow-600 text-white py-3 px-4 rounded-md hover:bg-yellow-700"
          >
            <Bell className="h-5 w-5" />
            <span>Envoyer une notification</span>
          </button>

          <button
            onClick={() => setShowSendSuncoins(true)}
            className="flex items-center justify-center space-x-2 bg-green-600 text-white py-3 px-4 rounded-md hover:bg-green-700"
          >
            <Coins className="h-5 w-5" />
            <span>Envoyer des SUNCOINS</span>
          </button>
        </div>
      </div>

      {/* Users Table */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="flex-1 relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Rechercher un utilisateur..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-primary-500 focus:border-transparent"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          </div>

          <select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value as typeof selectedRole)}
            className="px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-primary-500 focus:border-transparent"
          >
            <option value="all">Tous les utilisateurs</option>
            <option value="customer">Clients</option>
            <option value="merchant">Marchands</option>
          </select>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nom
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rôle
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  SUNCOINS
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Statut
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {user.role === 'merchant' ? user.merchantInfo?.companyName : user.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">{user.email}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.role === 'merchant' 
                        ? 'bg-purple-100 text-purple-800'
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {user.role === 'merchant' ? 'Marchand' : 'Client'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.fidecoins || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.frozen
                        ? 'bg-red-100 text-red-800'
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {user.frozen ? 'Gelé' : 'Actif'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => handleFreezeUser(user.id, user.frozen || false)}
                        className={`p-1 rounded-full ${
                          user.frozen
                            ? 'text-green-600 hover:text-green-900'
                            : 'text-red-600 hover:text-red-900'
                        }`}
                        title={user.frozen ? 'Dégeler le compte' : 'Geler le compte'}
                      >
                        {user.frozen ? (
                          <Unlock className="h-5 w-5" />
                        ) : (
                          <Lock className="h-5 w-5" />
                        )}
                      </button>
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="p-1 text-red-600 hover:text-red-900 rounded-full"
                        title="Supprimer le compte"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => setShowSendSuncoins(true)}
                        className="p-1 text-primary-600 hover:text-primary-900 rounded-full"
                        title="Envoyer des SUNCOINS"
                      >
                        <Coins className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modals */}
      {showNotificationForm && (
        <AdminNotificationForm
          onSubmit={async (data) => {
            try {
              setLoading(true);
              // Implement notification sending
              setShowNotificationForm(false);
            } catch (err) {
              console.error('Error sending notification:', err);
              setError('Erreur lors de l\'envoi de la notification');
            } finally {
              setLoading(false);
            }
          }}
          onClose={() => setShowNotificationForm(false)}
          loading={loading}
        />
      )}

      {showMerchantValidation && (
        <MerchantValidationModal
          merchants={pendingMerchants}
          onClose={() => setShowMerchantValidation(false)}
        />
      )}

      {showSendSuncoins && (
        <SendSuncoinsModal
          onSubmit={handleSendSuncoins}
          onClose={() => setShowSendSuncoins(false)}
          loading={loading}
        />
      )}
    </div>
  );
}

export default Management;