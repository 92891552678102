import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { X, Send } from 'lucide-react';
import { CSSTransition } from 'react-transition-group';
import { tips } from './sunnyTips';
import { getResponseForRoute } from './sunnyResponses';
import { Message, SunnyDatabaseInfo } from './types';
import { SunnyDatabaseManager } from '../../lib/sunny';
import SunnyAvatar from './SunnyAvatar';

export default function SunnyChat() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [currentTypingText, setCurrentTypingText] = useState('');
  const [lastTipTime, setLastTipTime] = useState(Date.now());
  const [databaseInfo, setDatabaseInfo] = useState<SunnyDatabaseInfo | null>(null);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const chatRef = useRef<HTMLDivElement>(null);
  const dbManagerRef = useRef<SunnyDatabaseManager | null>(null);
  const welcomeShownRef = useRef(false);
  const typingSpeedRef = useRef(50); // ms per character

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (isOpen && !dbManagerRef.current) {
      dbManagerRef.current = new SunnyDatabaseManager();
      const unsubscribe = dbManagerRef.current.subscribe((info) => {
        setDatabaseInfo(info);
      });

      return () => {
        unsubscribe();
        dbManagerRef.current?.cleanup();
        dbManagerRef.current = null;
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !welcomeShownRef.current) {
      const welcomeMessage = getResponseForRoute(location.pathname, 'welcome');
      if (welcomeMessage) {
        addMessage({
          type: 'sunny',
          content: welcomeMessage,
        });
        welcomeShownRef.current = true;
      }
    }
  }, [isOpen, location.pathname]);

  useEffect(() => {
    const tipInterval = setInterval(() => {
      const now = Date.now();
      if (now - lastTipTime >= 5 * 60 * 1000 && Math.random() > 0.7) {
        const randomTip = tips[Math.floor(Math.random() * tips.length)];
        addMessage({
          type: 'sunny',
          content: `💡 Tip: ${randomTip}`,
        });
        setLastTipTime(now);
      }
    }, 60 * 1000);

    return () => clearInterval(tipInterval);
  }, [lastTipTime]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentTypingText]);

  const simulateTyping = async (text: string) => {
    setIsTyping(true);
    let currentText = '';
    
    for (let i = 0; i < text.length; i++) {
      currentText += text[i];
      setCurrentTypingText(currentText);
      await new Promise(resolve => setTimeout(resolve, typingSpeedRef.current));
    }
    
    setCurrentTypingText('');
    setIsTyping(false);
    return text;
  };

  const addMessage = async (message: Message) => {
    if (message.type === 'sunny') {
      const typedText = await simulateTyping(message.content);
      setMessages(prev => [...prev, { ...message, content: typedText }]);
    } else {
      setMessages(prev => [...prev, message]);
    }
  };

  const generateDatabaseResponse = (question: string): string | null => {
    if (!databaseInfo) return null;

    const keywords = {
      merchants: () => `There are currently ${databaseInfo.merchants.total} active merchants on the platform, offering ${databaseInfo.merchants.activeQuests} quests and ${databaseInfo.merchants.activeCoupons} coupons!`,
      quests: () => `We have ${databaseInfo.merchants.activeQuests} active quests right now. Users have already completed ${databaseInfo.users.questCompletions} quests in total!`,
      coupons: () => `${databaseInfo.merchants.activeCoupons} coupons are currently available. The average discount is ${databaseInfo.stats.averageDiscount.toFixed(1)}%!`,
      suncoins: () => `Users have an average of ${Math.round(databaseInfo.users.averageSuncoins)} SUNCOINS. In total, ${databaseInfo.users.totalSuncoinsSpent} SUNCOINS have been used!`,
      categories: () => `The most popular categories are: ${databaseInfo.stats.popularCategories.join(', ')}`,
      statistics: () => `We've processed ${databaseInfo.stats.totalTransactions} transactions, with an average discount of ${databaseInfo.stats.averageDiscount.toFixed(1)}%. ${databaseInfo.users.total} users are already enjoying our services!`
    };

    for (const [key, response] of Object.entries(keywords)) {
      if (question.toLowerCase().includes(key)) {
        return response();
      }
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim() || isTyping) return;

    const userQuestion = inputValue;
    addMessage({
      type: 'user',
      content: inputValue,
    });

    setInputValue('');

    setTimeout(() => {
      const databaseResponse = generateDatabaseResponse(userQuestion);
      const response = databaseResponse || getResponseForRoute(location.pathname, 'question', userQuestion);
      
      addMessage({
        type: 'sunny',
        content: response,
      });
    }, 500);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-20 md:bottom-4 right-4 bg-primary-600 text-white p-3 rounded-full shadow-lg hover:bg-primary-700 transition-colors z-50"
      >
        <SunnyAvatar />
      </button>

      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="chat"
        unmountOnExit
        nodeRef={chatRef}
      >
        <div ref={chatRef} className="fixed bottom-36 md:bottom-20 right-4 w-96 bg-white rounded-xl shadow-xl z-50">
          <div className="p-4 bg-primary-600 text-white rounded-t-xl flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <SunnyAvatar />
              <div>
                <h3 className="font-bold">Sunny</h3>
                <p className="text-xs text-white/80">Virtual Assistant</p>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="text-white hover:text-gray-200"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="h-96 overflow-y-auto p-4 space-y-4">
            {isOffline && (
              <div className="bg-yellow-50 p-3 rounded-lg text-yellow-800 text-sm">
                Offline mode. Some features may be limited.
              </div>
            )}
            
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex items-start space-x-2 message-animation ${
                  message.type === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                {message.type === 'sunny' && <SunnyAvatar />}
                <div
                  className={`max-w-[80%] p-3 rounded-lg ${
                    message.type === 'user'
                      ? 'bg-primary-600 text-white'
                      : 'bg-gray-100 text-gray-900'
                  }`}
                >
                  {message.content}
                </div>
              </div>
            ))}
            {isTyping && currentTypingText && (
              <div className="flex items-start space-x-2 message-animation">
                <SunnyAvatar />
                <div className="bg-gray-100 p-3 rounded-lg text-gray-900">
                  {currentTypingText}
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <form onSubmit={handleSubmit} className="p-4 border-t">
            <div className="flex space-x-2">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Ask Sunny a question..."
                className="flex-1 rounded-lg border-gray-300 focus:border-primary-500 focus:ring-primary-500"
                disabled={isTyping || isOffline}
              />
              <button
                type="submit"
                disabled={isTyping || isOffline}
                className="bg-primary-600 text-white p-2 rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
              >
                <Send className="h-5 w-5" />
              </button>
            </div>
          </form>
        </div>
      </CSSTransition>
    </>
  );
}