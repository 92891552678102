import React from 'react';
import { AlertCircle } from 'lucide-react';

interface GameHUDProps {
  loading: boolean;
  error: string | null;
  dailyStats: {
    regular: number;
    legendary: number;
    rare: number;
  };
}

export default function GameHUD({ loading, error, dailyStats }: GameHUDProps) {
  return (
    <div className="fixed top-0 left-0 right-0 bg-white shadow-lg z-50 p-4">
      <div className="flex justify-between items-center">
        {/* Normal coins */}
        <div className="text-center">
          <div className="text-sm text-gray-600">Normal</div>
          <div className="font-bold">
            {dailyStats.regular}/10
            <span className="text-xs text-primary-600 ml-1">+1</span>
          </div>
        </div>

        {/* Legendary coins */}
        <div className="text-center">
          <div className="text-sm text-gray-600">Légendaire</div>
          <div className="font-bold">
            {dailyStats.legendary}/2
            <span className="text-xs text-primary-600 ml-1">+3</span>
          </div>
        </div>

        {/* Rare coins */}
        <div className="text-center">
          <div className="text-sm text-gray-600">Rare</div>
          <div className="font-bold">
            {dailyStats.rare}/1
            <span className="text-xs text-primary-600 ml-1">+5</span>
          </div>
        </div>
      </div>

      {error && (
        <div className="mt-2 p-2 bg-red-50 rounded-lg flex items-center gap-2">
          <AlertCircle className="h-4 w-4 text-red-500" />
          <span className="text-sm text-red-600">{error}</span>
        </div>
      )}
    </div>
  );
}