import React from 'react';
import { Crown, Star, Zap, Shield, Gift } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import PremiumSubscriptionButton from './PremiumSubscriptionButton';

export default function PremiumSubscriptionBanner() {
  const { userData } = useAuth();
  const isPremium = userData?.subscription?.status === 'active';

  if (isPremium) return null;

  return (
    <div className="bg-gradient-to-r from-yellow-50 to-amber-50 rounded-xl p-6 shadow-lg">
      <div className="flex items-start space-x-6">
        <div className="hidden md:block">
          <div className="w-16 h-16 bg-gradient-to-br from-yellow-500 to-amber-500 rounded-full shadow-lg flex items-center justify-center">
            <Crown className="h-8 w-8 text-white" />
          </div>
        </div>
        
        <div className="flex-1">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Passez Premium ! ✨
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="space-y-3">
              <div className="flex items-center space-x-2 text-gray-700">
                <Star className="h-5 w-5 text-yellow-500" />
                <span>Double SUNCOINS sur les quêtes</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <Zap className="h-5 w-5 text-yellow-500" />
                <span>Quêtes exclusives Premium</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <Shield className="h-5 w-5 text-yellow-500" />
                <span>Support prioritaire</span>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center space-x-2 text-gray-700">
                <Crown className="h-5 w-5 text-yellow-500" />
                <span>Badge Premium exclusif</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <Gift className="h-5 w-5 text-yellow-500" />
                <span>Bonus quotidien de 50 SUNCOINS</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <Zap className="h-5 w-5 text-yellow-500" />
                <span>Notifications instantanées</span>
              </div>
            </div>
          </div>

          <PremiumSubscriptionButton className="w-full md:w-auto" />
          
          <p className="text-sm text-gray-500 mt-4">
            Essai gratuit de 14 jours • Annulation à tout moment
          </p>
        </div>
      </div>
    </div>
  );
}