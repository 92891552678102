import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, getDoc, increment } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { Footprints, Award, AlertCircle, Play, Pause, Activity, MapPin } from 'lucide-react';
import { StepCounter, isStepCountingSupported } from '../lib/stepCounter';

const STEPS_PER_SUNCOIN = 2000;
const DAILY_GOAL = 10000;
const BONUS_REWARD = 10;

const milestones = [2000, 4000, 6000, 8000, 10000];

export default function StepChallenge() {
  const { currentUser } = useAuth();
  const [steps, setSteps] = useState(0);
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isTracking, setIsTracking] = useState(false);
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    if (!currentUser) return;

    if (!isStepCountingSupported()) {
      setIsSupported(false);
      setError("Votre appareil ne supporte pas la détection de pas. Utilisez un appareil mobile avec accéléromètre.");
      return;
    }

    loadTodaySteps();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser || !isTracking) return;

    const stepCounter = StepCounter.getInstance();
    const subscription = stepCounter.getSteps().subscribe(newSteps => {
      handleStepUpdate(newSteps);
    });

    stepCounter.start(currentUser.uid).catch(err => {
      console.error('Erreur démarrage compteur:', err);
      setError(err.message);
      setIsTracking(false);
    });

    return () => {
      subscription.unsubscribe();
      stepCounter.stop();
    };
  }, [currentUser, isTracking]);

  const loadTodaySteps = async () => {
    if (!currentUser) return;

    try {
      const today = new Date().toISOString().split('T')[0];
      const stepsRef = doc(db, COLLECTIONS.USERS, currentUser.uid, 'dailySteps', today);
      
      // Initialize today's document if it doesn't exist
      await setDoc(stepsRef, {
        count: 0,
        lastUpdate: new Date()
      }, { merge: true });

      const stepsDoc = await getDoc(stepsRef);
      if (stepsDoc.exists()) {
        setSteps(stepsDoc.data().count || 0);
        if (stepsDoc.data().lastUpdate) {
          setLastUpdate(new Date(stepsDoc.data().lastUpdate.toDate()).toLocaleTimeString());
        }
      }
    } catch (err) {
      console.error('Erreur chargement pas:', err);
      setError('Impossible de charger les données de pas');
    }
  };

  const handleStepUpdate = async (newSteps: number) => {
    if (!currentUser) return;

    try {
      const today = new Date().toISOString().split('T')[0];
      const stepsRef = doc(db, COLLECTIONS.USERS, currentUser.uid, 'dailySteps', today);
      const userRef = doc(db, COLLECTIONS.USERS, currentUser.uid);

      await setDoc(stepsRef, {
        count: newSteps,
        lastUpdate: new Date()
      }, { merge: true });

      const previousMilestones = Math.floor(steps / STEPS_PER_SUNCOIN);
      const newMilestones = Math.floor(newSteps / STEPS_PER_SUNCOIN);
      const suncoinsEarned = newMilestones - previousMilestones;

      const reachedGoalNow = newSteps >= DAILY_GOAL && steps < DAILY_GOAL;
      const bonusReward = reachedGoalNow ? BONUS_REWARD : 0;

      if (suncoinsEarned > 0 || bonusReward > 0) {
        await setDoc(userRef, {
          fidecoins: increment(suncoinsEarned + bonusReward)
        }, { merge: true });
      }

      setSteps(newSteps);
      setLastUpdate(new Date().toLocaleTimeString());
    } catch (err) {
      console.error('Erreur mise à jour pas:', err);
      setError('Erreur lors de la mise à jour des pas');
    }
  };

  const toggleTracking = async () => {
    try {
      const stepCounter = StepCounter.getInstance();

      if (!isTracking) {
        await stepCounter.start(currentUser!.uid);
        setIsTracking(true);
        setError(null);
      } else {
        stepCounter.stop();
        setIsTracking(false);
      }
    } catch (err: any) {
      setError(err.message || "Impossible de gérer le suivi d'activité");
    }
  };

  const getCurrentMilestone = () => {
    for (let i = 0; i < milestones.length; i++) {
      if (steps < milestones[i]) {
        return i;
      }
    }
    return milestones.length;
  };

  const getProgressToNextMilestone = () => {
    const currentIndex = getCurrentMilestone();
    if (currentIndex === 0) {
      return (steps / milestones[0]) * 100;
    }
    if (currentIndex === milestones.length) {
      return 100;
    }
    
    const prevMilestone = milestones[currentIndex - 1];
    const nextMilestone = milestones[currentIndex];
    const progressInSegment = steps - prevMilestone;
    const segmentSize = nextMilestone - prevMilestone;
    
    return ((progressInSegment / segmentSize) * 100) + ((currentIndex / milestones.length) * 100);
  };

  if (!isSupported) {
    return (
      <div className="bg-yellow-50 rounded-lg p-4">
        <div className="flex items-center space-x-2">
          <AlertCircle className="h-5 w-5 text-yellow-600" />
          <p className="text-sm text-yellow-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Step Challenge</h2>
      <div className="bg-white rounded-xl shadow-sm p-4">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-3">
          <div className="flex items-center gap-2">
            <button
              onClick={toggleTracking}
              className={`inline-flex items-center gap-1.5 px-3 py-1.5 text-xs font-medium rounded-full transition-colors ${
                isTracking 
                  ? 'bg-red-50 text-red-600 hover:bg-red-100'
                  : 'bg-primary-50 text-primary-600 hover:bg-primary-100'
              }`}
            >
              {isTracking ? (
                <>
                  <Pause className="h-3.5 w-3.5" />
                  <span>Pause</span>
                </>
              ) : (
                <>
                  <Play className="h-3.5 w-3.5" />
                  <span>Démarrer</span>
                </>
              )}
            </button>
            <div className="flex items-center gap-2 text-sm">
              <Activity className="h-4 w-4 text-primary-600" />
              <span className="font-medium text-gray-900">{steps.toLocaleString()}</span>
              <span className="text-xs text-gray-500">pas</span>
            </div>
          </div>
        </div>

        {error && (
          <div className="mt-3 p-2 bg-red-50 text-red-600 rounded text-xs flex items-center gap-1.5">
            <AlertCircle className="h-4 w-4" />
            <span>{error}</span>
          </div>
        )}

        <div className="relative mt-6">
          <div className="h-1.5 bg-gray-100 rounded-full mb-10 md:mb-8">
            <div
              className="h-full bg-primary-600 rounded-full transition-all duration-500"
              style={{ width: `${Math.min(getProgressToNextMilestone(), 100)}%` }}
            />
          </div>

          <div className="absolute -top-1 left-0 right-0 flex justify-between">
            {milestones.map((milestone, index) => {
              const completed = steps >= milestone;
              const current = steps < milestone && (index === 0 || steps >= milestones[index - 1]);
              
              return (
                <div key={milestone} className="relative flex flex-col items-center">
                  <div
                    className={`w-3 h-3 rounded-full border ${
                      completed
                        ? 'bg-primary-600 border-primary-600'
                        : current
                        ? 'bg-white border-primary-600'
                        : 'bg-white border-gray-300'
                    }`}
                  />
                  
                  <div className="absolute -top-6 transform -translate-x-1/2 left-1/2 hidden md:block">
                    <span className={`text-xs ${completed ? 'text-primary-600 font-medium' : 'text-gray-500'}`}>
                      {(milestone / 1000)}k
                    </span>
                  </div>

                  <div className="absolute -bottom-6 transform -translate-x-1/2 left-1/2">
                    <span className={`text-[10px] md:text-xs ${completed ? 'text-primary-600 font-medium' : 'text-gray-500'}`}>
                      +{milestone / STEPS_PER_SUNCOIN}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {steps >= DAILY_GOAL && (
          <div className="mt-10 bg-green-50 text-green-600 p-2 rounded text-xs flex items-center justify-center gap-1.5">
            <Award className="h-4 w-4" />
            <span>Objectif atteint ! +{BONUS_REWARD} SUNCOINS !</span>
          </div>
        )}

        {lastUpdate && (
          <p className="mt-3 text-[10px] text-gray-400 text-center">
            Dernière mise à jour : {lastUpdate}
          </p>
        )}

        {isTracking && (
          <p className="mt-2 text-[10px] text-primary-500 text-center">
            ℹ️ Pour un suivi optimal, gardez Sunkdo ouvert dans votre navigateur
          </p>
        )}
      </div>
    </>
  );
}