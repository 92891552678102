import React from 'react';
import { Link } from 'react-router-dom';
import { LogIn, Gamepad2 } from 'lucide-react';

const AuthRedirect = () => {
  return (
    <div className="min-h-[80vh] flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full">
        <div className="text-center space-y-6">
          <div className="bg-gradient-to-r from-primary-50 via-accent-50 to-primary-50 rounded-full p-4 w-20 h-20 mx-auto flex items-center justify-center">
            <Gamepad2 className="h-10 w-10 text-primary-600" />
          </div>

          <div className="space-y-2">
            <h2 className="text-2xl font-bold text-gray-900">
              Connectez-vous pour jouer !
            </h2>
            <p className="text-gray-600">
              Rejoignez l'aventure SUNQUEST et commencez à gagner des SUNCOINS en explorant votre ville !
            </p>
          </div>

          <div className="space-y-4">
            <Link
              to="/login"
              className="block w-full bg-primary-600 text-white py-3 px-4 rounded-lg hover:bg-primary-700 transition-colors duration-300 flex items-center justify-center space-x-2"
            >
              <LogIn className="h-5 w-5" />
              <span>Se connecter</span>
            </Link>
            
            <Link
              to="/register"
              className="block w-full bg-white text-primary-600 py-3 px-4 rounded-lg border-2 border-primary-600 hover:bg-primary-50 transition-colors duration-300"
            >
              Créer un compte
            </Link>
          </div>

          <p className="text-sm text-gray-500">
            Déjà des milliers de joueurs parcourent les rues à la recherche de SUNCOINS. Ne manquez pas cette aventure unique !
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthRedirect;