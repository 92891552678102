import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { Loader, Camera, AlertCircle, X } from 'lucide-react';

interface QuestScannerProps {
  userId: string;
  onScan: (questId: string) => void;
  onClose: () => void;
}

export default function QuestScanner({ userId, onScan, onClose }: QuestScannerProps) {
  const [error, setError] = useState<string | null>(null);
  const [scanning, setScanning] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const html5QrCode = useRef<Html5Qrcode | null>(null);

  useEffect(() => {
    let mounted = true;

    const initializeScanner = async () => {
      try {
        const devices = await Html5Qrcode.getCameras();
        if (devices && devices.length > 0) {
          html5QrCode.current = new Html5Qrcode("qr-reader");
          if (mounted) {
            startScanning();
          }
        } else {
          setCameraError(true);
          setError('Aucune caméra détectée');
        }
      } catch (err) {
        console.error('Erreur d\'initialisation de la caméra:', err);
        setCameraError(true);
        setError('Impossible d\'accéder à la caméra');
      }
    };

    initializeScanner();

    return () => {
      mounted = false;
      stopScanning();
    };
  }, []);

  const handleQRCode = async (qrData: string) => {
    try {
      const data = JSON.parse(qrData);
      
      if (!data.type || data.type !== 'quest' || !data.id || !data.code) {
        throw new Error('QR code invalide');
      }

      onScan(data.id);
      await stopScanning();
    } catch (err: any) {
      console.error('Erreur traitement QR code:', err);
      setError(err.message || 'QR code invalide');
    }
  };

  const startScanning = async () => {
    if (!html5QrCode.current) return;

    try {
      setScanning(true);
      setError(null);

      await html5QrCode.current.start(
        { facingMode: "environment" },
        {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          aspectRatio: 1.0,
        },
        handleQRCode,
        (errorMessage) => {
          if (!errorMessage.includes('No MultiFormat Readers')) {
            console.debug('Scanner status:', errorMessage);
          }
        }
      );
    } catch (err) {
      console.error('Erreur du scanner:', err);
      setError('Erreur lors de l\'initialisation du scanner');
      setScanning(false);
    }
  };

  const stopScanning = async () => {
    if (html5QrCode.current?.isScanning) {
      try {
        await html5QrCode.current.stop();
        await html5QrCode.current.clear();
      } catch (err) {
        console.error('Erreur lors de l\'arrêt du scanner:', err);
      }
    }
    setScanning(false);
  };

  const handleClose = async () => {
    await stopScanning();
    onClose();
  };

  const handleRetry = async () => {
    setError(null);
    setCameraError(false);
    startScanning();
  };

  return (
    <div className="fixed inset-0 z-50">
      <div className="absolute inset-0 bg-black bg-opacity-75 backdrop-blur-sm" onClick={handleClose} />
      
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-lg">
        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div className="relative px-6 py-4 bg-primary-50">
            <h3 className="text-xl font-semibold text-gray-900">Scanner un QR code</h3>
            <button
              onClick={handleClose}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="p-6">
            {cameraError ? (
              <div className="text-center p-8 bg-gray-50 rounded-lg">
                <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
                <p className="text-gray-600 mb-4">
                  {error || 'Impossible d\'accéder à la caméra'}
                </p>
                <button
                  onClick={handleRetry}
                  className="bg-primary-600 text-white px-4 py-2 rounded-md hover:bg-primary-700 transition-colors"
                >
                  Réessayer
                </button>
              </div>
            ) : (
              <>
                <div className="relative bg-black rounded-lg overflow-hidden">
                  <div id="qr-reader" className="w-full" />
                  <div className="absolute inset-0 pointer-events-none">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <div className="w-48 h-48 border-2 border-primary-500 rounded-lg">
                        <div className="absolute top-0 left-0 w-4 h-4 border-t-2 border-l-2 border-primary-500" />
                        <div className="absolute top-0 right-0 w-4 h-4 border-t-2 border-r-2 border-primary-500" />
                        <div className="absolute bottom-0 left-0 w-4 h-4 border-b-2 border-l-2 border-primary-500" />
                        <div className="absolute bottom-0 right-0 w-4 h-4 border-b-2 border-r-2 border-primary-500" />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-center text-sm text-gray-600 mt-4">
                  Placez le QR code dans le cadre
                </p>
              </>
            )}

            {error && !cameraError && (
              <div className="mt-4 p-3 bg-red-50 text-red-600 rounded-md flex items-center gap-2">
                <AlertCircle className="h-5 w-5 flex-shrink-0" />
                <span>{error}</span>
              </div>
            )}

            <div className="text-center text-sm text-gray-600 mt-4">
              {scanning ? (
                <div className="flex items-center justify-center gap-2">
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Scanner en cours...</span>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-2">
                  <Camera className="h-4 w-4" />
                  <span>Préparation du scanner...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}