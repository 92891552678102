import React, { useState } from 'react';
import { Store, Loader } from 'lucide-react';
import { createSubscription } from '../lib/stripe/subscriptions';
import { useAuth } from '../contexts/AuthContext';

interface MerchantSubscriptionButtonProps {
  onError?: (error: Error) => void;
  className?: string;
}

export default function MerchantSubscriptionButton({
  onError,
  className = ''
}: MerchantSubscriptionButtonProps) {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handleSubscribe = async () => {
    if (!currentUser) {
      onError?.(new Error('Vous devez être connecté pour souscrire'));
      return;
    }

    try {
      setLoading(true);
      const sessionId = await createSubscription(currentUser.uid, 'MERCHANT');
      window.location.href = sessionId;
    } catch (error) {
      console.error('Subscription error:', error);
      onError?.(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={handleSubscribe}
      disabled={loading}
      className={`flex items-center justify-center space-x-2 bg-primary-600 text-white px-6 py-3 rounded-lg hover:bg-primary-700 disabled:opacity-50 ${className}`}
    >
      {loading ? (
        <>
          <Loader className="h-5 w-5 animate-spin" />
          <span>Traitement en cours...</span>
        </>
      ) : (
        <>
          <Store className="h-5 w-5" />
          <span>Devenir marchand pour 19,90€/mois</span>
        </>
      )}
    </button>
  );
}