import React from 'react';
import { Trophy, Gift, Star, Lock, Crown } from 'lucide-react';
import { usePremiumStatus } from '../../lib/premium/hooks';
import { useAuth } from '../../contexts/AuthContext';

interface Reward {
  id: string;
  title: string;
  description: string;
  icon: React.ElementType;
  suncoins: number;
  unlocked: boolean;
  progress?: number;
}

export default function PremiumRewards() {
  const { isPremium, premiumStats } = usePremiumStatus();
  const { userData } = useAuth();

  if (!userData) return null;

  const rewards: Reward[] = [
    {
      id: 'daily_streak',
      title: 'Série quotidienne',
      description: 'Connectez-vous 7 jours de suite',
      icon: Star,
      suncoins: 100,
      unlocked: isPremium && (premiumStats?.loginStreak || 0) >= 7,
      progress: ((premiumStats?.loginStreak || 0) / 7) * 100
    },
    {
      id: 'quest_master',
      title: 'Maître des quêtes',
      description: 'Complétez 10 quêtes Premium',
      icon: Trophy,
      suncoins: 250,
      unlocked: isPremium && (premiumStats?.exclusiveQuestsCompleted || 0) >= 10,
      progress: ((premiumStats?.exclusiveQuestsCompleted || 0) / 10) * 100
    },
    {
      id: 'bonus_collector',
      title: 'Collectionneur de bonus',
      description: 'Réclamez 30 bonus quotidiens',
      icon: Gift,
      suncoins: 500,
      unlocked: isPremium && (premiumStats?.dailyBonusClaimed || 0) >= 30,
      progress: ((premiumStats?.dailyBonusClaimed || 0) / 30) * 100
    }
  ];

  if (!isPremium) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center space-x-3 mb-6">
          <Trophy className="h-6 w-6 text-primary-600" />
          <h2 className="text-xl font-semibold text-gray-900">Récompenses Premium</h2>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg text-center space-y-4">
          <Lock className="h-8 w-8 text-gray-400 mx-auto" />
          <p className="text-gray-600">
            Devenez membre Premium pour débloquer des récompenses exclusives !
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center space-x-3 mb-6">
        <Trophy className="h-6 w-6 text-primary-600" />
        <h2 className="text-xl font-semibold text-gray-900">Récompenses Premium</h2>
      </div>

      <div className="space-y-6">
        {rewards.map((reward) => (
          <div
            key={reward.id}
            className={`p-4 rounded-lg ${
              reward.unlocked
                ? 'bg-green-50 border border-green-200'
                : 'bg-gray-50 border border-gray-200'
            }`}
          >
            <div className="flex items-start space-x-4">
              <div className={`p-2 rounded-full ${
                reward.unlocked ? 'bg-green-100' : 'bg-gray-100'
              }`}>
                <reward.icon className={`h-6 w-6 ${
                  reward.unlocked ? 'text-green-600' : 'text-gray-500'
                }`} />
              </div>
              
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <h3 className="font-medium text-gray-900">{reward.title}</h3>
                  <div className="flex items-center space-x-1 text-primary-600">
                    <Crown className="h-4 w-4" />
                    <span className="font-medium">{reward.suncoins}</span>
                  </div>
                </div>
                
                <p className="text-sm text-gray-600 mt-1">
                  {reward.description}
                </p>

                {typeof reward.progress === 'number' && !reward.unlocked && (
                  <div className="mt-3">
                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-primary-600 transition-all duration-500"
                        style={{ width: `${Math.min(100, reward.progress)}%` }}
                      />
                    </div>
                    <p className="text-xs text-gray-500 mt-1">
                      {Math.round(reward.progress)}% complété
                    </p>
                  </div>
                )}

                {reward.unlocked && (
                  <div className="mt-2 flex items-center space-x-2 text-green-600">
                    <Trophy className="h-4 w-4" />
                    <span className="text-sm font-medium">Récompense débloquée !</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}