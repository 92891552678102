import { collection, query, where, getDocs, getDoc, onSnapshot } from 'firebase/firestore';
import { db, COLLECTIONS } from './firebase';
import { SunnyDatabaseInfo } from '../components/Sunny/types';

type SunnyDataListener = (info: SunnyDatabaseInfo) => void;

export class SunnyDatabaseManager {
  private unsubscribeMerchants?: () => void;
  private unsubscribeUsers?: () => void;
  private unsubscribeQuests?: () => void;
  private unsubscribeCoupons?: () => void;
  private unsubscribeMetrics?: () => void;
  private isOffline: boolean = false;

  private currentData: Partial<SunnyDatabaseInfo> = {
    merchants: {
      total: 0,
      categories: {},
      activeQuests: 0,
      activeCoupons: 0
    },
    users: {
      total: 0,
      averageSuncoins: 0,
      totalSuncoinsSpent: 0,
      questCompletions: 0
    },
    stats: {
      totalTransactions: 0,
      averageDiscount: 0,
      popularCategories: []
    }
  };
  
  private listeners: Set<SunnyDataListener> = new Set();
  private retryTimeout?: NodeJS.Timeout;

  constructor() {
    this.setupListeners();
    this.setupConnectivityListener();
  }

  private setupConnectivityListener() {
    window.addEventListener('online', () => {
      this.isOffline = false;
      this.setupListeners(); // Reconnect listeners when back online
    });

    window.addEventListener('offline', () => {
      this.isOffline = true;
      this.cleanup(); // Clean up listeners when offline
    });
  }

  private setupListeners() {
    if (this.isOffline) return;

    try {
      // Listen to merchants
      const merchantsQuery = query(
        collection(db, COLLECTIONS.USERS),
        where('role', '==', 'merchant')
      );
      
      this.unsubscribeMerchants = onSnapshot(merchantsQuery, 
        (snapshot) => {
          const merchants = snapshot.docs.map(doc => doc.data());
          const categories: { [key: string]: number } = {};
          merchants.forEach(merchant => {
            const category = merchant.merchantInfo?.category;
            if (category) {
              categories[category] = (categories[category] || 0) + 1;
            }
          });

          const sortedCategories = Object.entries(categories)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 3)
            .map(([category]) => category);

          this.updateData({
            merchants: {
              total: merchants.length,
              categories,
              activeQuests: this.currentData.merchants?.activeQuests || 0,
              activeCoupons: this.currentData.merchants?.activeCoupons || 0
            },
            stats: {
              ...this.currentData.stats,
              popularCategories: sortedCategories
            }
          });
        },
        (error) => {
          console.error('Error in merchants listener:', error);
          this.handleError();
        }
      );

      // Listen to users
      const usersQuery = query(collection(db, COLLECTIONS.USERS));
      this.unsubscribeUsers = onSnapshot(usersQuery,
        (snapshot) => {
          const users = snapshot.docs.map(doc => doc.data());
          const totalSuncoins = users.reduce((sum, user) => sum + (user.fidecoins || 0), 0);
          const totalSpent = users.reduce((sum, user) => sum + (user.suncoinsSpent || 0), 0);

          this.updateData({
            users: {
              total: users.length,
              averageSuncoins: totalSuncoins / users.length,
              totalSuncoinsSpent: totalSpent,
              questCompletions: this.currentData.users?.questCompletions || 0
            }
          });
        },
        (error) => {
          console.error('Error in users listener:', error);
          this.handleError();
        }
      );

      // Listen to active quests
      const questsQuery = query(
        collection(db, COLLECTIONS.QUESTS),
        where('active', '==', true)
      );
      this.unsubscribeQuests = onSnapshot(questsQuery,
        (snapshot) => {
          this.updateData({
            merchants: {
              ...this.currentData.merchants!,
              activeQuests: snapshot.size
            }
          });
        },
        (error) => {
          console.error('Error in quests listener:', error);
          this.handleError();
        }
      );

      // Listen to active coupons
      const couponsQuery = query(
        collection(db, COLLECTIONS.COUPONS),
        where('active', '==', true)
      );
      this.unsubscribeCoupons = onSnapshot(couponsQuery,
        (snapshot) => {
          const coupons = snapshot.docs.map(doc => doc.data());
          const totalDiscount = coupons.reduce((sum, coupon) => {
            return sum + (coupon.discount?.value || 0);
          }, 0);

          this.updateData({
            merchants: {
              ...this.currentData.merchants!,
              activeCoupons: snapshot.size
            },
            stats: {
              ...this.currentData.stats!,
              averageDiscount: totalDiscount / snapshot.size
            }
          });
        },
        (error) => {
          console.error('Error in coupons listener:', error);
          this.handleError();
        }
      );

    } catch (error) {
      console.error('Error setting up listeners:', error);
      this.handleError();
    }
  }

  private handleError() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
    
    // Retry after 5 seconds
    this.retryTimeout = setTimeout(() => {
      if (!this.isOffline) {
        this.cleanup();
        this.setupListeners();
      }
    }, 5000);
  }

  private updateData(newData: Partial<SunnyDatabaseInfo>) {
    this.currentData = {
      ...this.currentData,
      ...newData
    };

    if (this.isDataComplete()) {
      this.notifyListeners(this.currentData as SunnyDatabaseInfo);
    }
  }

  private isDataComplete(): boolean {
    return !!(
      this.currentData.merchants?.total !== undefined &&
      this.currentData.users?.total !== undefined &&
      this.currentData.stats?.totalTransactions !== undefined
    );
  }

  private notifyListeners(data: SunnyDatabaseInfo) {
    this.listeners.forEach(listener => {
      try {
        listener(data);
      } catch (error) {
        console.error('Error in listener:', error);
      }
    });
  }

  public subscribe(listener: SunnyDataListener): () => void {
    this.listeners.add(listener);
    if (this.isDataComplete()) {
      listener(this.currentData as SunnyDatabaseInfo);
    }
    return () => this.listeners.delete(listener);
  }

  public cleanup() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
    this.unsubscribeMerchants?.();
    this.unsubscribeUsers?.();
    this.unsubscribeQuests?.();
    this.unsubscribeCoupons?.();
    this.unsubscribeMetrics?.();
    this.listeners.clear();
  }
}