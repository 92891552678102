import { z } from 'zod';

export const paymentSchema = z.object({
  amount: z.number()
    .min(0.01, 'Le montant doit être supérieur à 0')
    .max(999999.99, 'Montant trop élevé'),
  suncoinsUsed: z.number()
    .min(0, 'Le montant en SUNCOINS ne peut pas être négatif'),
  userId: z.string().min(1, "L'identifiant utilisateur est requis"),
  merchantId: z.string().min(1, "L'identifiant marchand est requis")
}).refine(
  (data) => data.suncoinsUsed <= data.amount * 100, 
  { message: 'Les SUNCOINS utilisés ne peuvent pas dépasser la valeur du paiement' }
);

export const validatePaymentData = (data: unknown) => {
  return paymentSchema.safeParse(data);
};

export const validateSuncoinsAmount = (
  amount: number,
  suncoinsRequested: number,
  maxPercent: number,
  availableSuncoins: number
): { valid: boolean; error?: string } => {
  if (suncoinsRequested > availableSuncoins) {
    return { valid: false, error: 'Solde SUNCOINS insuffisant' };
  }

  const maxSuncoins = Math.floor(amount * maxPercent / 100);
  if (suncoinsRequested > maxSuncoins) {
    return { 
      valid: false, 
      error: `Maximum ${maxPercent}% du montant peut être payé en SUNCOINS` 
    };
  }

  return { valid: true };
};