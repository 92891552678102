import React from 'react';
import { Trophy, Star, Crown, Target, Users, Zap, Gift } from 'lucide-react';
import { usePremiumStatus } from '../../lib/premium/hooks';
import { useAuth } from '../../contexts/AuthContext';

interface Achievement {
  id: string;
  title: string;
  description: string;
  icon: React.ElementType;
  rarity: 'common' | 'rare' | 'epic' | 'legendary';
  requirement: number;
  currentProgress: number;
  reward: number;
  unlocked: boolean;
}

const RARITY_COLORS = {
  common: 'text-gray-600 bg-gray-100',
  rare: 'text-blue-600 bg-blue-100',
  epic: 'text-purple-600 bg-purple-100',
  legendary: 'text-yellow-600 bg-yellow-100'
};

export default function PremiumAchievements() {
  const { isPremium, premiumStats } = usePremiumStatus();
  const { userData } = useAuth();

  if (!userData || !isPremium) return null;

  const achievements: Achievement[] = [
    {
      id: 'quest_master',
      title: 'Maître des Quêtes',
      description: 'Complétez 100 quêtes Premium',
      icon: Trophy,
      rarity: 'legendary',
      requirement: 100,
      currentProgress: premiumStats?.exclusiveQuestsCompleted || 0,
      reward: 1000,
      unlocked: (premiumStats?.exclusiveQuestsCompleted || 0) >= 100
    },
    {
      id: 'daily_champion',
      title: 'Champion Quotidien',
      description: 'Réclamez votre bonus quotidien 30 jours de suite',
      icon: Star,
      rarity: 'epic',
      requirement: 30,
      currentProgress: premiumStats?.loginStreak || 0,
      reward: 500,
      unlocked: (premiumStats?.loginStreak || 0) >= 30
    },
    {
      id: 'social_butterfly',
      title: 'Papillon Social',
      description: 'Parrainez 10 nouveaux membres Premium',
      icon: Users,
      rarity: 'rare',
      requirement: 10,
      currentProgress: userData.referralCount || 0,
      reward: 250,
      unlocked: (userData.referralCount || 0) >= 10
    },
    {
      id: 'bonus_collector',
      title: 'Collectionneur de Bonus',
      description: 'Accumulez 5000 SUNCOINS en bonus Premium',
      icon: Gift,
      rarity: 'epic',
      requirement: 5000,
      currentProgress: premiumStats?.totalBonusEarned || 0,
      reward: 500,
      unlocked: (premiumStats?.totalBonusEarned || 0) >= 5000
    }
  ];

  const unlockedCount = achievements.filter(a => a.unlocked).length;
  const totalAchievements = achievements.length;

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <Crown className="h-6 w-6 text-primary-600" />
          <h2 className="text-xl font-semibold text-gray-900">Succès Premium</h2>
        </div>
        <div className="text-sm text-gray-600">
          {unlockedCount}/{totalAchievements} débloqués
        </div>
      </div>

      <div className="space-y-6">
        <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-primary-600 transition-all duration-500"
            style={{ width: `${(unlockedCount / totalAchievements) * 100}%` }}
          />
        </div>

        <div className="grid gap-4">
          {achievements.map((achievement) => (
            <div
              key={achievement.id}
              className={`p-4 rounded-lg border ${
                achievement.unlocked
                  ? 'border-primary-200 bg-primary-50'
                  : 'border-gray-200 bg-gray-50'
              }`}
            >
              <div className="flex items-start space-x-4">
                <div className={`p-2 rounded-full ${RARITY_COLORS[achievement.rarity]}`}>
                  <achievement.icon className="h-6 w-6" />
                </div>

                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="font-medium text-gray-900">{achievement.title}</h3>
                      <p className="text-sm text-gray-600 mt-1">{achievement.description}</p>
                    </div>
                    <div className="flex items-center space-x-1 text-primary-600">
                      <Crown className="h-4 w-4" />
                      <span className="font-medium">+{achievement.reward}</span>
                    </div>
                  </div>

                  {!achievement.unlocked && (
                    <div className="mt-3">
                      <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div
                          className="h-full bg-primary-600 transition-all duration-500"
                          style={{
                            width: `${Math.min(
                              100,
                              (achievement.currentProgress / achievement.requirement) * 100
                            )}%`
                          }}
                        />
                      </div>
                      <p className="text-xs text-gray-500 mt-1">
                        {achievement.currentProgress} / {achievement.requirement}
                      </p>
                    </div>
                  )}

                  {achievement.unlocked && (
                    <div className="mt-2 flex items-center space-x-2 text-green-600">
                      <Trophy className="h-4 w-4" />
                      <span className="text-sm font-medium">Succès débloqué !</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}