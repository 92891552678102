import React, { useState } from 'react';
import { Crown, Loader } from 'lucide-react';
import { createSubscription } from '../../lib/stripe/subscriptions';
import { useAuth } from '../../contexts/AuthContext';

interface PremiumSubscriptionButtonProps {
  onError?: (error: Error) => void;
  className?: string;
}

export default function PremiumSubscriptionButton({
  onError,
  className = ''
}: PremiumSubscriptionButtonProps) {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handleSubscribe = async () => {
    if (!currentUser) {
      onError?.(new Error('Vous devez être connecté pour souscrire'));
      return;
    }

    try {
      setLoading(true);
      const sessionId = await createSubscription(currentUser.uid, 'PREMIUM');
      window.location.href = sessionId;
    } catch (error) {
      console.error('Subscription error:', error);
      onError?.(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={handleSubscribe}
      disabled={loading}
      className={`flex items-center justify-center space-x-2 bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-6 py-3 rounded-lg hover:from-yellow-600 hover:to-yellow-700 disabled:opacity-50 ${className}`}
    >
      {loading ? (
        <>
          <Loader className="h-5 w-5 animate-spin" />
          <span>Traitement en cours...</span>
        </>
      ) : (
        <>
          <Crown className="h-5 w-5" />
          <span>Devenir Premium pour 2,99€/mois</span>
        </>
      )}
    </button>
  );
}