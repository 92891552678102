import React, { useState, useEffect } from 'react';
import { Trophy, Users, Gift } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { getReferralStats } from '../lib/referral/stats';
import ReferralQRCode from './ReferralQRCode';

export default function ReferralStats() {
  const { currentUser, userData } = useAuth();
  const [stats, setStats] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadStats = async () => {
      if (!currentUser) return;

      try {
        setLoading(true);
        const referralStats = await getReferralStats(currentUser.uid);
        setStats(referralStats);
      } catch (err) {
        console.error('Error loading referral stats:', err);
        setError('Erreur lors du chargement des statistiques');
      } finally {
        setLoading(false);
      }
    };

    loadStats();
  }, [currentUser]);

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/3"></div>
          <div className="space-y-3">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-600 p-4 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center space-x-3 mb-6">
        <Trophy className="h-6 w-6 text-primary-600" />
        <h2 className="text-xl font-bold text-gray-900">Programme de parrainage</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-primary-50 rounded-lg p-4">
          <div className="flex items-center space-x-3 mb-2">
            <Users className="h-5 w-5 text-primary-600" />
            <h3 className="font-medium text-primary-900">Vos parrainages</h3>
          </div>
          <p className="text-2xl font-bold text-primary-600 mb-1">
            {stats?.referralCount || 0}
          </p>
          <p className="text-sm text-primary-700">
            Total des SUNCOINS gagnés : {stats?.totalEarnings || 0}
          </p>
        </div>

        {stats?.nextMilestone && (
          <div className="bg-green-50 rounded-lg p-4">
            <div className="flex items-center space-x-3 mb-2">
              <Gift className="h-5 w-5 text-green-600" />
              <h3 className="font-medium text-green-900">Prochain palier</h3>
            </div>
            <p className="text-2xl font-bold text-green-600 mb-1">
              +{stats.nextMilestone.bonus} SUNCOINS
            </p>
            <p className="text-sm text-green-700">
              Plus que {stats.nextMilestone.remaining} parrainage{stats.nextMilestone.remaining > 1 ? 's' : ''} !
            </p>
          </div>
        )}
      </div>

      <ReferralQRCode />
    </div>
  );
}