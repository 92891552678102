import React from 'react';
import { Clock, Crown } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { usePremiumStatus } from '../../lib/premium/hooks';

export default function PremiumHistory() {
  const { premiumStats } = usePremiumStatus();

  if (!premiumStats) return null;

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-center space-x-3 mb-6">
        <Clock className="h-6 w-6 text-primary-600" />
        <h2 className="text-xl font-semibold text-gray-900">Historique Premium</h2>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Crown className="h-5 w-5 text-primary-600" />
            <span className="text-gray-600">Membre depuis</span>
          </div>
          <span className="font-medium text-gray-900">
            {format(premiumStats.memberSince, 'PPP', { locale: fr })}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-600">Bonus quotidiens gagnés</span>
          <span className="font-medium text-gray-900">
            {premiumStats.totalBonusEarned} SUNCOINS
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-600">Quêtes exclusives complétées</span>
          <span className="font-medium text-gray-900">
            {premiumStats.exclusiveQuestsCompleted}
          </span>
        </div>

        <div className="bg-primary-50 p-4 rounded-lg">
          <p className="text-sm text-primary-700">
            Multiplicateur de quêtes actif : x{premiumStats.questMultiplier}
          </p>
        </div>
      </div>
    </div>
  );
}