import { doc, updateDoc, increment, getDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from './firebase';
import confetti from 'canvas-confetti';

// Rewards structure for different user types
export const REWARDS = {
  customer: {
    referrer: 100,   // Referrer gets 100 SUNCOINS
    referee: 50      // New user gets 50 SUNCOINS
  },
  merchant: {
    referrer: 1000,  // Referrer gets 1000 SUNCOINS
    referee: 500     // New merchant gets 500 SUNCOINS
  }
} as const;

// Milestones for additional bonuses
export const REFERRAL_MILESTONES = [
  { count: 5, bonus: 250 },    // 250 SUNCOINS bonus at 5 referrals
  { count: 10, bonus: 750 },   // 750 SUNCOINS bonus at 10 referrals
  { count: 25, bonus: 2500 },  // 2500 SUNCOINS bonus at 25 referrals
  { count: 50, bonus: 7500 },  // 7500 SUNCOINS bonus at 50 referrals
  { count: 100, bonus: 25000 } // 25000 SUNCOINS bonus at 100 referrals
] as const;

// Process a successful referral
export const handleReferralReward = async (
  referrerId: string,
  newUserId: string,
  isMerchant: boolean
): Promise<void> => {
  try {
    const referrerRef = doc(db, COLLECTIONS.USERS, referrerId);
    const referrerDoc = await getDoc(referrerRef);
    
    if (!referrerDoc.exists()) {
      throw new Error('Referrer not found');
    }

    const rewards = isMerchant ? REWARDS.merchant : REWARDS.customer;

    // Update referrer
    await updateDoc(referrerRef, {
      fidecoins: increment(rewards.referrer),
      referralCount: increment(1),
      totalReferralEarnings: increment(rewards.referrer)
    });

    // Update new user
    const newUserRef = doc(db, COLLECTIONS.USERS, newUserId);
    await updateDoc(newUserRef, {
      fidecoins: increment(rewards.referee),
      referredBy: referrerId
    });

    // Trigger celebration
    triggerCelebration();
  } catch (error) {
    console.error('Error processing referral:', error);
    throw error;
  }
};

// Trigger celebration animation
const triggerCelebration = () => {
  const duration = 3 * 1000;
  const animationEnd = Date.now() + duration;

  const randomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const confettiInterval = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      clearInterval(confettiInterval);
      return;
    }

    confetti({
      particleCount: 3,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: ['#FFD700', '#FFA500', '#FF6347']
    });
    confetti({
      particleCount: 3,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: ['#FFD700', '#FFA500', '#FF6347']
    });
  }, 150);
};