import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendEmailVerification
} from 'firebase/auth';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import { auth, db, COLLECTIONS } from '../lib/firebase';
import { handleReferralReward } from '../lib/referral';

interface UserData {
  id?: string;
  name: string;
  email: string;
  role: 'customer' | 'merchant' | 'admin';
  fidecoins?: number;
  phone?: string;
  address?: string;
  photoURL?: string;
  frozen?: boolean;
  referralCount?: number;
  totalReferralEarnings?: number;
  referredBy?: string;
  merchantInfo?: {
    siren: string;
    companyName: string;
    businessAddress: string;
    location: {
      lat: number;
      lng: number;
    };
    discountPercent: number;
    category: string;
    website?: string;
  };
}

interface AuthContextType {
  currentUser: User | null;
  userData: UserData | null;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, data: Partial<UserData> & { referralCode?: string }) => Promise<void>;
  logout: () => Promise<void>;
  updateUserProfile: (data: Partial<UserData>) => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      
      if (user) {
        const docRef = doc(db, COLLECTIONS.USERS, user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData({ id: user.uid, ...docSnap.data() } as UserData);
        }
      } else {
        setUserData(null);
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const register = async (email: string, password: string, data: Partial<UserData> & { referralCode?: string }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userRef = doc(db, COLLECTIONS.USERS, user.uid);
      const initialData = {
        email: user.email,
        name: data.name,
        role: data.role || 'customer',
        fidecoins: data.role === 'merchant' ? 5000 : 100,
        referralCount: 0,
        totalReferralEarnings: 0,
        createdAt: Timestamp.now(),
        ...(data.role === 'merchant' && data.merchantInfo && {
          merchantInfo: {
            ...data.merchantInfo,
            verified: false,
            discountPercent: 20
          }
        })
      };

      await setDoc(userRef, initialData);

      if (data.referralCode) {
        await handleReferralReward(data.referralCode, user.uid, data.role === 'merchant');
      }

      await sendEmailVerification(user);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => signOut(auth);

  const updateUserProfile = async (data: Partial<UserData>) => {
    if (!currentUser) throw new Error('No user logged in');

    const userRef = doc(db, COLLECTIONS.USERS, currentUser.uid);
    await setDoc(userRef, data, { merge: true });
    
    setUserData(prev => prev ? { ...prev, ...data } : null);
  };

  const sendVerificationEmail = async () => {
    if (!currentUser) throw new Error('No user logged in');
    await sendEmailVerification(currentUser);
  };

  const value = {
    currentUser,
    userData,
    login,
    register,
    logout,
    updateUserProfile,
    sendVerificationEmail
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}