import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Trophy, X, Loader, Info, Map, Download } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import { notifyNearbyUsers } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';

const questSchema = z.object({
  title: z.string().min(3, 'Le titre doit contenir au moins 3 caractères'),
  description: z.string().min(10, 'La description doit contenir au moins 10 caractères'),
  type: z.enum(['WALK', 'VISIT', 'PURCHASE', 'TREASURE']),
  reward: z.number()
    .min(10, 'La récompense minimum est de 10 SUNCOINS')
    .max(1000, 'La récompense maximum est de 1000 SUNCOINS')
    .refine(val => {
      return true;
    }, {
      message: 'La récompense minimum pour une chasse aux trésors est de 200 SUNCOINS'
    }),
  radius: z.number().min(0.1, 'Le rayon minimum est de 100m'),
  deadline: z.string().refine((date) => new Date(date) > new Date(), {
    message: 'La date limite doit être dans le futur'
  }),
  collaborativeMerchants: z.array(z.string()).optional(),
});

type QuestFormData = z.infer<typeof questSchema>;

interface QuestFormProps {
  onSubmit: (data: QuestFormData & { questCode: string }) => Promise<void>;
  onClose: () => void;
  loading?: boolean;
}

export default function QuestForm({ onSubmit, onClose, loading }: QuestFormProps) {
  const { userData } = useAuth();
  const [showTreasureInfo, setShowTreasureInfo] = useState(false);
  const [treasureQRCodes, setTreasureQRCodes] = useState<string[]>([]);
  
  const { register, handleSubmit, formState: { errors }, watch } = useForm<QuestFormData>({
    resolver: zodResolver(questSchema),
    defaultValues: {
      type: 'VISIT',
      radius: 0.1,
      reward: 100,
      deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    }
  });

  const questType = watch('type');
  const questCode = React.useMemo(() => crypto.randomUUID(), []);

  // Générer les QR codes pour la chasse aux trésors
  React.useEffect(() => {
    if (questType === 'TREASURE') {
      const codes = Array.from({ length: 10 }, (_, i) => ({
        type: 'treasure_checkpoint',
        mainQuestCode: questCode,
        checkpointNumber: i + 1,
        total: 10
      }));
      setTreasureQRCodes(codes.map(data => JSON.stringify(data)));
    }
  }, [questType, questCode]);

  const downloadQRCodes = () => {
    const zip = new JSZip();
    const qrFolder = zip.folder("qr-codes");

    treasureQRCodes.forEach((qrData, index) => {
      const canvas = document.createElement('canvas');
      QRCode.toCanvas(canvas, qrData, { width: 1000, height: 1000 });
      const imageData = canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
      qrFolder?.file(`checkpoint-${index + 1}.png`, imageData, { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then(function(content) {
      const url = window.URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = url;
      link.download = "chasse-aux-tresors-qrcodes.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleFormSubmit = async (data: QuestFormData) => {
    if (!userData?.merchantInfo?.location) {
      console.error('Merchant location not found');
      return;
    }

    try {
      const questData = {
        ...data,
        questCode,
        reward: Number(data.reward),
        radius: Number(data.radius)
      };

      await onSubmit(questData);

      try {
        const notifiedUsers = await notifyNearbyUsers({
          title: data.title,
          description: data.description,
          reward: Number(data.reward),
          location: userData.merchantInfo.location,
          merchantName: userData.merchantInfo.companyName
        });
        
        console.log(`Notification sent to ${notifiedUsers} nearby users`);
      } catch (error) {
        console.error('Error sending notifications:', error);
      }
    } catch (error) {
      console.error('Error creating quest:', error);
      throw error;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-3">
            <Trophy className="h-6 w-6 text-primary-600" />
            <h3 className="text-xl font-semibold text-gray-900">Créer une quête</h3>
          </div>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            type="button"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {questType === 'TREASURE' && !showTreasureInfo && (
          <div className="mb-6 bg-primary-50 p-4 rounded-lg">
            <div className="flex items-start space-x-3">
              <Info className="h-5 w-5 text-primary-600 mt-1" />
              <div>
                <h4 className="font-medium text-primary-900">Chasse aux trésors</h4>
                <p className="text-sm text-primary-700 mt-1">
                  Créez un parcours ludique avec 10 points de contrôle QR dans votre magasin ou quartier !
                </p>
                <button
                  onClick={() => setShowTreasureInfo(true)}
                  className="text-sm text-primary-600 hover:text-primary-700 font-medium mt-2"
                >
                  En savoir plus
                </button>
              </div>
            </div>
          </div>
        )}

        {showTreasureInfo && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-2xl">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Chasse aux trésors</h3>
                <button
                  onClick={() => setShowTreasureInfo(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <div className="space-y-4">
                <div className="bg-primary-50 p-4 rounded-lg">
                  <h4 className="font-medium text-primary-900 mb-2">Comment ça marche ?</h4>
                  <ul className="space-y-2 text-sm text-primary-700">
                    <li>• Créez un parcours avec 10 points de contrôle QR</li>
                    <li>• Les clients doivent scanner tous les QR codes dans l'ordre</li>
                    <li>• Récompense minimum de 200 SUNCOINS pour motiver la participation</li>
                    <li>• Parfait pour faire découvrir votre magasin ou votre quartier</li>
                  </ul>
                </div>

                <div className="bg-green-50 p-4 rounded-lg">
                  <h4 className="font-medium text-green-900 mb-2">Avantages</h4>
                  <ul className="space-y-2 text-sm text-green-700">
                    <li>• Augmente le temps passé en magasin</li>
                    <li>• Crée une expérience ludique et mémorable</li>
                    <li>• Possibilité de collaboration entre commerces</li>
                    <li>• Génère du trafic dans des zones spécifiques</li>
                  </ul>
                </div>

                <div className="bg-blue-50 p-4 rounded-lg">
                  <h4 className="font-medium text-blue-900 mb-2">Idées d'utilisation</h4>
                  <ul className="space-y-2 text-sm text-blue-700">
                    <li>• Parcours découverte de votre boutique</li>
                    <li>• Circuit entre plusieurs commerces partenaires</li>
                    <li>• Animation commerciale de quartier</li>
                    <li>• Événements spéciaux (soldes, fêtes...)</li>
                  </ul>
                </div>

                <button
                  onClick={() => setShowTreasureInfo(false)}
                  className="w-full bg-primary-600 text-white py-2 rounded-lg hover:bg-primary-700"
                >
                  J'ai compris
                </button>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Type de quête</label>
            <select
              {...register('type')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            >
              <option value="VISIT">Visite en magasin</option>
              <option value="WALK">Marche jusqu'au magasin</option>
              <option value="PURCHASE">Achat minimum</option>
              <option value="TREASURE">Chasse aux trésors</option>
            </select>
            {errors.type && (
              <p className="mt-1 text-sm text-red-600">{errors.type.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Titre</label>
            <input
              type="text"
              {...register('title')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              placeholder="Ex: Grande chasse aux trésors de Noël"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              {...register('description')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              placeholder={questType === 'TREASURE' ? 
                "Décrivez le parcours et donnez des indices pour trouver les QR codes..." :
                "Décrivez votre quête en détail..."
              }
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Récompense (SUNCOINS)
                {questType === 'TREASURE' && (
                  <span className="text-xs text-primary-600 ml-1">
                    (min. 200)
                  </span>
                )}
              </label>
              <input
                type="number"
                {...register('reward', { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                min={questType === 'TREASURE' ? 200 : 10}
                max="1000"
                step="10"
              />
              {errors.reward && (
                <p className="mt-1 text-sm text-red-600">{errors.reward.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Date limite</label>
              <input
                type="date"
                {...register('deadline')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
              {errors.deadline && (
                <p className="mt-1 text-sm text-red-600">{errors.deadline.message}</p>
              )}
            </div>
          </div>

          {questType === 'TREASURE' && (
            <div className="border-t pt-6">
              <div className="flex items-center justify-between mb-4">
                <h4 className="font-medium text-gray-900">QR Codes de la chasse</h4>
                <button
                  type="button"
                  onClick={downloadQRCodes}
                  className="flex items-center space-x-2 text-primary-600 hover:text-primary-700"
                >
                  <Download className="h-5 w-5" />
                  <span>Télécharger tous les QR codes</span>
                </button>
              </div>
              
              <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                {treasureQRCodes.map((qrData, index) => (
                  <div key={index} className="bg-gray-50 p-2 rounded-lg text-center">
                    <QRCodeSVG
                      value={qrData}
                      size={100}
                      level="H"
                      includeMargin={true}
                    />
                    <p className="text-xs text-gray-600 mt-1">Point {index + 1}/10</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:opacity-50"
            >
              {loading ? (
                <div className="flex items-center space-x-2">
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Création...</span>
                </div>
              ) : (
                'Créer la quête'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}