import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { X, Store, Clock, AlertTriangle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuth } from '../contexts/AuthContext';

interface CouponQRCodeProps {
  coupon: {
    id: string;
    code: string;
    title: string;
    merchantName: string;
    discountType: 'percentage' | 'fixed';
    discountValue: number;
    expiresAt: Date;
    terms?: string;
  };
  onClose: () => void;
}

export default function CouponQRCode({ coupon, onClose }: CouponQRCodeProps) {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return null;
  }

  // Create QR code data with proper format
  const qrData = JSON.stringify({
    type: 'coupon',
    code: coupon.code,
    userId: currentUser.uid,
    timestamp: new Date().toISOString(),
    discount: {
      type: coupon.discountType,
      value: coupon.discountValue
    }
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-900">{coupon.title}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-6">
          <div className="flex items-center space-x-3 text-gray-600">
            <Store className="h-5 w-5" />
            <span>{coupon.merchantName}</span>
          </div>

          <div className="flex items-center space-x-3 text-gray-600">
            <Clock className="h-5 w-5" />
            <span>
              Expire le {format(coupon.expiresAt, 'dd MMMM yyyy', { locale: fr })}
            </span>
          </div>

          <div className="flex justify-center">
            <div className="bg-gray-50 p-4 rounded-lg">
              <QRCodeSVG
                value={qrData}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>
          </div>

          <div className="bg-yellow-50 p-4 rounded-lg">
            <div className="flex items-start space-x-3">
              <AlertTriangle className="h-5 w-5 text-yellow-600 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-yellow-700">
                <p className="font-medium">Important</p>
                <p className="mt-1">
                  Ce coupon ne peut être utilisé qu'une seule fois.
                  Présentez ce QR code au marchand pour bénéficier de votre réduction de{' '}
                  {coupon.discountType === 'percentage' 
                    ? `${coupon.discountValue}%`
                    : `${coupon.discountValue}€`}.
                </p>
              </div>
            </div>
          </div>

          {coupon.terms && (
            <div className="text-sm text-gray-500">
              <p className="font-medium mb-1">Conditions d'utilisation :</p>
              <p>{coupon.terms}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}