import { BehaviorSubject } from 'rxjs';
import { doc, setDoc, increment, Timestamp } from 'firebase/firestore';
import { db, COLLECTIONS } from './firebase';

export class StepCounter {
  private static instance: StepCounter;
  private stepCount = new BehaviorSubject<number>(0);
  private accelerometer: DeviceMotionEventListener;
  private isRunning: boolean = false;
  private lastResetDate: string = '';
  private lastSaveTime: number = 0;
  private readonly SAVE_INTERVAL = 1000; // Save every second
  private userId: string | null = null;

  // Paramètres de détection affinés
  private readonly ACCELERATION_THRESHOLD = 1.2;
  private readonly TIME_THRESHOLD = 180;
  private readonly PEAK_THRESHOLD = 0.8;
  private readonly GRAVITY = 9.81;

  // Variables pour la détection améliorée
  private lastAccelerations: number[] = [];
  private readonly BUFFER_SIZE = 5;
  private lastStepTime: number = 0;
  private peakDetected: boolean = false;
  private valleyDetected: boolean = false;

  private constructor() {
    this.accelerometer = this.handleMotion.bind(this);
    this.checkDayChange();
  }

  public static getInstance(): StepCounter {
    if (!StepCounter.instance) {
      StepCounter.instance = new StepCounter();
    }
    return StepCounter.instance;
  }

  public async start(userId: string): Promise<void> {
    if (this.isRunning && this.userId === userId) return;

    try {
      this.userId = userId;
      
      // Initialize daily steps document
      const today = new Date().toISOString().split('T')[0];
      const stepsRef = doc(db, COLLECTIONS.USERS, userId, 'dailySteps', today);
      
      await setDoc(stepsRef, {
        count: 0,
        lastUpdate: Timestamp.now()
      }, { merge: true });

      if (typeof DeviceMotionEvent !== 'undefined' && typeof (DeviceMotionEvent as any).requestPermission === 'function') {
        const response = await (DeviceMotionEvent as any).requestPermission();
        if (response !== 'granted') {
          throw new Error('Permission refusée pour l\'accéléromètre');
        }
      }

      if (!window.DeviceMotionEvent) {
        throw new Error('L\'accéléromètre n\'est pas disponible sur cet appareil');
      }

      this.restoreState();
      window.addEventListener('devicemotion', this.accelerometer);
      this.isRunning = true;

    } catch (error: any) {
      console.error('Error starting step counter:', error);
      throw new Error(error.message || 'Impossible de démarrer le compteur de pas');
    }
  }

  public stop(): void {
    if (!this.isRunning) return;
    window.removeEventListener('devicemotion', this.accelerometer);
    this.isRunning = false;
    this.saveState();
  }

  public getSteps(): BehaviorSubject<number> {
    return this.stepCount;
  }

  private async handleMotion(event: DeviceMotionEvent): Promise<void> {
    if (!event.accelerationIncludingGravity || !this.userId) return;

    const { x, y, z } = event.accelerationIncludingGravity;
    if (x === null || y === null || z === null) return;

    const verticalAcceleration = Math.abs(z - this.GRAVITY);
    
    this.lastAccelerations.push(verticalAcceleration);
    if (this.lastAccelerations.length > this.BUFFER_SIZE) {
      this.lastAccelerations.shift();
    }

    const avgAcceleration = this.lastAccelerations.reduce((a, b) => a + b, 0) / this.lastAccelerations.length;
    const currentTime = Date.now();
    const timeSinceLastStep = currentTime - this.lastStepTime;

    if (avgAcceleration > this.ACCELERATION_THRESHOLD && !this.peakDetected && timeSinceLastStep > this.TIME_THRESHOLD) {
      this.peakDetected = true;
      this.valleyDetected = false;
    } else if (avgAcceleration < this.PEAK_THRESHOLD && this.peakDetected && !this.valleyDetected) {
      this.valleyDetected = true;
      this.peakDetected = false;
      
      const newStepCount = this.stepCount.value + 1;
      this.stepCount.next(newStepCount);
      this.lastStepTime = currentTime;

      if (currentTime - this.lastSaveTime >= this.SAVE_INTERVAL) {
        await this.saveState();
        this.lastSaveTime = currentTime;
      }
    }
  }

  private async saveState(): Promise<void> {
    if (!this.userId) return;
    
    try {
      const today = new Date().toISOString().split('T')[0];
      const stepsRef = doc(db, COLLECTIONS.USERS, this.userId, 'dailySteps', today);
      
      await setDoc(stepsRef, {
        count: increment(1),
        lastUpdate: Timestamp.now()
      }, { merge: true });

      localStorage.setItem(`stepCount_${this.userId}_${today}`, this.stepCount.value.toString());
      localStorage.setItem(`lastStepTime_${this.userId}`, this.lastStepTime.toString());
      localStorage.setItem(`lastResetDate_${this.userId}`, today);
    } catch (error) {
      console.error('Error saving step state:', error);
    }
  }

  private checkDayChange(): void {
    const currentDate = new Date().toISOString().split('T')[0];
    if (this.lastResetDate !== currentDate) {
      this.resetSteps();
      this.lastResetDate = currentDate;
    }
    setTimeout(() => this.checkDayChange(), 60000); // Check every minute
  }

  private resetSteps(): void {
    this.stepCount.next(0);
    this.lastStepTime = 0;
    this.lastAccelerations = [];
    this.peakDetected = false;
    this.valleyDetected = false;
    
    if (this.userId) {
      const currentDate = new Date().toISOString().split('T')[0];
      localStorage.removeItem(`stepCount_${this.userId}_${currentDate}`);
      localStorage.removeItem(`lastStepTime_${this.userId}`);
      localStorage.setItem(`lastResetDate_${this.userId}`, currentDate);
    }
  }

  private restoreState(): void {
    if (!this.userId) return;

    const currentDate = new Date().toISOString().split('T')[0];
    const savedDate = localStorage.getItem(`lastResetDate_${this.userId}`);
    
    if (savedDate === currentDate) {
      const savedSteps = localStorage.getItem(`stepCount_${this.userId}_${currentDate}`);
      const savedTime = localStorage.getItem(`lastStepTime_${this.userId}`);
      
      if (savedSteps) {
        const steps = parseInt(savedSteps, 10);
        if (!isNaN(steps)) {
          this.stepCount.next(steps);
        }
      }
      if (savedTime) {
        const time = parseInt(savedTime, 10);
        if (!isNaN(time)) {
          this.lastStepTime = time;
        }
      }
    } else {
      this.resetSteps();
    }
  }
}

export const isStepCountingSupported = (): boolean => {
  return typeof DeviceMotionEvent !== 'undefined' && 
         (window.DeviceMotionEvent || (DeviceMotionEvent as any).requestPermission);
};