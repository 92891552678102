import React from 'react';
import { ThumbsUp, ThumbsDown, Clock, Percent, DollarSign, Store, QrCode, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface CouponCardProps {
  coupon: {
    id: string;
    title: string;
    description: string;
    discount: {
      type: 'percentage' | 'fixed';
      value: number;
    } | null;
    category: string;
    merchantName: string;
    expiresAt: Date;
    likeCount: number;
    dislikeCount: number;
  };
  onLike: () => void;
  onDislike: () => void;
  onShowQR: () => void;
  userInteraction?: 'like' | 'dislike';
  className?: string;
}

export default function CouponCard({
  coupon,
  onLike,
  onDislike,
  onShowQR,
  userInteraction,
  className = ''
}: CouponCardProps) {
  const discount = coupon.discount || { type: 'percentage', value: 0 };

  const handleInteraction = (action: 'like' | 'dislike', e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === 'like') {
      onLike();
    } else {
      onDislike();
    }
  };

  return (
    <div className={`bg-white rounded-xl shadow-lg overflow-hidden ${className}`}>
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-xl font-semibold text-gray-900">{coupon.title}</h3>
            <p className="text-sm text-gray-600 flex items-center mt-1">
              <Store className="h-4 w-4 mr-1" />
              {coupon.merchantName}
            </p>
          </div>
          <div className="flex items-center space-x-1 bg-primary-50 px-2 py-1 rounded-full">
            {discount.type === 'percentage' ? (
              <>
                <Percent className="h-4 w-4 text-primary-600" />
                <span className="font-bold text-primary-600">{discount.value}%</span>
              </>
            ) : (
              <>
                <DollarSign className="h-4 w-4 text-primary-600" />
                <span className="font-bold text-primary-600">{discount.value}€</span>
              </>
            )}
          </div>
        </div>

        <p className="text-gray-600 mb-4">{coupon.description}</p>

        <div className="flex items-center justify-between text-sm text-gray-500 mb-4">
          <span className="inline-flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            Expire le {format(coupon.expiresAt, 'dd MMMM', { locale: fr })}
          </span>
          <span className="px-2 py-1 bg-gray-100 rounded-full">
            {coupon.category}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex space-x-4">
            <button
              onClick={(e) => handleInteraction('like', e)}
              onTouchEnd={(e) => handleInteraction('like', e)}
              className={`flex items-center space-x-1 px-2 py-1 rounded-full transition-colors touch-manipulation ${
                userInteraction === 'like'
                  ? 'bg-green-100 text-green-600'
                  : 'hover:bg-gray-100'
              }`}
            >
              <ThumbsUp className="h-4 w-4" />
              <span>{coupon.likeCount}</span>
            </button>
            <button
              onClick={(e) => handleInteraction('dislike', e)}
              onTouchEnd={(e) => handleInteraction('dislike', e)}
              className={`flex items-center space-x-1 px-2 py-1 rounded-full transition-colors touch-manipulation ${
                userInteraction === 'dislike'
                  ? 'bg-red-100 text-red-600'
                  : 'hover:bg-gray-100'
              }`}
            >
              <ThumbsDown className="h-4 w-4" />
              <span>{coupon.dislikeCount}</span>
            </button>
          </div>

          {userInteraction ? (
            <button
              onClick={onShowQR}
              className="flex items-center space-x-2 bg-primary-600 text-white px-3 py-1 rounded-md hover:bg-primary-700 transition-colors"
            >
              <QrCode className="h-4 w-4" />
              <span>Utiliser</span>
            </button>
          ) : (
            <div className="flex items-center text-sm text-gray-500">
              <AlertCircle className="h-4 w-4 mr-1" />
              <span>Donnez votre avis pour utiliser</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}