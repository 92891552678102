import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, arrayUnion, arrayRemove, Timestamp } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Search, Filter, MapPin, AlertCircle, ThumbsUp, ThumbsDown } from 'lucide-react';
import { MERCHANT_CATEGORIES } from '../lib/constants';
import CouponCard from '../components/CouponCard';
import CouponQRCode from '../components/CouponQRCode';

interface Coupon {
  id: string;
  title: string;
  description: string;
  discount: {
    type: 'percentage' | 'fixed';
    value: number;
  };
  category: string;
  merchantName: string;
  merchantId: string;
  expiresAt: Date;
  likeCount: number;
  dislikeCount: number;
  likedBy: string[];
  dislikedBy: string[];
  active: boolean;
  code: string;
  terms?: string;
}

export default function Coupons() {
  const { currentUser } = useAuth();
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const couponsRef = collection(db, COLLECTIONS.COUPONS);
        const q = query(couponsRef, where('active', '==', true));
        const snapshot = await getDocs(q);
        
        const fetchedCoupons = snapshot.docs.map(doc => {
          const data = doc.data();
          let expiresAt = new Date();
          if (data.expiresAt instanceof Timestamp) {
            expiresAt = data.expiresAt.toDate();
          } else if (data.expiresAt) {
            expiresAt = new Date(data.expiresAt);
          }

          return {
            id: doc.id,
            ...data,
            expiresAt,
            likeCount: data.likeCount || 0,
            dislikeCount: data.dislikeCount || 0,
            likedBy: data.likedBy || [],
            dislikedBy: data.dislikedBy || [],
            discount: data.discount || { type: 'percentage', value: 0 }
          } as Coupon;
        });

        setCoupons(fetchedCoupons);
      } catch (error) {
        console.error('Error fetching coupons:', error);
        setError('Une erreur est survenue lors du chargement des coupons');
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  const handleLike = async (couponId: string) => {
    if (!currentUser) return;

    try {
      const couponRef = doc(db, COLLECTIONS.COUPONS, couponId);
      const coupon = coupons.find(c => c.id === couponId);
      
      if (!coupon) return;

      if (coupon.likedBy.includes(currentUser.uid)) {
        await updateDoc(couponRef, {
          likedBy: arrayRemove(currentUser.uid),
          likeCount: (coupon.likeCount || 0) - 1
        });
      } else {
        if (coupon.dislikedBy.includes(currentUser.uid)) {
          await updateDoc(couponRef, {
            dislikedBy: arrayRemove(currentUser.uid),
            dislikeCount: (coupon.dislikeCount || 0) - 1
          });
        }
        await updateDoc(couponRef, {
          likedBy: arrayUnion(currentUser.uid),
          likeCount: (coupon.likeCount || 0) + 1
        });
      }

      setCoupons(coupons.map(c => {
        if (c.id === couponId) {
          const wasLiked = c.likedBy.includes(currentUser.uid);
          const wasDisliked = c.dislikedBy.includes(currentUser.uid);
          return {
            ...c,
            likedBy: wasLiked 
              ? c.likedBy.filter(id => id !== currentUser.uid)
              : [...c.likedBy, currentUser.uid],
            dislikedBy: wasDisliked 
              ? c.dislikedBy.filter(id => id !== currentUser.uid)
              : c.dislikedBy,
            likeCount: wasLiked ? c.likeCount - 1 : c.likeCount + 1,
            dislikeCount: wasDisliked ? c.dislikeCount - 1 : c.dislikeCount
          };
        }
        return c;
      }));
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  const handleDislike = async (couponId: string) => {
    if (!currentUser) return;

    try {
      const couponRef = doc(db, COLLECTIONS.COUPONS, couponId);
      const coupon = coupons.find(c => c.id === couponId);
      
      if (!coupon) return;

      if (coupon.dislikedBy.includes(currentUser.uid)) {
        await updateDoc(couponRef, {
          dislikedBy: arrayRemove(currentUser.uid),
          dislikeCount: (coupon.dislikeCount || 0) - 1
        });
      } else {
        if (coupon.likedBy.includes(currentUser.uid)) {
          await updateDoc(couponRef, {
            likedBy: arrayRemove(currentUser.uid),
            likeCount: (coupon.likeCount || 0) - 1
          });
        }
        await updateDoc(couponRef, {
          dislikedBy: arrayUnion(currentUser.uid),
          dislikeCount: (coupon.dislikeCount || 0) + 1
        });
      }

      setCoupons(coupons.map(c => {
        if (c.id === couponId) {
          const wasDisliked = c.dislikedBy.includes(currentUser.uid);
          const wasLiked = c.likedBy.includes(currentUser.uid);
          return {
            ...c,
            dislikedBy: wasDisliked 
              ? c.dislikedBy.filter(id => id !== currentUser.uid)
              : [...c.dislikedBy, currentUser.uid],
            likedBy: wasLiked 
              ? c.likedBy.filter(id => id !== currentUser.uid)
              : c.likedBy,
            dislikeCount: wasDisliked ? c.dislikeCount - 1 : c.dislikeCount + 1,
            likeCount: wasLiked ? c.likeCount - 1 : c.likeCount
          };
        }
        return c;
      }));
    } catch (error) {
      console.error('Error updating dislike:', error);
    }
  };

  const filteredCoupons = coupons.filter(coupon => {
    const matchesSearch = 
      coupon.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      coupon.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      coupon.merchantName.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesCategory = !selectedCategory || coupon.category === selectedCategory;
    
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Coupons de réduction</h1>

        <div className="bg-gradient-to-r from-primary-50 to-accent-50 rounded-lg p-6 mb-8">
          <div className="flex items-start space-x-6">
            <div className="flex-1">
              <h2 className="text-lg font-semibold text-gray-900 mb-2">
                🎯 Votre avis compte énormément !
              </h2>
              <p className="text-gray-700 mb-4">
                Pour accéder aux coupons, commencez par donner votre avis avec un petit 
                <span className="inline-flex items-center mx-1 text-green-600">
                  <ThumbsUp className="h-4 w-4 mr-1" /> like
                </span> 
                ou un 
                <span className="inline-flex items-center mx-1 text-red-600">
                  <ThumbsDown className="h-4 w-4 mr-1" /> dislike
                </span>
                !
              </p>
              <p className="text-gray-600 text-sm">
                Votre feedback nous aide à sélectionner les meilleures offres et à créer une expérience shopping 
                qui correspond vraiment à vos envies ! 🎉
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="flex-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Rechercher un coupon..."
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
            />
          </div>

          <div className="flex items-center space-x-4">
            <div className="relative">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              >
                <option value="">Toutes les catégories</option>
                {MERCHANT_CATEGORIES.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center gap-2 text-red-600">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}

        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600 mx-auto"></div>
          </div>
        ) : filteredCoupons.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-600">Aucun coupon disponible</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredCoupons.map((coupon) => (
              <CouponCard
                key={coupon.id}
                coupon={coupon}
                onLike={() => handleLike(coupon.id)}
                onDislike={() => handleDislike(coupon.id)}
                onShowQR={() => setSelectedCoupon(coupon)}
                userInteraction={
                  currentUser
                    ? coupon.likedBy.includes(currentUser.uid)
                      ? 'like'
                      : coupon.dislikedBy.includes(currentUser.uid)
                      ? 'dislike'
                      : undefined
                    : undefined
                }
              />
            ))}
          </div>
        )}
      </div>

      {selectedCoupon && (
        <CouponQRCode
          coupon={selectedCoupon}
          onClose={() => setSelectedCoupon(null)}
        />
      )}
    </div>
  );
}