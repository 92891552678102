import React from 'react';
import { Copy, Share2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { QRCodeSVG } from 'qrcode.react';

interface ReferralQRCodeProps {
  onClose?: () => void;
}

export default function ReferralQRCode({ onClose }: ReferralQRCodeProps) {
  const { currentUser, userData } = useAuth();
  const [copied, setCopied] = React.useState(false);
  const [shared, setShared] = React.useState(false);

  if (!currentUser || !userData) return null;

  const copyReferralId = async () => {
    try {
      await navigator.clipboard.writeText(currentUser.uid);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Erreur lors de la copie:', err);
    }
  };

  const shareReferralCode = async () => {
    const shareData = {
      title: 'Rejoignez Sunkdo !',
      text: `Utilisez mon code de parrainage ${currentUser.uid} pour gagner des SUNCOINS bonus à l'inscription !`,
      url: `${window.location.origin}/referral-signup?ref=${currentUser.uid}`
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        setShared(true);
        setTimeout(() => setShared(false), 2000);
      } else {
        await copyReferralId();
      }
    } catch (err) {
      console.error('Erreur lors du partage:', err);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 md:p-6">
      <h3 className="text-xl font-semibold text-gray-900 mb-4">
        Votre code de parrainage
      </h3>

      <div className="space-y-6">
        {/* Code QR */}
        <div className="flex justify-center">
          <div className="bg-white p-2 md:p-4 rounded-lg shadow-inner">
            <QRCodeSVG
              value={`${window.location.origin}/referral-signup?ref=${currentUser.uid}`}
              size={200}
              level="H"
              includeMargin={true}
            />
          </div>
        </div>

        {/* Code de parrainage */}
        <div className="bg-primary-50 p-3 md:p-4 rounded-lg">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <div className="w-full md:w-auto">
              <p className="text-sm text-primary-600 font-medium">Code de parrainage</p>
              <p className="text-lg font-bold text-primary-800 mt-1 break-all">
                {currentUser.uid}
              </p>
            </div>
            <div className="flex gap-2 w-full md:w-auto">
              <button
                onClick={copyReferralId}
                className="flex-1 md:flex-none flex items-center justify-center gap-2 px-4 py-2 bg-primary-100 text-primary-600 hover:bg-primary-200 rounded-lg transition-colors"
                title="Copier le code"
              >
                <Copy className="h-5 w-5" />
                <span className="md:hidden">Copier</span>
              </button>
              <button
                onClick={shareReferralCode}
                className="flex-1 md:flex-none flex items-center justify-center gap-2 px-4 py-2 bg-primary-100 text-primary-600 hover:bg-primary-200 rounded-lg transition-colors"
                title="Partager"
              >
                <Share2 className="h-5 w-5" />
                <span className="md:hidden">Partager</span>
              </button>
            </div>
          </div>
        </div>

        <div className="bg-primary-50 p-4 rounded-lg">
          <p className="text-primary-700 text-sm">
            {userData.role === 'merchant' ? (
              <>
                Partagez ce code avec d'autres commerçants et gagnez <strong>500 SUNCOINS</strong> pour chaque nouveau marchand qui rejoint la plateforme !
              </>
            ) : (
              <>
                Partagez ce code avec vos amis et gagnez <strong>50 SUNCOINS</strong> pour chaque nouvelle inscription ! Vos filleuls reçoivent également un bonus de bienvenue.
              </>
            )}
          </p>
        </div>

        {(copied || shared) && (
          <div className="text-center text-sm text-green-600 bg-green-50 p-2 rounded-lg animate-fade-in">
            {copied ? 'Code copié !' : 'Code partagé !'}
          </div>
        )}
      </div>
    </div>
  );
}