import React, { useState, useEffect } from 'react';
import { Bell, AlertCircle, Loader } from 'lucide-react';
import { initNotifications } from '../lib/notifications';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';

export function NotificationPrompt() {
  const { currentUser } = useAuth();
  const [showPrompt, setShowPrompt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (currentUser) {
      // Vérifier les permissions de notification et de géolocalisation
      Promise.all([
        navigator.permissions.query({ name: 'notifications' }),
        navigator.permissions.query({ name: 'geolocation' })
      ]).then(([notifPermission, geoPermission]) => {
        if (notifPermission.state === 'default' || geoPermission.state === 'prompt') {
          setShowPrompt(true);
        }
      }).catch(console.error);
    }
  }, [currentUser]);

  const handleEnable = async () => {
    if (!currentUser) return;

    try {
      setLoading(true);
      setError(null);

      // Demander la permission de géolocalisation d'abord
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      });

      // Sauvegarder la position de l'utilisateur
      await updateDoc(doc(db, COLLECTIONS.USERS, currentUser.uid), {
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      });

      // Initialiser les notifications
      await initNotifications(currentUser.uid);
      setShowPrompt(false);
    } catch (err: any) {
      console.error('Error enabling notifications:', err);
      setError(err.message || 'Une erreur est survenue lors de l\'activation des notifications');
    } finally {
      setLoading(false);
    }
  };

  if (!showPrompt) return null;

  return (
    <div className="fixed bottom-4 right-4 max-w-sm bg-white rounded-lg shadow-lg p-4 border border-primary-100 animate-slide-up">
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <Bell className="h-6 w-6 text-primary-600" />
        </div>
        <div className="flex-1">
          <h3 className="text-sm font-medium text-gray-900">
            Restez informé !
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Activez la géolocalisation et les notifications pour découvrir les quêtes à proximité et ne manquer aucune offre exclusive.
          </p>

          {error && (
            <div className="mt-2 p-2 bg-red-50 rounded-md flex items-center gap-2 text-sm text-red-600">
              <AlertCircle className="h-4 w-4 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          <div className="mt-4 flex space-x-3">
            <button
              onClick={handleEnable}
              disabled={loading}
              className="flex-1 bg-primary-600 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-primary-700 disabled:opacity-50 flex items-center justify-center space-x-2"
            >
              {loading ? (
                <>
                  <Loader className="h-4 w-4 animate-spin" />
                  <span>Activation...</span>
                </>
              ) : (
                'Activer'
              )}
            </button>
            <button
              onClick={() => setShowPrompt(false)}
              className="flex-1 bg-gray-100 text-gray-700 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200"
            >
              Plus tard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}