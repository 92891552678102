import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const messaging = typeof window !== 'undefined' ? getMessaging(app) : null;

// Environment variables
export const VAPID_KEY = import.meta.env.VITE_VAPID_KEY;

// Collections
export const COLLECTIONS = {
  USERS: 'users',
  QUESTS: 'quests',
  ORDERS: 'orders',
  COUPONS: 'coupons',
  DEALS: 'deals',
  NOTIFICATIONS: 'notifications',
  PAYMENT_REQUESTS: 'payment_requests',
  METRICS: 'metrics',
  POSTS: 'posts',
  PRESTASHOP_MERCHANTS: 'prestashop_merchants'
} as const;

// Utility functions
export const isUserAdmin = async (userId: string): Promise<boolean> => {
  try {
    const adminEmails = [
      'admin@sunkdo.com',
      'sebgoudon@gmail.com',
      'denismisran@gmail.com',
      'direction.sunkdo@gmail.com'
    ];
    const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, userId));
    if (!userDoc.exists()) return false;
    const userData = userDoc.data();
    return userData.role === 'admin' || adminEmails.includes(userData.email);
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

// Temporarily disable notifyNearbyUsers
export const notifyNearbyUsers = async () => {
  // Temporarily disabled
  return 0;
};

export default app;